import React, { useState,useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import CalendarDatePicker from "../CalendarDatePicker";
import { member_insight_summery_data, fetch_member_agent_list, download_agent_data } from "../../../services/met/Service";
import { LoaderLoader, formatDate } from "../../utils/CommonLibrary";
import moment from "moment";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import MembersReachedBar from "../DashboardGraphsMCI/MembersReachedBar";
import CallsMadedBar from "../DashboardGraphsMCI/CallsMadedBar";
import MembersEnrolledBar from "../DashboardGraphsMCI/MembersEnrolledBar";
import TargetAchievementBar from "../DashboardGraphsMCI/TargetAchievementBar";
import CallDispositionBar from "../DashboardGraphsMCI/CallDispositionBar";
import DataQualityPie from "../DashboardGraphsMCI/DataQualityPie";
import MemberCorrectDataFunnelBar from "../DashboardGraphsMCI/MemberCorrectDataFunnelBar";
import MemberIncorrectDataFunnelBar from "../DashboardGraphsMCI/MemberIncorrectDataFunnelBar";
import CallConversionDispositionBar from "../DashboardGraphsMCI/CallConversionDispositionBar";
import { decryptData } from "../../utils/Utils";


function ContentCallHistoryAgent() {

    const storedDates = localStorage.getItem('selectedDates') ? JSON.parse(decryptData(localStorage.getItem('selectedDates'))) : '';

    const [startDate, setStartDate] = useState(
        storedDates && storedDates.length === 4 ? moment(storedDates[0]) : moment()
    );
    const [endDate, setEndDate] = useState(
        storedDates && storedDates.length === 4 ? moment(storedDates[1]) : moment()
    );

    const [agentData, setAgentData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const[stateAgentId,setStateAgentId] = useState(0);
    const[showSelect,setShowSelect] = useState(false);
    
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    const formattedYesterdayDate = yesterday.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });

    const today = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(today.getDate());

    const handleDateChange = ({ start, end }) => {
        setStartDate(start);
        setEndDate(end);
    };

    const [avgCallTime,setAvgCallTime] = useState(0);
    const [attemptCallTotal,setAttemptCallTotal] = useState(0);
    const [errorMsg,setErrorMsg] = useState('');
    const [firstCallConversionRate,setFirstCallConversionRate] = useState(0);
    const [memberSatisfaction,setMemberSatisfaction] = useState(0);
    const [agentUtilizationRate,setAgentUtilizationRate] = useState(0);
    const [totalIncorrectData,setTotalIncorrectData] = useState(0);
    const [totalIncorrectDataPercentage,setTotalIncorrectDataPercentage] = useState(0);
    const [totalMemberUntoched,setTotalMemberUntoched] = useState(0);
    const [showExport,setShowExport] = useState(false);
    
    const [statsValue, setStatsValue ] = useState({'membersMigrated':'','touchedCount':'','untouchedCount':'','validCount':'','invalidCount':'','onboardedCount':'',
        'extraEntryCount':'','notInterstedCount':'','poolExaust':'','backPoolCount':'','onboardLaterCount':'','totalOnboardCount':''
    });

    const resetTime = (date) => {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    };

    const [isWithinRange, setIsWithinRange] = useState(false);
    useEffect(() => {
        const today = resetTime(new Date());
        const sd = resetTime(new Date(startDate));
        const ed = resetTime(new Date(endDate));

        // let iwr = today >= sd && today <= ed;
        // setIsWithinRange(iwr);

        if (sd.getTime() === today.getTime() && ed.getTime() === today.getTime()) {
            setIsWithinRange(false);
        } else {
            const iwr = today >= sd && today <= ed;
            setIsWithinRange(iwr);
        }
    }, [startDate, endDate])

    useEffect(() => {
        if (agentData.length > 0) {
            setStateAgentId(agentData[0].agentId);
        }
    }, [agentData]);
    
    useEffect(() => {
        fetchAgentUserlist();
    }, []);

    useEffect(() => {
        if(stateAgentId > 0){
            agentInsightStats();
        }
    }, [stateAgentId,startDate,endDate]);

    const fetchAgentUserlist = async () =>{
        setIsLoading(true); //to show loader
        try {
            let resp = await fetch_member_agent_list();
            
            if(resp.data.data.showSelect){
                setShowSelect(resp.data.data.showSelect);
                setAgentData(resp.data.data.agentList);
            }else{
                setStateAgentId(resp.data.data.agentId)
            }
            
        } catch (error) {
            console.error(error);
        }
        finally {
            setIsLoading(false);  // Will be called regardless of success or failure
        }
    }

    const agentInsightStats = async () =>{
        setIsLoading(true); 
        try {
            setShowExport(false);
            let sdate= formatDate(startDate);
            let edate= formatDate(endDate);
            let resp = await member_insight_summery_data(sdate,edate,stateAgentId,'agent');
            if(resp.data.code === 200){
                setShowExport(resp.data.data.showExport);
                setAvgCallTime(resp.data.data.recordAvgDuration);
                setAttemptCallTotal(resp.data.data.attemptPerMember);
                setMemberSatisfaction(resp.data.data.memberSatisfaction);
                setFirstCallConversionRate(resp.data.data.firstCallConversionRate);
                setAgentUtilizationRate(resp.data.data.agentUtilizationRate);
                setTotalIncorrectData(resp.data.data.totalIncorrectData);
                setTotalIncorrectDataPercentage(resp.data.data.totalIncorrectData);
                setTotalMemberUntoched(resp.data.data.totalMemberUntoched);
                setStatsValue(prevState => ({ ...prevState, membersMigrated: resp.data.data.successRecords, touchedCount: resp.data.data.touchCount, untouchedCount: resp.data.data.untouchCount,
                    validCount: resp.data.data.valid, invalidCount: resp.data.data.invalid, onboardedCount: resp.data.data.onboardedValue, notInterstedCount: resp.data.data.rejected, onboardLaterCount: resp.data.data.laterOnboard,
                    totalOnboardCount: resp.data.data.onboardedTotal,backPoolCount: resp.data.data.backToPool,poolExaust: resp.data.data.poolExaust,extraEntryCount: resp.data.data.extraManualOnboarded
                }));
            }
        } catch (error) {
            console.error('Error:', error);
        }finally{
            setIsLoading(false); 
        }
    }

    const fetchDownloadData = async () =>{
        
        setIsLoading(true);
        try{
            let sdate= formatDate(startDate);
            let edate= formatDate(endDate);
            let resp = await download_agent_data(sdate,edate,stateAgentId,'agent');
            if(resp.data.code === 200){
                window.location.href = resp.data.data.fileLink;
            }else{
                setErrorMsg(resp.data.data.msg);
                setTimeout(() => setErrorMsg(false), 2000);
            }
            setIsLoading(false);
        }catch(error) {
            console.error('Error=', error);
        }
    }

    const handleChange = (e) => {
        setStateAgentId(e.target.value);
    };

    const renderMCI6Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Number of members who were successfully contacted.
        </Tooltip>
    );

    const renderMCI7Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Total number of calls made to members.
        </Tooltip>
    );

    const renderMCI9Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Total number of agents making the calls.
        </Tooltip>
    );

    const renderMCI11Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Outcome of the calls made
        </Tooltip>
    );

    const renderMCI12Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Average time spent on each call by agents over time.
        </Tooltip>
    );

    const renderMCI13Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Average number of call attempts per member.
        </Tooltip>
    );

    const renderMCI14Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Percentage of members enrolled after the first call attempt.
        </Tooltip>
    );

    const renderMCI15Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Measure of member satisfaction based on feedback.
        </Tooltip>
    );

    const renderMCI16Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Percentage of time agents spend on calls versus available time.
        </Tooltip>
    );

    const renderMCI18Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Stages of member conversion from contact to enrollment.
        </Tooltip>
    );

    const renderMCI19Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Conversion rate of members segmented by age groups.
        </Tooltip>
    );

    //call back agent graph
    const [totalAgentsCount,setTotalAgentsCount] = useState(0);
    const handleAgentCallback = (childData) => {
        setTotalAgentsCount(childData);
      };

    //call back members enroll graph
    const [totalEnrollCount,setTotalEnrollCount] = useState(0);
    const handleEnrollCallback = (childData) => {
            setTotalEnrollCount(childData);
    };
    
    //call back for member reached graph
    const [totalReachedCount,setTotalReachedCount] = useState(0);
    const handleReachedCallback = (childData) => {
        setTotalReachedCount(childData);
    };

    //call back for call made graph
    const [totalCallMadeCount,setTotalCallMadeCount] = useState(0);
    const handleCallMadeCallback = (childData) => {
        setTotalCallMadeCount(childData);
    };

    //call back for call conversion graph
    
    const [totalCallConversionData,setTotalCallConversionData] = useState({ count_enroll: 0, count_reject: 0,count_later: 0 });
    const handleCallConversionCallback = (childData) => {
        setTotalCallConversionData(childData);
    };
    //member conversion funnel call back
    const [totalAgentMemberConversionData,setTotalAgentMemberConversionData] = useState(0);
    const handleAgentMemberCallback = (childData) => {
        setTotalAgentMemberConversionData(childData);
    };
      
    //Target Achieved call back
    const [totalTargetAchieved,setTotalTargetAchieved] = useState(0);
    const handleTargetAchievedCallback = (childData) => {
        setTotalTargetAchieved(childData);
    };

    return (
        <>
         <LoaderLoader isLoading={isLoading} />
         <section className='ContentMemberEngagement'>
            <div className="container-fluid px-0 mb-5 pb-4 ">
                <div className="row align-items-center pb-3 sticky-top-bar">
                    <div className="col-md text-start">
                        <h1 className="main-title">Agent Insights</h1>
                    </div>
                    {showSelect && <div className="col-md-3">
                        <select className="form-select input-field-ui" id="agentId" name="agentId" value={stateAgentId} onChange={handleChange} aria-label="Default select example">
                            {agentData.map((option,i) => (
                                <option key={i} value={option.agentId}>{option.fullName}</option>
                            ))}
                        </select>
                    </div> }
                    <div className="col-md-3">
                        <CalendarDatePicker startDate={startDate} endDate={endDate} onDateChange={handleDateChange} />
                    </div>
                </div>
                <div className='reports-programs mb-3'>
                    <div className="row align-items-center">
                        <div className="col-md-auto">
                            <img src="https://storage.googleapis.com/ksabupatest/2024/10/21/gjssv/8hc17sjj3u.png" alt="Invited Members icon" style={{height:"140px"}} className="img-fluid mb-0"/>
                        </div>
                        <div className="col-md">
                            <p className="mb-0">Get a clear overview of activities and performance, tracking progress, assessing effectiveness, and driving improvements for enhanced productivity.</p>
                        </div>
                    </div>
                </div>

                {isWithinRange &&
                    <div className="data-not-bg mb-3">
                        <div className="data-not-box">
                            <div className="row text-center px-2">
                                <div className="col text-center"  >
                                    <h1 class="data-not-title">Last Updated: {formattedYesterdayDate}. Today's data will be available tomorrow.</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                
                <div className="member-engagement p-3 mb-3">
                    <div className="row align-items-center mb-2">
                        <div className="row col-12">
                            <div className="col-10">
                                <h1 className='dashboard-graph-title' style={{justifyContent:"flex-start"}}>Reaching out members
                                    <OverlayTrigger placement="top" overlay={<Tooltip className="custom-tooltip">Overview of the status breakdown for member outreach.</Tooltip>}>
                                        <i className='bx bx-info-circle ms-1'></i>
                                    </OverlayTrigger>
                                </h1>
                            </div>

                            {showExport && <div className="col-2 text-end">
                                <button className="btn btn-primary btn-primary clear-search-btn" onClick={() => fetchDownloadData()}><i className="bi bi-download"></i> Export</button>
                                <div className={`${errorMsg != "" ? "errorDiv mb-4 tw-w-full tw-text-right" : "hide"}`} style={{ fontWeight: "400 !important",display:'flex' }} ><span className="tw-w-full tw-text-right">{errorMsg}</span></div>
                            </div> }
                        </div>
                    </div>
                    <div className="row text-center px-2">
                        <div className="col" >
                            <div className="row">
                                {/* <div className="col-2 px-1" >
                                    <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', border: '2px solid #1279be' }}>
                                        <div className="card-body p-2">
                                            <div className="row">
                                                <div className="col">
                                                    <h1 className="card-title1">{statsValue.membersMigrated}</h1>
                                                    <h5 className="card-text1">Members Migrated
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={<Tooltip className="custom-tooltip">Members successfully migrated by the RCM team.</Tooltip>}
                                                    >
                                                        <i className='bx bx-info-circle ms-1'></i>
                                                    </OverlayTrigger>
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                <div className="col-2  px-1" >
                                    <div className={`card bg-grey mb-1`} style={{ borderRadius: '14px', border: '2px solid #1279be' }}>
                                        <div className="card-body p-2">
                                            <div className="row">
                                                <div className="col">
                                                    <h1 className="card-title1">{statsValue.touchedCount}</h1>
                                                    <h5 className="card-text1">Members Reached
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={<Tooltip className="custom-tooltip">Number of unique members that were reached.(Valid + Incorrect)</Tooltip>}
                                                    >
                                                    <i className='bx bx-info-circle ms-1'></i>
                                                    </OverlayTrigger>
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-2  px-1" >
                                    <div className={`card bg-grey mb-1`} style={{ borderRadius: '14px', border: '2px solid #1279be' }}>
                                        <div className="card-body p-2">
                                            <div className="row">
                                                <div className="col">
                                                    <h1 className="card-title1">{statsValue.validCount}</h1>
                                                    <h5 className="card-text1">Valid
                                                    <OverlayTrigger 
                                                        placement="top"
                                                        overlay={<Tooltip className="custom-tooltip">Members eligible for outreach who have been successfully contacted or onboarded.</Tooltip>}
                                                    >
                                                        <i className='bx bx-info-circle ms-1'></i>
                                                    </OverlayTrigger>
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none' }}>
                                        <div className="card-body p-2">
                                            <div className="row">
                                                <div className="col">
                                                    <div className="row">
                                                        <div className="col">
                                                            <h1 className="card-title1">{statsValue.invalidCount}</h1>
                                                            <h5 className="card-text1">Incorrect
                                                            <OverlayTrigger 
                                                                placement="top"
                                                                overlay={<Tooltip className="custom-tooltip">Members marked incorrect by the agent due to data issues, such as policy termination, data inaccuracies or discrepancies.</Tooltip>}
                                                            >
                                                                <i className='bx bx-info-circle ms-1'></i>
                                                            </OverlayTrigger>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col" >
                                    <div className="row">
                                    <div className="col-12 px-1 mb-1" >
                                        <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height:"100%",border: '2px solid #1279be' }}>
                                        <div className="card-body p-2">
                                        <div className="row">
                                                        <div className="col border-end">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <h1 className="card-title1">{statsValue.onboardedCount}</h1>
                                                                    <h5 className="card-text1">Onboarded
                                                                    <OverlayTrigger 
                                                                        placement="top"
                                                                        overlay={<Tooltip className="custom-tooltip">Number of members added in the program by the agent.</Tooltip>}
                                                                    >
                                                                        <i className='bx bx-info-circle ms-1'></i>
                                                                    </OverlayTrigger>
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                        <div className="row">
                                                            <div className="col">
                                                                <h1 className="card-title1">{statsValue.extraEntryCount}</h1>
                                                                <h5 className="card-text1">Extra Manual Onboarded
                                                                <OverlayTrigger 
                                                                        placement="top"
                                                                        overlay={<Tooltip className="custom-tooltip">Number of members onboarded through add member feature by the agent.</Tooltip>}
                                                                    >
                                                                        <i className='bx bx-info-circle ms-1'></i>
                                                                    </OverlayTrigger>
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                        <div className="col-12 px-1 mb-2" >
                                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height:"100%" }}>
                                                <div className="card-body p-2">
                                                <div className="row">
                                                    <div className="col-3 border-end">
                                                        <div className="row">
                                                            <div className="col">
                                                                <h1 className="card-title1">{statsValue.notInterstedCount}</h1>
                                                                <h5 className="card-text1">Not Interested
                                                                <OverlayTrigger 
                                                                        placement="top"
                                                                        overlay={<Tooltip className="custom-tooltip">Members who are not interested in the program.</Tooltip>}
                                                                    >
                                                                        <i className='bx bx-info-circle ms-1'></i>
                                                                    </OverlayTrigger>
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* <div className="col-3 border-end">
                                                        <div className="row">
                                                            <div className="col">
                                                                <h1 className="card-title1">{statsValue.backPoolCount}</h1>
                                                                <h5 className="card-text1">Back to pool
                                                                <OverlayTrigger 
                                                                        placement="top"
                                                                        overlay={<Tooltip className="custom-tooltip">Members who can be contacted again for onboarding.</Tooltip>}
                                                                    >
                                                                        <i className='bx bx-info-circle ms-1'></i>
                                                                    </OverlayTrigger>
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div> */}

                                                    {/* <div className="col-3 border-end">
                                                        <div className="row">
                                                            <div className="col">
                                                                <h1 className="card-title1">{statsValue.poolExaust}</h1>
                                                                <h5 className="card-text1">Pool Exhausted
                                                                <OverlayTrigger 
                                                                        placement="top"
                                                                        overlay={<Tooltip className="custom-tooltip">Members that have been fully attempted with no successful outcome.</Tooltip>}
                                                                    >
                                                                        <i className='bx bx-info-circle ms-1'></i>
                                                                    </OverlayTrigger>
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div> */}

                                                    <div className="col-3">
                                                        <div className="row">
                                                            <div className="col">
                                                                <h1 className="card-title1">{statsValue.onboardLaterCount}</h1>
                                                                <h5 className="card-text1">Onboard later
                                                                <OverlayTrigger 
                                                                        placement="top"
                                                                        overlay={<Tooltip className="custom-tooltip">Members interested in the program but will onboard later.</Tooltip>}
                                                                    >
                                                                        <i className='bx bx-info-circle ms-1'></i>
                                                                    </OverlayTrigger>
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-auto px-1" >
                                <div className={`card bg-grey`} style={{ borderRadius: '14px', border: '2px solid #1279be' }}>
                                    <div className="card-body p-2">
                                        <div className="row">
                                            <div className="col">
                                                <h1 className="card-title1">{statsValue.totalOnboardCount}</h1>
                                                <h5 className="card-text1">Total Onboarded
                                                <OverlayTrigger 
                                                    placement="top"
                                                    overlay={<Tooltip className="custom-tooltip">Combined total of members onboarded by both RCM and agents.</Tooltip>}
                                                    >
                                                    <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>

                    <div className="row align-items-center mt-4 mb-2">
                        <div className="col"><h1 className='main-title'>Agent Performance</h1></div>
                    </div>



                    <div className="row">

                       <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Calls Made
                                    <OverlayTrigger placement="top" overlay={renderMCI7Tooltip}>
                                        <i className='bx bx-info-circle ms-2'></i>
                                    </OverlayTrigger></span> 
                                    <span className="dashboard-graph-number">{totalCallMadeCount}</span>
                                </h1>

                                {stateAgentId && <CallsMadedBar startDate={startDate} endDate={endDate} agentId={stateAgentId} requestFrom="agent" parentCallback={handleCallMadeCallback} /> }
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Members Reached
                                    <OverlayTrigger placement="top" overlay={renderMCI6Tooltip}>
                                        <i className='bx bx-info-circle ms-2'></i>
                                    </OverlayTrigger></span> 
                                    <span className="dashboard-graph-number">{totalReachedCount}</span>
                                </h1>

                                {stateAgentId && <MembersReachedBar startDate={startDate} endDate={endDate} agentId={stateAgentId} requestFrom="agent" parentCallback={handleReachedCallback}/> }
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Onboarded Members
                                    <OverlayTrigger placement="top" 
                                        overlay={<Tooltip className="custom-tooltip">Number of members added in the program</Tooltip>}>
                                        <i className='bx bx-info-circle ms-1'></i>
                                    </OverlayTrigger>
                                    </span> 
                                    <span className="dashboard-graph-number">{totalEnrollCount}</span>
                                </h1>

                                {stateAgentId && <MembersEnrolledBar startDate={startDate} endDate={endDate} agentId={stateAgentId} requestFrom="agent" parentCallback={handleEnrollCallback}/> }
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Target Achievement 
                                    <OverlayTrigger placement="top" 
                                        overlay={<Tooltip className="custom-tooltip">Target status achieved by agents</Tooltip>}>
                                        <i className='bx bx-info-circle ms-1'></i>
                                    </OverlayTrigger>
                                    </span> 
                                </h1>

                                {stateAgentId && <TargetAchievementBar startDate={startDate} endDate={endDate} agentId={stateAgentId} requestFrom="agent" parentCallback={handleTargetAchievedCallback}/>}
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Call Status 
                                    <OverlayTrigger placement="top" overlay={renderMCI11Tooltip}>
                                        <i className='bx bx-info-circle ms-2'></i>
                                    </OverlayTrigger></span> 
                                </h1>
                                {stateAgentId && <CallDispositionBar startDate={startDate} endDate={endDate} agentId={stateAgentId} requestFrom="agent" /> }
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Call Conversion 
                                    <OverlayTrigger placement="top" 
                                        overlay={<Tooltip className="custom-tooltip">Bifurcation of call conversions</Tooltip>}>
                                        <i className='bx bx-info-circle ms-1'></i>
                                    </OverlayTrigger>
                                    </span> 
                                </h1>

                                {stateAgentId && <CallConversionDispositionBar startDate={startDate} endDate={endDate} agentId={stateAgentId} requestFrom="agent" /> }
                            </div>
                        </div>
                
                    </div>

                    <div className="member-engagement mb-3">
 
 
                    <div className="row text-center px-2">

                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none' }}>
                                <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="card-title1">{avgCallTime} <span>min</span></h1>
                                        <h5 className="card-text1">Average Call Duration
                                        <OverlayTrigger placement="top" overlay={renderMCI12Tooltip}>
                                            <i className='bx bx-info-circle ms-1'></i>
                                        </OverlayTrigger>
                                        </h5>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none' }}>
                                <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="card-title1">{attemptCallTotal}</h1>
                                        <h5 className="card-text1">Attempt per member
                                        <OverlayTrigger placement="top" overlay={renderMCI13Tooltip}>
                                            <i className='bx bx-info-circle ms-1'></i>
                                        </OverlayTrigger>
                                        </h5>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none' }}>
                                <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="card-title1">{firstCallConversionRate}</h1>
                                        <h5 className="card-text1">First Call Conversion Rate
                                        <OverlayTrigger placement="top" overlay={renderMCI14Tooltip}>
                                            <i className='bx bx-info-circle ms-1'></i>
                                        </OverlayTrigger>
                                        </h5>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none' }}>
                                <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="card-title1">{memberSatisfaction}</h1>
                                        <h5 className="card-text1">Member Satisfaction
                                        <OverlayTrigger placement="top" overlay={renderMCI15Tooltip}>
                                            <i className='bx bx-info-circle ms-1'></i>
                                        </OverlayTrigger>
                                        </h5>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none' }}>
                                <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="card-title1">{agentUtilizationRate}</h1>
                                        <h5 className="card-text1">Agent Utilization Rate
                                        <OverlayTrigger placement="top" overlay={renderMCI16Tooltip}>
                                            <i className='bx bx-info-circle ms-1'></i>
                                        </OverlayTrigger>
                                        </h5>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    </div>
                
                    <div className="row">

                        <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Data Quality
                                    <OverlayTrigger placement="top" 
                                        overlay={<Tooltip className="custom-tooltip">Measurement of data quality</Tooltip>}>
                                        <i className='bx bx-info-circle ms-1'></i>
                                    </OverlayTrigger>
                                    </span> 
                                </h1>

                                {stateAgentId && <DataQualityPie startDate={startDate} endDate={endDate} agentId={stateAgentId} requestFrom="agent" /> }
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Correct Data
                                    <OverlayTrigger placement="top" 
                                        overlay={<Tooltip className="custom-tooltip">Correct marked data disposition</Tooltip>}>
                                        <i className='bx bx-info-circle ms-1'></i>
                                    </OverlayTrigger>
                                    </span> 
                                </h1>

                                {stateAgentId && <MemberCorrectDataFunnelBar startDate={startDate} endDate={endDate} agentId={stateAgentId} requestFrom="agent" /> }
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Incorrect Data
                                    <OverlayTrigger placement="top" 
                                        overlay={<Tooltip className="custom-tooltip">Incorrect marked data disposition</Tooltip>}>
                                        <i className='bx bx-info-circle ms-1'></i>
                                    </OverlayTrigger>
                                    </span> 
                                </h1>

                                {stateAgentId && <MemberIncorrectDataFunnelBar startDate={startDate} endDate={endDate} agentId={stateAgentId} requestFrom="agent" /> }
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}

export default ContentCallHistoryAgent;
