import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, Tab, Tabs, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import axios from 'axios';
import alertify from 'alertifyjs';
import { Link } from 'react-router-dom';
import './MemberServices.css';
import { fetchServiceEventHistory } from "../../../../services/cn/Service";


const EventHistory = forwardRef((props, ref) => {
    const qsParams = useParams();
    const userId = qsParams.memberId;

    const memberBasicInfo = props.memberBasicInfo ? props.memberBasicInfo : {};
    const pathwayId = (memberBasicInfo.memberPathways && memberBasicInfo.memberPathways.length > 0) ? memberBasicInfo.memberPathways[0].pathwayId : 0;

    const [eventHistory, setEventHistory] = useState([]);

    useEffect(() => {
        getServiceEventHistory();
    }, [pathwayId])

    useImperativeHandle(ref, () => ({
        getServiceEventHistory
    }));

    const getServiceEventHistory = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchServiceEventHistory(userId, pathwayId, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setEventHistory(response.data.data.logs);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }


    return (
        <>
            {
                eventHistory.length > 0 &&
                <div className='col-md-12 mt-3'>
                    <div className='card card-task'>
                        <div className='card-body'>
                            <div className='card-text'>
                                <div className='row align-items-center' >
                                    <div className='col'>
                                        <h5 className='card-title'>Event History</h5>
                                        <div className='table-responsive mt-2' style={{ maxHeight: "380px", overflowX: "hidden" }}>
                                            <table className="table table-striped table-bordered mb-0">
                                                <thead style={{ position: "sticky", top: "0" }}>
                                                    <tr>
                                                        <th scope="col">Service</th>
                                                        <th scope="col" style={{ width: "15%" }}>Date</th>
                                                        <th scope="col" style={{ width: "15%" }}>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        eventHistory.map((item, index) => {
                                                            return (
                                                                <tr style={{ verticalAlign: 'middle' }} key={index}>
                                                                    <td className='bg-td-green'>{item.serviceName}</td>
                                                                    <td className='bg-td-green'>{item.statusChangeDate}</td>
                                                                    <td className='bg-td-green'>{item.status}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            }

        </>
    )
});

export default EventHistory