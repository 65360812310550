import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import NewRequestReenroll from "./NewRequestReenroll";
import PastRequestReenroll from "./PastRequestReenroll";
//import { fetch_all_members_reenroll } from "../../../services/met/Service";



function ContentMemberRegisterLaterDetails() {

    /*const [currentReEnrollRequests, setCurrentReenrollRequests] = useState([]);
    const [pastReEnrollRequests, setPastReenrollRequests] = useState([]);

    useEffect(() => {
        fetchAgentMemberEnrollList();
    }, []);


    // fetching re-enroll requests made by agents 
    const fetchAgentMemberEnrollList = async () => {
        try {
            let response = await fetch_all_members_reenroll();
            if (response.data.code === 200) {
                let currentRequests = response.data?.data?.filter((request) => request.requestStatus === "Pending");
                let pastRequests = response.data?.data?.filter((request) => request.requestStatus !== "Pending");

                setCurrentReenrollRequests(currentRequests);
                setPastReenrollRequests(pastRequests);
            }
        } catch (error) {
            console.log(error);
        } finally {
        }
    };*/





    return (
        <>
            <section className='ContentMemberDeEnrollment'>
                <div className="row align-items-center pb-3 sticky-top-bar">
                    <div className="col-md">
                        <h1 className="main-title">Member Re-enroll Details</h1>
                    </div>
                    <div className="col-md text-end">

                    </div>
                </div>
                <div className='reports-programs mb-3'>
                    <div className="row align-items-center">
                        <div className="col-md-auto">
                            <img src="https://storage.googleapis.com/ksabupatest/2024/10/18/8241v/806lvhnlka.png" alt="Invited Members icon" style={{ height: "140px" }} className="img-fluid mb-0" />
                        </div>
                        <div className="col-md">
                            <p className="mb-3">Re-enrollment requests are for members who were previously marked as "Incorrect" or "Not Interested" by agents. Approve these requests to allow the members to re-engage with the program, enabling future interactions or onboarding.</p>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">

                    <div className="row align-items-center pb-3">
                        <div className='comntent-MemberDeEnrollment mb-3'>
                            <div className="row">
                                <div className="col-md-12">
                                    <Tabs>
                                        <TabList>
                                            <Tab>New Request</Tab>
                                            <Tab>Past Request</Tab>
                                        </TabList>
                                        <TabPanel>
                                            <div className="table-responsive ContentMemberRegisterLaterTable">
                                                <NewRequestReenroll />
                                            </div>
                                        </TabPanel>
                                        <TabPanel>
                                            <div className="table-responsive ContentMemberRegisterLaterTable">
                                                <PastRequestReenroll />
                                            </div>
                                        </TabPanel>
                                    </Tabs>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

        </>
    );
}
export default ContentMemberRegisterLaterDetails;