import React, { useState, useEffect, useCallback, useRef, forwardRef, useImperativeHandle } from "react";
// import { createPortal } from 'react-dom';
// import { useUserTabs } from "../../components/common/UserTabsContext";
// import { Link } from "react-router-dom";
// import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
import "./Chat.css";
import { BiCheckDouble, BiCheck } from "react-icons/bi";
import Picker from "emoji-picker-react";
import TemplatePopup from "../chat/TemplatePopup";
import { getDatabase, ref, onValue} from "firebase/database";
import firebase from '../../../../firebase/firebase';

import {
  fetch_chat,
  check_status,
  send_message,
  send_message_v1,
  update_status,
  member_details,
} from "../services/Service";

import {  fetchAccessLevel } from "../../../../services/cn/Service";
import axios from "axios";
import MediaModal from "./MediaModalChat";

const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

const CustomTooltip = ({ text, visible }) => {
  if (!visible) return null;

  return (
    <div className="tw-absolute tw-right-[70%] tw-top-1/2 tw--translate-y-1/2 tw-mr-2 tw-z-[9999] tw-pointer-events-none">
      <div className="tw-bg-[#1279BE] tw-text-white tw-px-2 tw-py-1 tw-rounded tw-text-sm tw-whitespace-nowrap tw-relative">
        {text}
        {/* Arrow pointing right */}
        <div className="tw-absolute tw-right-[-6px] tw-top-1/2 tw--translate-y-1/2 tw-w-0 tw-h-0 tw-border-l-4 tw-border-y-4 tw-border-solid tw-border-y-transparent tw-border-l-[#1279BE]" />
      </div>
    </div>
  );
};

const Chat = forwardRef(({ userId ,firebaseNode},ref) => {
  const [chatPage, setChatpage] = useState(1);
  const [chatResult, setChatResult] = useState([]);
  const [loadingMore, setLoadingMore] = useState(true); // Loading state
  const [id, setId] = useState(""); // Loading state
  const [isAtTop, setIsAtTop] = useState(true);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [activeTooltip, setActiveTooltip] = useState(null);
  const [newMessageCount, setNewMessageCount] = useState(0); // New message count
  const [initialMessageCount, setInitialMessageCount] = useState(0); // Track initial message count
  const previousMessageCountRef = useRef(initialMessageCount);
  const [showNewMessageAlert, setShowNewMessageAlert] = useState(false); // Track initial message count
  const [textMessage, setTextMessage] = useState(""); // For sending normal text
  const [imageFile, setImageFile] = useState(null); // Selected image file
  const [imagePreview, setImagePreview] = useState(null); // Image preview URL
  const [imageNote, setImageNote] = useState(""); // Note for image with text
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false); // Toggle for image preview popup
  const [showTemplatePopup, setShowTemplatePopup] = useState(false);
  const [templateStatus, setTemplateStatus] = useState(false);

  const [sendText, setSendText] = useState(false); // New loading state
  const [loading, setLoading] = useState(false); // New loading state
  const [formError, setFormError] = useState(false)

  const [showkeyboard, setShowKeyBoard] = useState(true);
  const [optOut, setOptOut] = useState("N");
  const [statusNode, setStatusNode] = useState("");


  const [fileType, setFileType] = useState("");
  const [msgType, setMsgType] = useState("");
  const emojiPickerRef = useRef(null);

  const fileInputRef = useRef(null);
  const chatContainerRef = useRef(null); // Ref for chat container
  const chatEndRef = useRef(null);
  const previousScrollHeightRef = useRef(0);
  console.log(firebaseNode,'firebaseNodechat')


  useImperativeHandle(ref, () => ({
    refreshchat
  }));
  const [accessLevel, setAccessLevel] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState({ url: '', type: '' });

  useEffect(() => {
    const getAccessLevel = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchAccessLevel(ct)
            .then((response) => {
                console.log("access", response);
                if (response.data.code === 200) {
                    setAccessLevel(response.data.data.cnRoleAccess);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }
    getAccessLevel();
})

// console.log(accessLevel,'accessLevel')

  useEffect(() => {
    if (!userId) return;

    setId(userId);

    const fetchMemberDetails = async () => {
      try {
        const response = await member_details({ id: userId }); // Use userId directly or use the updated id state if needed
        if (response.data && response.data.code === 200 && response.data.data.optOut) {
          console.log(response.data.data, "member details");
          setTemplateStatus(response.data.data.templateStatus);
          setShowKeyBoard(response.data.data.showKeyboard)
          setOptOut(response.data.data.optOut);
          setStatusNode(response.data.data.firbasenode)
          await update_status({ id: userId });
        }
      } catch (error) {
        console.error("Error fetching member details:", error);
      }
    };

    // Call the async function
    fetchMemberDetails();

    // Optional dependency array; if you want this to run whenever userId changes
  }, [userId]);

  

  // useEffect(() => {
  //   // Fetch initial message count if it hasn’t been set yet
  //   const fetchInitialMessageCount = async () => {
  //     try {
  //       if (!id) return;
  //       const response = await check_status(id);
  //       if (response.data.code == 200) {
  //         const initialCount = response.data.data.msgCount;
  //         setInitialMessageCount(initialCount);
  //         previousMessageCountRef.current = initialCount; // Set the ref to this initial value
          
  //       }
  //     } catch (error) {
  //       console.error("Failed to fetch initial message count:", error);
  //     }
  //   };

  //   // Only fetch if initialMessageCount is unset or selected contact changes
  //   if (initialMessageCount === 0 && id) {
  //      fetchInitialMessageCount();
  //   }
  // }, [id, initialMessageCount, setInitialMessageCount]);

  // useEffect(() => {
  //   // Check for new messages based on current and previous message count
  //   const checkForNewMessages = async () => {
  //     try {
  //       if (!id) return;
  //       const response = await check_status(id);
  //       if (response.data.code == 200) {
  //         const newMessageCount = response.data.data.msgCount;
  //         console.log(
  //           "previousMessageCountRef.current",
  //           previousMessageCountRef.current
  //         );
  //         if (newMessageCount > previousMessageCountRef.current) {
  //           console.log("New messages detected!");
  //           const diff = newMessageCount - previousMessageCountRef.current;
  //           setNewMessageCount(diff); // Set initial message count
  //           previousMessageCountRef.current = newMessageCount; // Set the ref to this initial value
  //           setInitialMessageCount(newMessageCount);
  //           setShowNewMessageAlert(true);
  //           setChatpage(1); // Call API on Enter
  //           setTimeout(() => {
  //             setChatResult([]);
  //             getchat(id, 1); // Fetch chat for the new message
  //             setNewMessageCount(0);
  //           }, 1000);

  //           setTemplateStatus(response.data.data.templateStatus);
  //           setShowKeyBoard(response.data.data.showKeyboard)
  //           console.log(showkeyboard,'showkeyboard')
  //           setOptOut(response.data.data.optOut);

  //           setTimeout(() => setShowNewMessageAlert(false), 3000);
  //         }
  //       }
  //     } catch (error) {
  //       console.error("Failed to check for new messages:", error);
  //     }
  //   };

  //   // Set up polling for new messages every 15 seconds
  //    const interval = setInterval(() => checkForNewMessages(), 15000);
  //   return () => clearInterval(interval);
  // }, [id, setInitialMessageCount]);

  const checkForNewMessages = async () => {
    try {
      if (!id) return;
      const response = await check_status(id);
      if (response.data.code == 200) {
        const newMessageCount = response.data.data.msgCount;
        console.log(
          "previousMessageCountRef.current",
          previousMessageCountRef.current
        );
        //if (newMessageCount > previousMessageCountRef.current) {
          console.log("New messages detected!");
          // const diff = newMessageCount - previousMessageCountRef.current;
          // setNewMessageCount(diff); // Set initial message count
          // previousMessageCountRef.current = newMessageCount; // Set the ref to this initial value
          // setInitialMessageCount(newMessageCount);
          setShowNewMessageAlert(true);
          setChatpage(1); // Call API on Enter
          setTimeout(() => {
            setChatResult([]);
            getchat(id, 1); // Fetch chat for the new message
            setNewMessageCount(0);
          }, 1000);

          setTemplateStatus(response.data.data.templateStatus);
          setShowKeyBoard(response.data.data.showKeyboard)
          console.log(showkeyboard,'showkeyboard')
          setOptOut(response.data.data.optOut);

          setTimeout(() => setShowNewMessageAlert(false), 3000);
        //}
      }
    } catch (error) {
      console.error("Failed to check for new messages:", error);
    }
  };

  const debouncedGetChat = useCallback(
    debounce((id, page) => {
      getchat(id, page);
    }, 500), // 500ms delay
    [] // Empty dependency array since getchat is likely coming from props
  );
  

  const handleScroll = useCallback(() => {
    if (!chatContainerRef.current) return;

    const container = chatContainerRef.current;
    const scrollPosition = Math.round(container.scrollTop);
    const totalHeight = Math.round(container.scrollHeight);
    const visibleHeight = Math.round(container.clientHeight);
    const threshold = 50;
    console.log(chatPage, "chatpage");
    // Check if user is at the top (for loading more messages)
    if (scrollPosition <= 10) {
      previousScrollHeightRef.current = totalHeight;
      if (chatPage > 1) {
        debouncedGetChat(id, chatPage);
      }
      setIsAtTop(true);
    } else {
      setIsAtTop(false);
    }

    // Check if user is near bottom
    const distanceFromBottom = totalHeight - (scrollPosition + visibleHeight);
    const isAtBottom = distanceFromBottom <= threshold;

    // Update states based on scroll position
    setIsAtBottom(isAtBottom);
    setShowScrollButton(!isAtBottom);
  }, [chatPage, id]); // Add dependencies as needed

  console.log(id, "userId22");

  const debouncedHandleScroll = useCallback(
    debounce(() => {
      handleScroll();
    }, 500), // 100ms delay for scroll handling
    [handleScroll]
  );

  useEffect(() => {
    const container = chatContainerRef.current;
    if (container) {
      container.addEventListener("scroll", debouncedHandleScroll);
      return () => {
        container.removeEventListener("scroll", debouncedHandleScroll);
        // Clean up any pending debounced calls
        debouncedHandleScroll.cancel?.();
        debouncedGetChat.cancel?.();
      };
    }
  }, [debouncedHandleScroll, debouncedGetChat]);

  const handleSendText = async () => {
    if (textMessage.trim()) {
      console.log("Sending text message:", textMessage);
      if (!id) {
        alert("No contact selected to send the message.");
        return;
      }
      setSendText(true);
      setChatpage(1);

      try {
        const response = await send_message(textMessage, id); // Pass the current page to the API
        console.log(response, "response");
        if (response.data.code == 200) {
          // setChatpage(1);
          setFormError(false);
         // setChatpage(1);
          setChatResult([]);
          getchat(id, 1);
          setLoadingMore(true);
          setShowEmojiPicker(false);
          setSendText(false);
        }else{
            setLoadingMore(true);
            setShowEmojiPicker(false);
            setSendText(false);
        }
      } catch (error) {
        console.error("Failed to fetch contacts:", error);
      }
      // Add send logic here
      setTextMessage(""); // Clear text after sending
    } else {
      setFormError(true)
      showError(
        'Empty Field',
        'Message cannot be empty'
      );
      // alert("Message cannot be empty");
    }
  };

  const onEmojiClick = (emojiObject) => {
    setTextMessage((prevMessage) => prevMessage + emojiObject.emoji); // Append emoji to the message
    setShowEmojiPicker(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target)
      ) {
        setShowEmojiPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fileclear = () => {
    setIsPopupOpen(false);
    fileInputRef.current.value = "";
    setImageNote("");
  };

  // Handle image selection and preview display
  const [errorModal, setErrorModal] = useState({
    isOpen: false,
    title: '',
    message: ''
  });

  const showError = (title, message) => {
    setErrorModal({
      isOpen: true,
      title,
      message
    });
  };

  // Handle image selection and preview display
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // 8MB = 8 * 1024 * 1024 bytes
    const maxSize = 8 * 1024 * 1024; // 8MB in bytes
    
    if (file.size > maxSize) {
      showError(
        'File Too Large',
        'File size exceeds 8MB limit. Please select a smaller file.'
      );
      return;
    }

    const fileMimeType = file.type;
    let fileType = "";
    let msgType = "";
    let isValid = false;

    // WhatsApp supported formats
    const supportedFormats = {
      image: ['image/jpeg', 'image/jpg', 'image/png', 'image/webp'],
      video: ['video/mp4', 'video/3gpp'],
      audio: ['audio/aac', 'audio/mp4', 'audio/mpeg', 'audio/amr', 'audio/ogg', 'audio/opus'],
      document: [
        'application/pdf',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation'
      ]
    };

    // Determine file type and validate format
    if (supportedFormats.image.includes(fileMimeType)) {
      fileType = "image";
      msgType = "image";
      isValid = true;
    } else if (supportedFormats.audio.includes(fileMimeType)) {
      fileType = "audio";
      msgType = "audio";
      isValid = true;
    } else if (supportedFormats.video.includes(fileMimeType)) {
      fileType = "video";
      msgType = "video";
      isValid = true;
    } else if (supportedFormats.document.includes(fileMimeType)) {
      fileType = fileMimeType === "application/pdf" ? "pdf" : "document";
      msgType = "document";
      isValid = true;
    }

    if (!isValid) {
      showError(
        'Unsupported File Format',
        `Supported formats are:\n
- Images: JPG, JPEG, PNG, WebP
- Videos: MP4, 3GPP
- Audio: AAC, MP4, MP3, AMR, OGG, OPUS
- Documents: PDF, Excel, Word, PowerPoint`
      );
      return;
    }

    // Additional specific format validations
    if (fileType === "video") {
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = function() {
        window.URL.revokeObjectURL(video.src);
        const duration = video.duration;
        
        if (duration > 30) {
          showError(
            'Video Too Long',
            'Video duration cannot exceed 30 seconds.'
          );
          return;
        }

        proceedWithFileReading(file, fileType, msgType);
      }

      video.src = URL.createObjectURL(file);
      return;
    }

    if (fileType === "image") {
      const img = new Image();
      img.onload = function() {
        window.URL.revokeObjectURL(img.src);
        
        if (this.width < 192 || this.height < 192) {
          showError(
            'Image Too Small',
            'Image dimensions must be at least 192x192 pixels.'
          );
          return;
        }

        if (this.width > 5040 || this.height > 5040) {
          showError(
            'Image Too Large',
            'Image dimensions cannot exceed 5040x5040 pixels.'
          );
          return;
        }

        proceedWithFileReading(file, fileType, msgType);
      }

      img.src = URL.createObjectURL(file);
      return;
    }

    // For other file types, proceed directly
    proceedWithFileReading(file, fileType, msgType);
  };

  const proceedWithFileReading = (file, fileType, msgType) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setFileType(fileType);
      setMsgType(msgType);
      setImageFile(reader.result);
      setImagePreview(reader.result);
      setIsPopupOpen(true);
    };
    reader.readAsDataURL(file);
  };

  // Send image with accompanying text
  const handleSendImageWithText = async () => {
    //if (imageFile && imageNote.trim()) {
    console.log("Variables:", { id, imageFile, imageNote, fileType, msgType });
    const addFileFormData = { id, imageFile, imageNote, fileType, msgType };

    // const formData = new FormData();
    // formData.append("id", id); // Add contact ID
    // formData.append("message", imageNote); // Add the text note
    // formData.append("imageFile", imageFile); // Add the image file
    // formData.append("fileType", fileType); // Add the image file
    // formData.append("msgType", msgType); // Add the image file
    // console.log("here")
    // for (let [key, value] of formData.entries()) {
    //     console.log(`${key}:`, value);
    //   }
    setSendText(true);

    try {
      const response = await send_message_v1(addFileFormData);
        console.log(response,'newresponse')
      if (response.data && response.data.code == 200) {
        setChatpage(1);
        setChatResult([]);
        getchat(id, 1);
        setLoadingMore(true);
        setShowEmojiPicker(false);
        setSendText(false);
      }else{
        setLoadingMore(true);
        setShowEmojiPicker(false);
        setSendText(false);
        }
    } catch (error) {
      console.error("Error sending image with text:", error);
    }
    // Add send logic here
    setImageFile(null);
    setImagePreview(null);
    setImageNote("");
    setIsPopupOpen(false);
    setFileType("");
    setMsgType("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    // } else {
    //     alert("Both image and message cannot be empty");
    // }
  };

  const scrollToBottom = () => {
    // alert("in bttom")
    if (!chatEndRef.current || !chatContainerRef.current) return;

    const container = chatContainerRef.current;
    const targetPosition = chatEndRef.current.offsetTop;
    const startPosition = container.scrollTop;
    const containerHeight = container.clientHeight;
    const targetHeight = chatEndRef.current.clientHeight;

    // Calculate the exact position to scroll to
    const scrollDistance =
      targetPosition - startPosition - containerHeight + targetHeight + 32; // adding padding

    try {
      container.scrollTo({
        top: container.scrollTop + scrollDistance,
        behavior: "instant", // Use 'instant' for initial load
      });
    } catch (error) {
      // Fallback for older browsers
      container.scrollTop = container.scrollTop + scrollDistance;
    }
  };

  const getchat = async (id, newStartPage) => {
    try {
      setLoading(true)
      const response = await fetch_chat(id, newStartPage); // Pass the current page to the API
      if (response.data.data.chat && response.data.data.chat.length > 0) {
        setChatResult((prevChats) => [
          ...response.data.data.chat,
          ...prevChats,
        ]); // Prepend new messages
        setChatpage(newStartPage + 1); // Update the current page
        setAccessLevel(response.data.data.cnRoleAccess) // update access level
        
        setLoading(false)
        // setLoadingMore(true);
      } else {
        setLoadingMore(false); // No more data to load
        setAccessLevel(response.data.data.cnRoleAccess) // update access level
        setLoading(false)

      }
    } catch (error) {
      console.error("Failed to fetch contacts:", error);
    }
  };

  useEffect(() => {
    if (!id) return;

    const loadChatAndStatus = async () => {
      try {
        console.log("inside chat");
        setChatResult([]); // Clear previous chat messages
        setLoadingMore(true); // Set loading state
        await getchat(id, 1); // Load chat messages
        setLoadingMore(false); // Reset loading statep
      } catch (error) {
        console.error("Failed to load chat and status:", error);
        setLoadingMore(false);
      }
    };

    loadChatAndStatus();
  }, [id]);

  useEffect(() => {
    if (chatResult.length > 0 && !loadingMore) {
      // Small timeout to ensure DOM is updated
      const scrollTimeout = setTimeout(() => {
        scrollToBottom();
      }, 100);

      return () => clearTimeout(scrollTimeout);
    }
  }, [loadingMore]);

  const refreshchat = () => {
    setChatResult([]);
    setChatpage(1);
    getchat(id, 1);
    setLoadingMore(true);
    setTimeout(() => {
      scrollToBottom();
    }, 200);
  };

  // Update scroll position after new messages are loaded
  useEffect(() => {
    if (isAtBottom) {
      scrollToBottom();
    } else {
      // Maintain the previous scroll position when loading new data
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop =
          chatContainerRef.current.scrollHeight -
          previousScrollHeightRef.current;
      }
    }
  }, [chatResult]);


    useEffect(() => { 
      console.log("inside firebase1",firebaseNode);
      if (!firebaseNode) return;
      let unsubscribe;
      try{ 
        if(firebaseNode !== ""){ 
          const database = getDatabase(firebase);
          console.log(database,'database')
          // just read values from this database 
          const dbRef = ref(database);
          const realtimeRef = ref(database, `${firebaseNode}/newmessage`);

          
          // write a onchange 
          unsubscribe = onValue(realtimeRef, (snapshot) => {
            const data = snapshot.val();
            console.log(data, 'data from firebase')
            if(data?.userId == userId){ 
              checkForNewMessages(); 
            } 

          });
        }
      }catch(error){ 
        console.log(error, 'error in firebase node'); 
      }

      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
  }, [firebaseNode]);    


  useEffect(() => {
    if (!statusNode) return;
    let unsubscribe;
    try {
      if (statusNode !== "") {
        const database = getDatabase(firebase);
        console.log(database, "database");
        // just read values from this database
        const dbRef = ref(database);
        const realtimeRef = ref(database, `${statusNode}/status`);
        console.log(realtimeRef,'realtimeRef')

        // write a onchange
        unsubscribe = onValue(realtimeRef, (snapshot) => {
          const data = snapshot.val();
          console.log(data, "data from firebase status");
          if (data?.userId == userId) {
            console.log(data.status, data.twId);
            let twId = data.twId;
            console.log("twId values", twId);

            setChatResult((pre) => {
              return pre.map((row) => {
                if (row.messageId === twId) {
                  // Return a new object with updated messageStatus
                  return { ...row, messageStatus: data.status };
                }
                // Return the original object if no match
                return row;
              });
            });
          }
        });
      }
    } catch (error) {
      console.log(error, "error in firebase node");
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [statusNode]);

  const getMessageStatusIcon = (status) => {
    switch (status?.toLowerCase()) {
      case "sent":
        return <BiCheck className="tw-w-4 tw-h-4 tw-text-gray-500" />;
      case "delivered":
      case "unread":
        return <BiCheckDouble className="tw-w-4 tw-h-4 tw-text-gray-500" />;
      case "read":
        return <BiCheckDouble className="tw-w-4 tw-h-4 tw-text-blue-500" />;
      case "undelivered":
      case "failed":
        return (
          <i className="bi bi-info-circle tw-w-4 tw-h-4 tw-text-red-500"></i>
        );
      case "queued":
      default:
        return <BiCheck className="tw-w-4 tw-h-4 tw-text-gray-500" />;
    }
  };


  const replaceVariables = (content) => {
    if (!content) return "";

    // URL regex for better link detection
    const urlRegex = /(?:(?:https?:\/\/)?(?:www\.)?)?[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z]{2,})+(?:\/[^\s]*)?/gi;
    
    const commonTLDs = ['com', 'org', 'net', 'edu', 'gov', 'mil', 'io', 'co', 'ai', 'app', 'dev', 'uk', 'us', 'eu', 'in', 'de', 'fr','xyz'];

     
     // First handle line breaks
     content = content.replace(/\\n|\n/g, "<br/>");

     // Split content by HTML tags and process each part
     const parts = content.split(/(<[^>]*>)/);
     content = parts.map(part => {
         // If it's an HTML tag, return it unchanged
         if (part.startsWith('<') && part.endsWith('>')) {
             return part;
         }
         // If part contains Arabic/Urdu, wrap it in RTL div
         if (/[\u0600-\u06FF\uFB50-\uFDFF\uFE70-\uFEFF]/.test(part)) {
             return `<div class="tw-text-right" dir="rtl" style="text-align: right;">${part}</div>`;  
         }
         // If it's non-empty text without Arabic/Urdu, wrap it in regular div
         if (part.trim()) {
             return `<div>${part}</div>`;
         }
         // Return empty parts unchanged
         return part;
     }).join('');

    //  console.log(content,'content')
    return (
      content
        // Handle line breaks first
        // .replace(/\\n/g, "<br />")

        // Bold: *text* (not preceded or followed by space)
        .replace(/\*([^*]+)\*/g, "<strong>$1</strong>")

        // Italic: _text_ (not preceded or followed by space)
        .replace(/(?<!\s)_(.*?)_(?!\s)/g, "<em>$1</em>")

        // Strikethrough: ~text~ (not preceded or followed by space)
        .replace(/(?<!\s)~(.*?)~(?!\s)/g, "<del>$1</del>")

        // Monospace: ```text```
        .replace(/```(.*?)```/g, "<code>$1</code>")

        // Single line code: `text`
        .replace(/`(.*?)`/g, "<code>$1</code>")
        

        // Enhanced URL detection and replacement
        .replace(urlRegex, (url) => {
          // Check if this matches common URL patterns
          const isLikelyURL = commonTLDs.some(tld => 
            url.toLowerCase().includes(`.${tld}`) &&
            url.match(/^[^@\s]+\.[^\s]+$/) // Exclude email addresses
          );

          if (!isLikelyURL) return url;

          // Add protocol if missing
          let fullUrl = url;
          
          if (!url.match(/^https?:\/\//i)) {
            fullUrl = 'https://' + url;
          }

          // Validate URL
          try {
            const cleanedUrl = fullUrl.replace("</div>", "");
            new URL(fullUrl);
            return `<a href="${cleanedUrl}" class="tw-text-blue-600 hover:tw-underline" target="_blank" rel="check noopener noreferrer">${url}</a>`;
          } catch {
            return url;
          }
        })

        // WhatsApp style quotes: > at start of line
        .replace(
          /(^|\n)&gt; ?([^\n]*)/g,
          '$1<div class="tw-border-l-4 tw-border-gray-300 tw-pl-3 tw-my-1 tw-text-gray-600">$2</div>'
        )

        // Lists: - or • at start of line
        .replace(
          /(^|\n)[-•] ?([^\n]*)/g,
          '$1<div class="tw-flex tw-gap-2 tw-my-1">•<span>$2</span></div>'
        )

        // Handle multiple underscores or asterisks in a single word
        .replace(/(\*{2}|_{2})(.*?)\1/g, "<strong>$2</strong>")
        .replace(/(\*{3}|_{3})(.*?)\1/g, "<strong><em>$2</em></strong>")

        // Fix any potentially broken HTML from nested formatting
        .replace(/<(\/?)(strong|em|del|code)>/g, "<$1$2>")
    );
  };

  console.log("showkeyboard", showkeyboard);
  console.log("templateStatus", templateStatus);

  const isEmojiDisabled = !showkeyboard;
  const isAttachmentDisabled = !showkeyboard;
  const isTemplateDisabled = !showkeyboard && !templateStatus;

  const convertUrlsToLinks = (text) => {
    if (!text) return '';
    
    // More comprehensive URL regex that matches:
    // - Standard URLs with http/https
    // - URLs starting with www
    // - URLs like example.com, example.co.in, etc.
    const urlRegex = /((?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|(?:www\.)?[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,})/gi;
  
    return text.replace(urlRegex, (url) => {
      // Check if the URL already has a protocol
      let fullUrl = url;
      if (!url.match(/^https?:\/\//i)) {
        fullUrl = 'https://' + url;
      }
  
      // Additional check to prevent false positives
      try {
        new URL(fullUrl);
        return `<a href="${fullUrl}" target="_blank" rel="noopener noreferrer" class="tw-text-blue-600 hover:tw-underline">${url}</a>`;
      } catch {
        return url; // Return original text if not a valid URL
      }
    });
  };


  const handleMediaModal = (url, mediaType) => {
    setSelectedMedia({ url, type: mediaType });
    setIsModalOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedMedia({ url: '', type: '' });
    document.body.style.overflow = 'unset';
  };

  const handleDownload = async () => {
    // const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
    const imageUrl = selectedMedia.url;
  
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
  
      // Create a download link
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'image.jpg'); // Filename for the download
      document.body.appendChild(link);
      link.click();
  
      // Clean up
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading the image:', error);
    }
  };
  

  return (
    <>
      <div className="chatDiv">
        <Row className="h-100">
          <div className="tw-h-full tw-w-full tw-flex tw-flex-col tw-bg-gray-50">
            {/* Header */}
            <div className="tw-bg-white tw-border-b tw-px-4 tw-h-10 tw-flex tw-justify-between tw-items-center tw-flex-shrink-0">
              <div className="tw-flex tw-items-center tw-justify-between tw-flex-col">
                <h5 className="tw-text-base tw-pt-1.5">WhatsApp Chats</h5>
                {/* <h2 className="tw-font-bold tw-text-sm tw-text-gray-500">
                  XXXXXXXXXX
                </h2> */}
              </div>
              <span
                  onClick={refreshchat}
                className="btn btn-sm btn-light tw-cursor-pointer"
              >
                <i className="bi bi-arrow-clockwise"></i>
              </span>
            </div>

            {/* Chat Body */}
            {/* <div className="tw-flex-1 tw-overflow-y-auto tw-p-4">
        <div className="tw-space-y-6">
          <div className="tw-flex tw-justify-center">
            <div className="tw-bg-blue-50 tw-text-gray-600 tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm">
              This conversation will be ended if you don't reply stop
              <div className="tw-text-xs tw-text-gray-500 tw-mt-1">Today 9:19 AM</div>
            </div>
          </div>

          
          <div className="tw-flex tw-justify-end tw-items-start tw-gap-2">
            <div className="tw-flex tw-flex-col tw-items-end">
              <div className="tw-bg-blue-50 tw-rounded-lg tw-px-4 tw-py-2 tw-shadow-sm">
                hi
              </div>
              <div className="tw-text-xs tw-text-gray-500 tw-mt-1">Today 12:52 PM</div>
            </div>
            <div className="tw-w-8 tw-h-8 tw-bg-blue-900 tw-rounded-full tw-flex tw-items-center tw-justify-center tw-text-white">
              S
            </div>
          </div>

          <div className="tw-flex tw-items-start tw-gap-2">
            <div className="tw-w-8 tw-h-8 tw-bg-blue-900 tw-rounded-full tw-flex tw-items-center tw-justify-center tw-text-white">
              S
            </div>
            <div className="tw-flex tw-flex-col">
              <div className="tw-bg-white tw-rounded-lg tw-px-4 tw-py-2 tw-shadow-sm">
                Hello
              </div>
              <div className="tw-text-xs tw-text-gray-500 tw-mt-1">Today 2:29 PM</div>
            </div>
          </div>
          <div className="tw-flex tw-items-start tw-gap-2">
            <div className="tw-w-8 tw-h-8 tw-bg-blue-900 tw-rounded-full tw-flex tw-items-center tw-justify-center tw-text-white">
              S
            </div>
            <div className="tw-flex tw-flex-col">
              <div className="tw-bg-white tw-rounded-lg tw-px-4 tw-py-2 tw-shadow-sm">
                Hello
              </div>
              <div className="tw-text-xs tw-text-gray-500 tw-mt-1">Today 2:29 PM</div>
            </div>
          </div>
        </div>
      </div> */}

            {/* Chat messages */}
            {/* <div className='tw-'> */}
            <div
              // onScroll={handleScroll}
              ref={chatContainerRef}
              className="tw-flex-1 tw-overflow-y-auto tw-p-4 tw-relative tw-bg-gray-100"
              style={{
                minHeight: 0, // Important for proper flex behavior
                scrollbarWidth: "thin",
                scrollbarColor: "#CBD5E0 #F3F4F6",
              }}
            >
              {loading ? (
                <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-py-4 tw-text-gray-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  height="48"
                  viewBox="0 0 24 24"
                >
                  <circle cx="4" cy="12" r="3" fill="currentColor">
                    <animate
                      id="svgSpinners3DotsFade0"
                      fill="freeze"
                      attributeName="opacity"
                      begin="0;svgSpinners3DotsFade1.end-0.25s"
                      dur="0.75s"
                      values="1;0.2"
                    />
                  </circle>
                  <circle cx="12" cy="12" r="3" fill="currentColor" opacity="0.4">
                    <animate
                      fill="freeze"
                      attributeName="opacity"
                      begin="svgSpinners3DotsFade0.begin+0.15s"
                      dur="0.75s"
                      values="1;0.2"
                    />
                  </circle>
                  <circle cx="20" cy="12" r="3" fill="currentColor" opacity="0.3">
                    <animate
                      id="svgSpinners3DotsFade1"
                      fill="freeze"
                      attributeName="opacity"
                      begin="svgSpinners3DotsFade0.begin+0.3s"
                      dur="0.75s"
                      values="1;0.2"
                    />
                  </circle>
                </svg>
                Loading...
              </div>
              ) : chatResult.length > 0 ? (
                chatResult.map((message, index) => (
                    <React.Fragment>
                    <div
                      key={message.id}
                      className={`tw-mb-4 tw-gap-2 tw-flex ${
                        message.senderType === "agent" ? "tw-justify-end" : ""
                      }`}
                      // ref={message.lastElement === true ? chatEndRef : null}
                      ref={index === chatResult.length - 1 ? chatEndRef : null}
                    >
                      {/* User message */}
                      {message.senderType === "user" && (
                       <div className="tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-2 tw-bg-white tw-py-2 tw-rounded-lg tw-max-w-[300px]">
                       {/* <div className="tw-w-8 tw-h-8 tw-bg-[#03335b] tw-text-white tw-rounded-full tw-flex tw-items-center tw-justify-center tw-mt-2 tw-flex-shrink-0">
                         {message.sender.split(" ")[0].charAt(0).toUpperCase()}
                       </div> */}
                       <p className="tw-pb-0 tw-mb-0 tw-font-semibold tw-text-[#03335B] tw-capitalize tw-text-sm tw-px-2">{message.sender}</p>
                          <div className={`tw-flex tw-flex-col tw-w-full tw-items-start tw-justify-start tw-relative tw-z-10 tw-text-xs`}>
                            {/* Reply Preview */}
      
                            {/* {message.originalId ? (
              <div className="tw-absolute -tw-bottom-12 -tw-z-10 border tw-w-full tw-max-w-[142px] tw-pb-4 tw-pt-1 tw-pl-1 tw-rounded-lg tw-flex tw-items-center tw-gap-2 tw-mt-2 tw-bg-gray-200">
                <i class="bi bi-reply-all-fill"></i>
                <p className='tw-mb-0'>Reply</p>
              </div>
            ) : null } */}
      
                            {/* <div className={`${message.originalId ? "tw-flex tw-flex-row" : ""}`}> */}
                            {message.originalId ? (
                              <div className="tw-max-w-[250px] tw-w-[250px]">
                                {message.headerType.toLowerCase() === "image" && (
                                  <div className="tw-bg-gray-50 tw-w-full tw-p-3 border tw-border-l-4 tw-border-b-0 tw-rounded-t-lg tw-flex tw-items-center tw-gap-2">
                                    <img
                                      src={message.mediaUrl}
                                      alt={message.templateName}
                                      className="tw-w-full tw-h-full tw-object-cover tw-rounded"
                                    />
                                  </div>
                                )}
                                {/* {message.headerType.toLowerCase() === "text" && (
                     <span className="tw-text-sm">{message.replyText}</span>
                   )} */}
                              </div>
                            ) : (
                              <>
                                {message.msgType === "image" && (
                                  <div className="tw-max-w-[250px] tw-w-[250px]">
                                    <div
                                      className={`tw-bg-white tw-rounded-t-lg ${
                                        message.originalText
                                          ? "border tw-border-b-0"
                                          : ""
                                      } tw-p-1`}
                                    >
                                      <img
                                        src={message.imgUrl}
                                        alt="image"
                                        className="tw-w-full tw-object-cover tw-rounded-lg"
                                        onClick={() => handleMediaModal(message.imgUrl, message.msgType)}
                                      />
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
      
                            {message.originalId ? (
                              <div className="tw-max-w-[250px] tw-w-[250px]">
                        {message.headerType.toLowerCase() === "audio" && (
                          <div className="tw-flex tw-items-center tw-gap-2 border tw-border-l-2">
                          <div className="tw-flex-shrink-0 tw-w-10 tw-h-10 tw-rounded-full tw-bg-[#03335b] tw-flex tw-items-center tw-justify-center">
                            <i className="bi bi-mic-fill tw-text-white"></i>
                          </div>
                          <audio controls className="tw-w-full">
                          <source src={message.mediaUrl} type="audio/aac" />
                          <source src={message.mediaUrl} type="audio/mp4" />
                          <source src={message.mediaUrl} type="audio/mpeg" />
                          <source src={message.mediaUrl} type="audio/amr" />
                          <source src={message.mediaUrl} type="audio/ogg" />
                          <source src={message.mediaUrl} type="audio/opus" />
                          </audio>
                        </div>
                        )}
                        {/* {message.headerType.toLowerCase() === "text" && (
             <span className="tw-text-sm">{message.replyText}</span>
           )} */}
                      </div>
                            ) : (
                              <>
                                {message.msgType === "audio" && (
                                  <div
                                    className={`tw-bg-white tw-rounded-t-lg ${
                                      message.originalText ? "border" : ""
                                    } tw-p-2 tw-w-[300px] tw-max-w-[300px]`}
                                  >
                                    <div className="tw-flex tw-items-center tw-gap-2">
                                      <div className="tw-flex-shrink-0 tw-w-10 tw-h-10 tw-rounded-full tw-bg-[#03335b] tw-flex tw-items-center tw-justify-center">
                                        <i className="bi bi-mic-fill tw-text-white"></i>
                                      </div>
                                      <audio
                                        controls
                                        className="tw-w-full"
                                      >
                                          <source src={message.imgUrl} type="audio/aac" />
                                          <source src={message.imgUrl} type="audio/mp4" />
                                          <source src={message.imgUrl} type="audio/mpeg" />
                                          <source src={message.imgUrl} type="audio/amr" />
                                          <source src={message.imgUrl} type="audio/ogg" />
                                          <source src={message.imgUrl} type="audio/opus" />
                                      </audio>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
      
                            {message.originalId ? (
                              <div className="tw-max-w-[250px] tw-w-[250px]">
                                {message.headerType.toLowerCase() === "video" && (
                                  <video
                                    className="tw-bg-gray-50 tw-w-full tw-p-3 border tw-border-b-0 tw-rounded-t-lg tw-flex tw-items-center tw-gap-2"
                                    controls
                                  >
                                    <source src={message.mediaUrl} type="video/mp4" />
                                  </video>
                                )}
                              </div>
                            ) : (
                              <>
                                {message.msgType === "video" && (
                                  <div className="tw-max-w-[250px] tw-w-[250px]">
                                    <div
                                      className={`tw-bg-white ${
                                        message.originalText ? "border" : ""
                                      } tw-rounded-t-lg tw-p-1`}
                                    >
                                      <video
                                        controls
                                        src={message.imgUrl}
                                        className="tw-w-full tw-rounded-lg"
                                      />
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                            {message.originalId ? (
                              <>
                                {message.headerType.toLowerCase() === "document" && (
                                  <div className="tw-bg-gray-50 tw-w-full tw-p-3 border tw-border-b-0 tw-rounded-t-lg tw-flex tw-items-center tw-gap-2">
                                    <svg
                                      className="tw-w-6 tw-h-6"
                                      fill="none"
                                      stroke="currentColor"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                                      />
                                    </svg>
                                    <span className="tw-text-sm">
                                      Document attached
                                    </span>
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                {message.msgType === "document" && (
                                  <div
                                    className={`tw-bg-white tw-cursor-pointer ${
                                      message.originalText ? "border" : ""
                                    } tw-rounded-t-lg tw-max-w-[250px] tw-w-[250px] tw-overflow-hidden`}
                                    onClick={() => handleMediaModal(message.imgUrl, message.msgType)}
                                  >
                                    <div
                                className="tw-flex tw-items-center tw-gap-3 tw-p-3 tw-bg-gray-50 tw-cursor-pointer">
                                <div className="tw-flex-shrink-0">
                                  <i className="bi bi-file-earmark-pdf tw-text-2xl tw-text-red-500"></i>
                                </div>
                                <div className="tw-flex-1 tw-min-w-0">
                                  <div className="tw-truncate tw-text-sm">
                                    Document
                                  </div>
                                  <div className="tw-text-xs tw-text-gray-500">
                                    PDF
                                  </div>
                                </div>
                                <span className="tw-px-3 tw-py-1 tw-text-sm tw-text-white tw-bg-[#03335b] tw-rounded hover:tw-bg-[#1279BE] tw-transition-colors">
                                  Preview
                                </span>
                              </div>
                                    {message.mimeType === "application/pdf" ? (
                                      <iframe
                                        src={message.imgUrl}
                                        title="PDF Document"
                                        className="tw-w-full "
                                      />
                                    ) : (
                                      <a
                                        href={message.imgUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="tw-block tw-p-2 tw-text-blue-600 tw-text-sm tw-text-center hover:tw-bg-gray-50"
                                      >
                                        View Document
                                      </a>
                                    )}
                                  </div>
                                )}
                              </>
                            )}
      
      {(!/\.(pdf|csv|jpeg|mp4)$/i.test(message.messageText) && message.messageText) || message.originalText ? (
                                <div className={`tw-max-w-[250px] tw-bg-white ${message.originalId ? `tw-w-[250px] border tw-border-l-4 tw-truncate ${message.mediaUrl ? "tw-border-t-0" : "tw-rounded-t-lg"} ` : "tw-rounded-lg"}`}>
                                  {/* <p className="tw-pb-0 tw-mb-0 tw-font-semibold tw-text-[#03335B] tw-capitalize tw-text-sm">{message.sender}</p> */}
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: message.originalId
                                        ? replaceVariables(message.originalText)
                                        : replaceVariables(message.messageText)
                                            // .split("")
                                            // .reduce((result, char, index) => {
                                            //   if (index > 0 && index % 25 === 0) {
                                            //     return result + "\u200B" + char;
                                            //   }
                                            //   return result + char;
                                            // }, "")
                                    }}
                                    className={`
               tw-text-sm tw-pl-2
              ${ message.msgType.toLowerCase() === "image" ||
                message.msgType.toLowerCase() === "video" ||
                message.msgType.toLowerCase() === "document" ||
                message.msgType.toLowerCase() === "audio"
                 ? "tw-w-[250px]" : ""}  
                 ${message.originalId ? "tw-max-h-12 tw-truncate" : ""}
              ${(() => {
                // If this is a reply message
                if (message.originalId) {
                  // If there's a header type (image, video, etc)
                  if (message.headerType) {
                    return "border tw-border-t-0 tw-border-b-0";
                  }
                  // If it's just text reply
                  return "border tw-rounded-t-lg";
                }
      
                // If there's a reply text but not a reply message
                if (message.originalText) {
                  // If there's media above
                  if (
                    message.msgType.toLowerCase() === "image" ||
                    message.msgType.toLowerCase() === "video" ||
                    message.msgType.toLowerCase() === "document" ||
                    message.msgType.toLowerCase() === "audio"
                  ) {
                    return "border tw-border-t-0 tw-rounded-b-lg";
                  }
                  return "border tw-rounded-lg";
                }
      
                // Regular message
                // Check if there's only imgUrl without messageText
                if (message.imgUrl && !message.messageText) {
                  return "border tw-border-t-0 tw-rounded-b-lg";
                } else if (message.imgUrl && message.messageText) {
                  return " tw-rounded-b-lg";
                }
                return "tw-rounded-lg";
              })()}
            `}
                                  />
                                </div>
                              ) : null}
                            {/* </div> */}
                            {message.originalId && (
                              <div className="tw-max-w-[250px] tw-mb-1">
                                <div
                                  className={`tw-bg-gray-200 ${message.originalId ? "tw-w-[250px]" : ""} tw-rounded-b-lg tw-p-2`}
                                >
                                  <div className="tw-text-xs tw-font-medium tw-text-[#03335b] tw-mb-1">
                                    {message.originalId ? message.sender : ""}
                                  </div>
                                  <div className="">
                                    {message.msgType === "image" && (
                                      <div className="tw-max-w-[250px]">
                                        <div
                                          className={`tw-bg-white ${
                                            message.originalId === ""
                                              ? "tw-rounded-lg"
                                              : "border tw-border-b-0 tw-rounded-t-lg"
                                          } tw-p-1`}
                                        >
                                          <img
                                            src={message.imgUrl}
                                            alt="image"
                                            className="tw-w-full tw-max-h-[280px] tw-object-cover tw-rounded-lg"
                                            onClick={() => handleMediaModal(message.imgUrl, message.msgType)}
                                          />
                                        </div>
                                      </div>
                                    )}
      
                                    {message.msgType === "audio" && (
                                      <div
                                        className={`tw-bg-white tw-rounded-t-lg ${
                                          message.originalText ? "border" : ""
                                        } tw-p-2 tw-w-[300px] tw-max-w-[300px]`}
                                      >
                                        <div className="tw-flex tw-items-center tw-gap-2">
                                          <div className="tw-flex-shrink-0 tw-w-10 tw-h-10 tw-rounded-full tw-bg-[#03335b] tw-flex tw-items-center tw-justify-center">
                                            <i className="bi bi-mic-fill tw-text-white"></i>
                                          </div>
                                          <audio
                                            controls
                                            className="tw-w-full tw-h-[36px]"
                                          >
                                              <source src={message.imgUrl} type="audio/aac" />
                                              <source src={message.imgUrl} type="audio/mp4" />
                                              <source src={message.imgUrl} type="audio/mpeg" />
                                              <source src={message.imgUrl} type="audio/amr" />
                                              <source src={message.imgUrl} type="audio/ogg" />
                                              <source src={message.imgUrl} type="audio/opus" />
                                          </audio>
                                        </div>
                                      </div>
                                    )}
      
                                    {message.msgType === "video" && (
                                      <div className="tw-max-w-[250px]">
                                        <div
                                          className={`tw-bg-white ${
                                            message.originalText ? "border" : ""
                                          } tw-rounded-t-lg tw-p-1`}
                                        >
                                          <video
                                            controls
                                            src={message.imgUrl}
                                            className="tw-w-full tw-max-h-[280px] tw-rounded-lg"
                                          />
                                        </div>
                                      </div>
                                    )}
      
                                    {message.msgType === "document" && (
                                      <div
                                        className={`tw-bg-white tw-cursor-pointer ${
                                          message.originalText ? "border" : ""
                                        } tw-rounded-t-lg tw-max-w-[250px] tw-overflow-hidden`}
                                        onClick={() => handleMediaModal(message.imgUrl, message.msgType)}
                                      >
                                         <div
                                className="tw-flex tw-items-center tw-gap-3 tw-p-3 tw-bg-gray-50 tw-cursor-pointer">
                                <div className="tw-flex-shrink-0">
                                  <i className="bi bi-file-earmark-pdf tw-text-2xl tw-text-red-500"></i>
                                </div>
                                <div className="tw-flex-1 tw-min-w-0">
                                  <div className="tw-truncate tw-text-sm">
                                    Document
                                  </div>
                                  <div className="tw-text-xs tw-text-gray-500">
                                    PDF
                                  </div>
                                </div>
                                <span className="tw-px-3 tw-py-1 tw-text-sm tw-text-white tw-bg-[#03335b] tw-rounded hover:tw-bg-[#1279BE] tw-transition-colors">
                                  Preview
                                </span>
                              </div>
                                        {message.mimeType === "application/pdf" ? (
                                          <iframe
                                            src={message.imgUrl}
                                            title="PDF Document"
                                            className="tw-w-full tw-h-[200px]"
                                          />
                                        ) : (
                                          <a
                                            href={message.imgUrl}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="tw-block tw-p-2 tw-text-blue-600 tw-text-sm tw-text-center hover:tw-bg-gray-50"
                                          >
                                            View Document
                                          </a>
                                        )}
                                      </div>
                                    )}
                                    <span
                                dangerouslySetInnerHTML={{
                                  __html: replaceVariables(
                                    message.messageText
                                  ),
                                }}
                                className={`tw-text-sm tw-text-gray-600 tw-break-words tw-overflow-hidden`}
                              ></span>
                            </div>
                          </div>
                        </div>
                            )}
                            <div className="tw-text-xs tw-text-gray-500 tw-mt-1 tw-ml-1">
                              {message.logDateTime}
                            </div>
                          </div>
                        </div>
                      )}
      
                      {/* <span className='tw-sticky tw-top-0 tw-right-0 tw-left-0 tw-mx-auto'>{message.day ? message.day : '' }</span> */}
      
                      {/* Agent message */}
                      {message.senderType === "agent" && (
                        <>
                          <div
                            className={`tw-max-w-[250px] ${
                              message.messageStatus === "undelivered" ||
                              message.messageStatus === "failed"
                                ? "tw-flex tw-items-center tw-gap-2"
                                : ""
                            } `}
                          >
                            {message.messageStatus === "undelivered" ||
                            message.messageStatus === "failed" ? (
                              <div className="tw-relative">
                                <div
                                  className="tw-cursor-pointer"
                                  onMouseEnter={() =>
                                    setActiveTooltip(message.id)
                                  }
                                  onClick={() => setActiveTooltip(message.id)}
                                  // onMouseLeave={() => setActiveTooltip(null)}
                                >
                                  {getMessageStatusIcon(message.messageStatus)}
                                </div>

                                {activeTooltip === message.id && (
                                  <div
                                    className="tw-absolute tw-z-[9999999] tw-w-64 tw-h-72 tw-overflow-y-auto tw-left-[-38px] tw-top-[17px] tw-bg-white tw-rounded-lg tw-border tw-shadow-lg tw-p-4"
                                    onMouseEnter={() => setActiveTooltip(message.id)}
                                    onMouseLeave={() => setActiveTooltip(null)}
                                    style={{
                                      scrollbarWidth: "thin",
                                      scrollbarColor: "#CBD5E0 #F3F4F6",
                                    }}
                                  >
                                    <div className="tw-relative">
                                      <p className="tw-text-sm tw-font-bold tw-text-red-600 tw-mb-2">
                                        ERROR: {message.errorCode}
                                      </p>
                                      <p className="tw-text-sm tw-text-gray-600 tw-mb-3">
                                        {message.description}
                                      </p>
                                      <div className="tw-flex tw-flex-col tw-gap-2">
                                        <span className="tw-text-sm tw-text-wrap">
                                          <a
                                            href={`https://www.twilio.com/docs/api/errors/${message.errorCode}`}
                                            className="tw-text-blue-500 hover:tw-underline tw-text-sm tw-mr-1"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            onClick={(e) => e.stopPropagation()}
                                          >
                                            View Error Details
                                          </a>
                                          about Error code{" "}
                                          <strong>{message.errorCode}</strong>
                                        </span>
                                        <span className="tw-text-sm tw-break-words">
                                          Possible Solution: {message.solution}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ) : null}

                            <div>
                              {message.msgType === "image" && (
                                <div className="tw-max-w-[250px]">
                                  <div className="tw-bg-[#f0f4ff] tw-rounded-t-lg tw-p-1 tw-shadow-sm">
                                    <img
                                      src={message.imgUrl}
                                      alt="image"
                                      className="tw-w-full tw-max-h-[280px] tw-object-cover tw-rounded-lg"
                                      onClick={() => handleMediaModal(message.imgUrl, message.msgType)}
                                    />
                                  </div>
                                </div>
                              )}
      
                              {message.msgType === "audio" && (
                                <div className="tw-bg-[#f0f4ff] tw-rounded-t-lg tw-p-2 tw-shadow-sm tw-w-[300px] tw-max-w-[300px]">
                                  <div className="tw-flex tw-items-center tw-gap-2">
                                    <div className="tw-flex-shrink-0 tw-w-10 tw-h-10 tw-rounded-full tw-bg-[#03335b] tw-flex tw-items-center tw-justify-center">
                                      <i className="bi bi-mic-fill tw-text-white"></i>
                                    </div>
                                    <audio controls className="tw-w-full tw-h-[36px]">
                                    <source src={message.imgUrl} type="audio/aac" />
                                    <source src={message.imgUrl} type="audio/mp4" />
                                    <source src={message.imgUrl} type="audio/mpeg" />
                                    <source src={message.imgUrl} type="audio/amr" />
                                    <source src={message.imgUrl} type="audio/ogg" />
                                    <source src={message.imgUrl} type="audio/opus" />
                                    </audio>
                                  </div>
                                </div>
                              )}
      
                              {message.msgType === "video" && (
                                <div className="tw-max-w-[250px]">
                                  <div className="tw-bg-[#f0f4ff] tw-rounded-t-lg tw-p-1 tw-shadow-sm">
                                    <video
                                      controls
                                      src={message.imgUrl}
                                      className="tw-w-full tw-max-h-[280px] tw-rounded-lg"
                                    />
                                  </div>
                                </div>
                              )}
      
                              {message.msgType === "document" && (
                                <div 
                                onClick={() => handleMediaModal(message.imgUrl, message.msgType)}
                                className="tw-bg-[#f0f4ff] tw-cursor-pointer tw-rounded-t-lg tw-shadow-sm tw-max-w-[250px] tw-overflow-hidden">
                                   <div
                                className="tw-flex tw-items-center tw-gap-3 tw-p-3 tw-bg-gray-50 tw-cursor-pointer">
                                <div className="tw-flex-shrink-0">
                                  <i className="bi bi-file-earmark-pdf tw-text-2xl tw-text-red-500"></i>
                                </div>
                                <div className="tw-flex-1 tw-min-w-0">
                                  <div className="tw-truncate tw-text-sm">
                                    Document
                                  </div>
                                  <div className="tw-text-xs tw-text-gray-500">
                                    PDF
                                  </div>
                                </div>
                                <span className="tw-px-3 tw-py-1 tw-text-sm tw-text-white tw-bg-[#03335b] tw-rounded hover:tw-bg-[#1279BE] tw-transition-colors">
                                  Preview
                                </span>
                              </div>
                                  {message.mimeType === "application/pdf" ? (
                                    <iframe
                                      src={message.imgUrl}
                                      title="PDF Document"
                                      className="tw-w-full tw-h-[200px]"
                                    />
                                  ) : (
                                    <a
                                      href={message.imgUrl}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="tw-block tw-p-2 tw-text-blue-600 tw-text-sm tw-text-center hover:tw-bg-sky-200"
                                    >
                                      View Document
                                    </a>
                                  )}
                                </div>
                              )}
      
                              {!/\.(pdf|csv|jpeg|mp4)$/i.test(
                                message.messageText
                              ) && (
                                <div className="tw-bg-[#f0f4ff] tw-rounded-b-lg tw-p-2.5 tw-shadow-sm">
                                  <p className="tw-pb-0 tw-mb-0 tw-font-semibold tw-capitalize"
                                  style={{
                                    color: `${message.senderColor}`
                                  }}
                                  >{message.sender}</p>
                                {message.headerContent && (
                                  <div className="tw-mb-2 tw-font-semibold tw-text-gray-500">{message.headerContent}</div>
                                )}
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: replaceVariables(message.messageText),
                                  }}
                                  className=" tw-break-words tw-whitespace-pre-wrap tw-text-sm"
                                ></div>
                                {message.footerContent && (
                                  <div className="tw-mt-2 tw-font-semibold tw-text-gray-500">{message.footerContent}</div>
                                )}
      
      {Array.isArray(message?.buttons) && message.buttons.length > 0 && (
        <div className="tw-flex tw-flex-col tw-gap-px tw-mt-3 tw-mx-[-12px] tw-mb-[-12px]">
          {message.buttons.map((button, index) => (
            <span
              key={index}
              className={`
                tw-text-[#1c4cba] dark:tw-text-[#4d82ff]
                tw-text-sm
                tw-font-medium
                tw-py-3
                tw-px-4
                tw-text-center
                tw-bg-white/50
                hover:tw-bg-gray-50/50
                active:tw-bg-gray-100/50
                tw-transition-colors
                tw-border-t
                tw-border-gray-200
                first:tw-border-t-0
                ${index === 0 ? 'tw-rounded-b-lg' : ''}
                tw-cursor-pointer
                tw-flex
                tw-items-center
                tw-justify-center
                tw-gap-2
                tw-w-full
              `}
            >
              {button.type === 'QUICK_REPLY' && (
                <span className="tw-truncate">{button.title}</span>
              )}
            </span>
          ))}
        </div>
      )}
      {console.log('message',message)}
      {message?.websiteUrl && (
        <div className="tw-flex tw-flex-col tw-gap-px tw-mt-3 tw-mx-[-12px] tw-mb-[-12px]">
          {Array.isArray(message.websiteUrl) ? (
            message.websiteUrl.map((link, index) => (
              
              <span
                key={index}
                className={`
                  tw-text-[#1c4cba] dark:tw-text-[#4d82ff]
                  tw-text-sm
                  tw-font-medium
                  tw-py-3
                  tw-px-4
                  tw-text-center
                  tw-bg-white/50
                  hover:tw-bg-gray-50/50
                  active:tw-bg-gray-100/50
                  tw-transition-colors
                  tw-border-t
                  tw-border-gray-200
                  first:tw-border-t-0
                  ${index === 0 ? 'tw-rounded-b-lg' : ''}
                  tw-cursor-pointer
                  tw-flex
                  tw-items-center
                  tw-justify-center
                  tw-gap-2
                  tw-w-full
                `}
              >
                {link.type === 'URL' && (
                  <>
                    <svg 
                      className="tw-w-4 tw-h-4" 
                      fill="none" 
                      stroke="currentColor" 
                      viewBox="0 0 24 24"
                    >
                      <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth="2" 
                        d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                      />
                    </svg>
                    {/* <span className="tw-truncate">{link.title}Check</span> */}
                    <a href={link.url} className="tw-truncate" target="_blank" rel="check noopener noreferrer">{link.title}</a>
                  </>
                )}
                {link.type === 'PHONE_NUMBER' && (
                  <>
                    <svg
                      className="tw-w-4 tw-h-4"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                      />
                    </svg>
                    <span className="tw-truncate">{link.title}</span>
                  </>
                )}
              </span>
            ))
          ) : typeof message.websiteUrl === 'string' ? (
            // Handle case where websiteUrl is a string
            <span className="tw-text-sm tw-break-words">{message.websiteUrl}</span>
          ) : null}
        </div>
      )}
                                </div>
                              )}
      
                              <div className="tw-flex tw-items-center tw-justify-end tw-gap-1 tw-mt-1 tw-mr-1">
                                <span className="tw-text-xs tw-text-gray-500">
                                  {" "}
                                  {message.logDateTime}
                                </span>
                                {message.messageStatus === "undelivered" ||
                                message.messageStatus === "failed"
                                  ? null
                                  : getMessageStatusIcon(message.messageStatus)}
                              </div>
                            </div>
                          </div>
      
                          {/* <div className="tw-w-8 tw-h-8 tw-bg-[#03335b] tw-text-white tw-rounded-full tw-flex tw-items-center tw-justify-center tw-mt-2 tw-flex-shrink-0">
                            {message.sender.split(" ")[0].charAt(0).toUpperCase()}
                          </div> */}
                        </>
                      )}
                    </div>
                  </React.Fragment>
                ))
              ) : (
                <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-text-gray-400">
  <i className="bi bi-chat-left-dots tw-text-5xl tw-mb-3"></i>
  <span className="tw-text-lg tw-font-medium">No messages yet</span>
  <span className="tw-text-sm tw-mt-1">Start a conversation</span>
</div>
              )}

              {showScrollButton && (
                <span
                  onClick={scrollToBottom}
                  className="tw-sticky tw-float-right tw-bottom-0 tw-right-0 tw-bg-[#1279BE] tw-text-white tw-rounded-full tw-w-6 tw-h-6 tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-shadow-lg hover:tw-bg-[#1279BE] tw-transition-all tw-duration-300 tw-z-50"
                  aria-label="Scroll to bottom"
                >
                  {newMessageCount ? (
                    newMessageCount
                  ) : (
                    <i className="bi bi-chevron-down"></i>
                  )}
                </span>
              )}
            </div>

            {/* Footer Input */}
            {accessLevel === 1 && (
              <div className="tw-flex-shrink-0 tw-bg-white tw-border-t tw-h-[104px]">
      

  <div className="tw-px-2 tw-pt-2 tw-flex tw-flex-col tw-items-center tw-justify-between">
    {/* {alert(showkeyboard)} */}
      {console.log(optOut,'optOut')}
    {optOut === 'Y' ? (
        <div className="tw-w-full tw-text-gray-600 tw-flex tw-flex-col tw-justify-center tw-text-left tw-bg-gray-100 tw-px-3 tw-py-1 tw-rounded-lg">
        <h5>Opted-out of WhatsApp Communication</h5> 
        <p className='tw-text-sm'>Unable to send WhatsApp message. This member has opted out of WhatsApp messaging.</p>
      </div>
    ) : (
      !showkeyboard ? (
      <div className="tw-relative tw-w-full">
      <div className="tw-w-full tw-text-gray-600 tw-flex tw-flex-col tw-justify-center tw-text-left tw-bg-gray-100 tw-px-3 tw-py-1 tw-rounded-lg">

      {templateStatus ? (
                  <p className="tw-text-sm tw-h-16 tw-overflow-y-auto tw-mb-0">
                    The member has not spoken to you in the last 24 hours.You can only send pre-approved templates.

                  </p>
                ) : (
                  <p className="tw-text-sm tw-h-16 tw-overflow-y-auto tw-mb-0">
                    Unable to send WhatsApp messages. This member has already
                    received the maximum allowable messages in the last 24 hours
                    for this number. Please wait 24 hours before attempting to
                    send another message, or use an alternative mobile number to
                    send a WhatsApp message.
                  </p>
                )}
      </div>

      
    </div>
    ) : (
      <div className="tw-relative tw-w-full">
      <textarea
        className={`w-border ${formError ? "tw-border-orange-700" : "tw-border-gray-300"} tw-rounded-2xl tw-text-sm tw-w-full tw-px-2 tw-py-2`}
        placeholder="Reply here"
        value={textMessage}
        onChange={(e) => setTextMessage(e.target.value)}
        rows={2}
      />

      
    </div>
    )
    )}
  
    <div className="tw-flex tw-w-full tw-gap-2 tw-justify-between tw-items-center">
      <div className="tw-w-9 tw-flex tw-justify-between tw-py-1">
        {/* Emoji Picker */}
        <div className="tw-relative tw-flex tw-items-center tw-justify-center">
          {/* <OverlayTrigger placement="left" overlay={<Tooltip>Emoji</Tooltip>}> */}
            <fieldset
            onMouseEnter={() => setActiveTooltip('emoji')}
            onMouseLeave={() => setActiveTooltip(null)}
            //   disabled={isEmojiDisabled}
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
              className={`tw-cursor-pointer ${isEmojiDisabled ? 'tw-pointer-events-none tw-opacity-50' : ''} tw-w-6 tw-h-6 tw-flex tw-items-center tw-justify-center hover:tw-text-gray-600 tw-transition-colors tw-select-none`}
            >
              😊
            </fieldset>
            <CustomTooltip 
              text="Emoji"
              visible={activeTooltip === 'emoji'}
            />
          {/* </OverlayTrigger> */}
          {showEmojiPicker && (
            <div 
              ref={emojiPickerRef} 
              className="tw-absolute tw-right-10 tw-bottom-0 tw-z-50"
            >
              <Picker onEmojiClick={onEmojiClick} />
            </div>
          )}
        </div>

                    {/* Attachment */}
                    {/* <div className="tw-flex tw-items-center tw-justify-center">
                      <fieldset
                        onMouseEnter={() => setActiveTooltip("attachment")}
                        onMouseLeave={() => setActiveTooltip(null)}
                        onClick={() => fileInputRef.current?.click()}
                        className={`tw-cursor-pointer ${isAttachmentDisabled ? 'tw-pointer-events-none tw-opacity-50' : ''} tw-w-6 tw-h-6 tw-flex tw-items-center tw-justify-center hover:tw-text-gray-600 tw-transition-colors tw-relative`}
                      >
                        <i className="bi bi-paperclip tw-rotate-45 tw-pt-1" />
                        <CustomTooltip
                          text="Attachment"
                          visible={activeTooltip === "attachment"}
                        />
                        <input
                    ref={fileInputRef}
                    type="file"
                    className="tw-hidden"
                    accept="image/jpeg,image/jpg,image/png,image/webp,
                            video/mp4,video/3gpp,
                            audio/aac,audio/mp4,audio/mpeg,audio/amr,audio/ogg,audio/opus,
                            application/pdf,
                            application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                            application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                            application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation"
                    onChange={handleImageChange}
                  />
      
                      </fieldset>
                    </div> */}

                    {/* Template */}
                    <div className="tw-flex tw-items-center tw-justify-center">
                      {/* <OverlayTrigger placement="left" overlay={<Tooltip>Select Template</Tooltip>}> */}
                      <fieldset
                        onMouseEnter={() => setActiveTooltip("template")}
                        onMouseLeave={() => setActiveTooltip(null)}
                        // disabled={!showkeyboard && !templateStatus}
                        onClick={() => setShowTemplatePopup(true)}
                        className={`tw-cursor-pointer ${isTemplateDisabled ? 'tw-pointer-events-none tw-opacity-50' : ''} tw-w-6 tw-h-6 tw-flex tw-items-center tw-justify-center hover:tw-text-gray-600 tw-transition-colors tw-relative`}
                      >
                        <i className="bi bi-stack" />
                        <CustomTooltip
                          text="Select Template"
                          visible={activeTooltip === "template"}
                        />
                      </fieldset>
                      {/* </OverlayTrigger> */}
                    </div>
                  </div>
                  <span
                    className={`tw-text-xs ${sendText || !showkeyboard || optOut === "Y" ? "tw-pointer-events-none tw-opacity-50" : ""} border tw-py-1 tw-px-2 tw-rounded-lg tw-bg-[#03335b] hover:tw-bg-[#1279BE] tw-text-white tw-font-semibold tw-cursor-pointer`}
                    onClick={handleSendText}
                    disabled={sendText || !showkeyboard || optOut === "Y"}
                  >
                    <i className="bi bi-send tw-pr-2"></i>
                    {sendText ? "Sending..." : "Send"}
                  </span>
                  {/* Controls container */}
                </div>

                {/* {showNewMessageAlert   &&  (
        <div
          className="tw-bg-blue-500 tw-text-white tw-p-2 tw-rounded tw-cursor-pointer"
          onClick={scrollToBottom}
        >
          {newMessageCount} new {newMessageCount > 1 ? 'messages' : 'message'}
          </div>
      )} */}
              </div>
            </div>
          )}
          </div>
          {/* </Row> */}
          {/* </Col> */}

          {/* <div className="tw-flex tw-flex-col tw-w-[150px] tw-gap-2 tw-justify-end">
            <button
              className="btn btn-primary clear-search-btn"
              onClick={handleSendText}
              disabled={sendText || !showkeyboard || optOut === "Y"}
            >
              {sendText ? "Sending..." : "Send"}
            </button>
          </div> */}
        </Row>
      </div>

      <MediaModal
      isOpen={isModalOpen}
      onClose={handleCloseModal}
      url={selectedMedia.url}
      mediaType={selectedMedia.type}
      onDownload={handleDownload}
      />

      {showTemplatePopup && (
        <TemplatePopup
          onClose={() => setShowTemplatePopup(false)}
          id={id}
          getchat={getchat}
          setLoadingMore={setLoadingMore}
          setChatpage={setChatpage}
          setChatResult={setChatResult}
          scrollToBottom={scrollToBottom}
        />
      )}

      {isPopupOpen ? (
        <div className="tw-fixed tw-inset-0 tw-bg-black/50 tw-flex tw-items-center tw-justify-center tw-z-[9999] tw-p-4">
          <div className="tw-bg-white tw-rounded-lg tw-overflow-hidden tw-w-full tw-max-w-5xl tw-flex tw-flex-col tw-max-h-[80vh]">
            {/* Header */}
            <div className="tw-flex-none tw-bg-white tw-px-6 tw-py-4 tw-border-b">
              <div className="tw-flex tw-justify-between tw-items-center">
                <h2 className="tw-text-xl tw-font-semibold tw-text-gray-700">
                  Preview
                </h2>
                <span
                  onClick={() => fileclear()}
                  className="tw-text-gray-500 tw-font-bold hover:tw-text-gray-700 tw-bg-white tw-text-xl tw-rounded-full tw-w-8 tw-h-8 tw-flex tw-items-center tw-justify-center tw-transition-colors tw-cursor-pointer"
                >
                  ✕
                </span>
              </div>
            </div>

            {/* Content area */}
            <div className="tw-flex-1 tw-overflow-y-auto tw-min-h-0">
              <div className="tw-p-6">
                <ImagePreviewPopup
                  imageSrc={imagePreview}
                  note={imageNote}
                  onNoteChange={(e) => setImageNote(e.target.value)}
                  onClose={() => setIsPopupOpen(false)}
                  onSend={handleSendImageWithText}
                  fileType={fileType}
                  sendText={sendText}
                />
              </div>
            </div>

            {/* Footer */}
            <div className="tw-flex-none tw-bg-gray-50 tw-px-6 tw-py-4 tw-border-t">
              <div className="tw-flex tw-justify-center">
                <span
                  disabled={sendText}
                  onClick={handleSendImageWithText}
                  className="tw-bg-[#03335b] hover:tw-bg-[#1279BE] tw-transition-all tw-duration-300 tw-text-white tw-px-8 tw-py-2 tw-rounded disabled:tw-opacity-50 disabled:tw-cursor-not-allowed tw-cursor-pointer"
                >
                  {sendText ? (
                    <span className="tw-flex tw-items-center tw-gap-2">
                      <span className="tw-w-4 tw-h-4 tw-border-2 tw-border-white tw-border-t-transparent tw-rounded-full tw-animate-spin" />
                      Sending...
                    </span>
                  ) : (
                    "Confirm & Send"
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {/* Error Modal */}
      <ErrorModal
        isOpen={errorModal.isOpen}
        onClose={() => setErrorModal({ ...errorModal, isOpen: false })}
        title={errorModal.title}
        message={errorModal.message}
      />
    </>
  );
});

const ImagePreviewPopup = ({ imageSrc, fileType, note, onNoteChange }) => {
  return (
    <div className="tw-flex tw-flex-col tw-space-y-6">
      {/* File preview based on file type */}
      <div className="tw-flex tw-justify-center tw-bg-gray-50 tw-rounded-lg tw-p-4">
        {fileType === "image" ? (
          <img
            src={imageSrc}
            alt="Selected file"
            className="tw-max-h-[40vh] tw-object-contain tw-w-auto tw-rounded"
          />
        ) : fileType === "pdf" ? (
          <embed
            src={imageSrc}
            type="application/pdf"
            className="tw-w-full tw-h-[40vh] tw-rounded"
          />
        ) : fileType === "audio" ? (
          <div className="tw-w-full tw-max-w-md tw-p-4 tw-bg-white tw-rounded-lg tw-shadow">
            <audio controls className="tw-w-full">
            <source src={imageSrc} type="audio/aac" />
            <source src={imageSrc} type="audio/mp4" />
            <source src={imageSrc} type="audio/mpeg" />
            <source src={imageSrc} type="audio/amr" />
            <source src={imageSrc} type="audio/ogg" />
            <source src={imageSrc} type="audio/opus" />
              Your browser does not support the audio element.
            </audio>
          </div>
        ) : fileType === "video" ? (
          <video controls className="tw-max-h-[40vh] tw-w-auto tw-rounded">
            <source src={imageSrc} type="video/mp4" />
            Your browser does not support the video element.
          </video>
        ) : fileType === "excel" ? (
          <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-shadow tw-text-center">
            <i className="bi bi-file-earmark-spreadsheet tw-text-4xl tw-text-green-600 tw-mb-2"></i>
            <p className="tw-text-gray-700">
              Excel file selected. No preview available.
            </p>
          </div>
        ) : null}
      </div>

      {/* Text area for adding a note */}
      <div className="tw-bg-white tw-rounded-lg">
        <textarea
          value={note}
          onChange={onNoteChange}
          placeholder="Add a message to accompany the file..."
          className="tw-w-full tw-p-4 tw-border tw-rounded-lg tw-h-24 tw-resize-none focus:tw-ring-2 focus:tw-ring-[#03335b] focus:tw-border-transparent"
        />
      </div>
    </div>
  );
};

const ErrorModal = ({ isOpen, onClose, title, message }) => {
    if (!isOpen) return null;
  
    return (
      <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-items-center tw-justify-center tw-z-[9999]">
        <div className="tw-bg-white tw-rounded-lg tw-overflow-hidden tw-w-[400px] tw-max-w-[90%]">
          {/* Header */}
          <div className="tw-flex tw-justify-between tw-items-center tw-p-4 tw-border-b tw-w-full">
            <h2 className="tw-text-xl tw-text-center tw-font-semibold tw-text-gray-800 tw-w-[100%]">
              {title || 'Error'}
            </h2>
            {/* <span
              onClick={onClose}
              className="tw-text-gray-400 hover:tw-text-gray-600 tw-transition-colors tw-cursor-pointer"
            >
              <svg className="tw-w-6 tw-h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </span> */}
          </div>
  
          {/* Content */}
          <div className="tw-p-4">
            <p className="tw-text-gray-600 tw-whitespace-pre-wrap tw-text-center">{message}</p>
          </div>
  
          {/* Footer */}
          <div className="tw-flex tw-justify-center tw-p-4 tw-border-t">
            <span
              onClick={onClose}
              className="tw-bg-[#03335b] hover:tw-bg-[#1279BE] tw-text-white tw-cursor-pointer tw-px-4 tw-py-2 tw-rounded tw-transition-colors"
            >
              OK
            </span>
          </div>
        </div>
      </div>
    );
  };

export default Chat;
