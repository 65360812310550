import { Link, useNavigate } from "react-router-dom"
import React, { useEffect, useState } from 'react';
import Navbar from "./pages/componenents/Navbar";
import Sidenavmenubar from "./pages/componenents/Sidenavmenubar";
import { decruptCipherJson, decruptCipherString } from "./pages/utils/CommonLibrary";
import Footer from "./pages/componenents/Footer";


function Protected({ Component, title, childrenProps, pathNew }) {
  const navigate = useNavigate();

  useEffect(() => {

    const pagesList = localStorage.getItem('pagesList') ? localStorage.getItem('pagesList') : '';
    const pagesListData = decruptCipherJson(pagesList);
    const isDeptExists = pagesListData.some(pagesList => pagesList.path === pathNew);
    if (!isDeptExists) {
      window.location.href = '/no-access';
    }

    let uid = localStorage.getItem('uid') ? localStorage.getItem('uid') : '';
    if (uid) {
      const isLoggedIn = decruptCipherString(uid);
      if (!isLoggedIn) {
        navigate('/login');
      }
    } else {
      navigate('/login');
    }

  }, [navigate]);

  if (!localStorage.getItem('uid')) {
    return null;
  }

  return (
    <>
      {title != "cnDashboard" && <Sidenavmenubar className="sidenavmenubarStyle" />}
      <section className={title == "cnDashboard" ? 'home-section-new' : 'home-section'}>
        <Navbar title={title} />
        <Component {...childrenProps} />
        <Footer />
      </section>
    </>
  );
}

export default Protected;