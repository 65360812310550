import React from 'react'; 
import { Link } from 'react-router-dom';
import { capitalizeFirstLetter, replaceUnderScoreWithSpace } from '../../utils/Utils';
import { useDispatch, useSelector } from 'react-redux';
import { setObseravationsCurrentPage, setObservationsPerPage, setObservationsTotalEntries, setObservationsTotalPages } from '../../../store/paginationSlice';
import { Loader2 } from 'lucide-react';




const Table = ({
    data, 
    setError, 
    sortConfig,
    onSort,
    view,
    dashboardData, 
    isLoading
}) => {
    const dispatch = useDispatch();
    const pagination = useSelector(state => state.pagination.observations);
    
    // Remove local sort state since it's now managed by parent
    const SortIndicator = ({ field }) => {  
        if (sortConfig.field !== field) {
            return <i className="fa fa-sort text-muted"></i>;
        }
        return sortConfig.direction === 'asc' ? 
            <i className="fa fa-sort-asc"></i> : 
            <i className="fa fa-sort-desc"></i>;
    };

    function handlePageChange(page){ 
        dispatch(setObseravationsCurrentPage(page));
    }

    return (
        <>
            <div className='table-responsive tw-relative'>
               
                {
                 isLoading && (
                    <div className="tw-absolute tw-inset-0 tw-bg-white/60 tw-flex tw-items-center tw-justify-center tw-z-10">
                        <div className="tw-flex tw-flex-col tw-items-center tw-gap-2">
                            <Loader2 className="tw-h-8 tw-w-8 tw-animate-spin tw-text-primary" />
                            <p className="tw-text-sm tw-text-gray-700">Loading observations...</p>
                        </div>
                    </div>
                )}

                <table className='table table-striped mb-0'>
                    <thead>
                        <tr>
                            <th 
                                className='td-styled-ob' 
                                style={{ cursor: 'pointer' }}   
                                onClick={() => onSort('observation_id')}
                            >
                                Observation ID &nbsp;
                                <SortIndicator field="observation_id" />
                            </th>
                            <th className='td-styled-ob'>Title</th>
                            <th 
                                className='td-styled-ob'
                                style={{ cursor: 'pointer' }}
                                onClick={() => onSort('status')}
                            >
                                Status &nbsp;
                                <SortIndicator field="status" />
                            </th>
                            <th 
                                className='td-styled-ob'
                                style={{ cursor: 'pointer' }}
                                onClick={() => onSort('priority')}
                            >
                                Priority &nbsp;
                                <SortIndicator field="priority" />
                            </th>
                            <th className='td-styled-ob'
                                style={{ cursor: 'pointer' }}
                                onClick={() => onSort('observation_type')}
                            > 
                                Observation Type &nbsp;
                                <SortIndicator field="observation_type" />
                            </th>
                            
                            <th className='td-styled-ob' 
                                style={{ cursor: 'pointer' }}
                                onClick={() => onSort('observation_date')}
                            >
                                Observation Date &nbsp;
                                <SortIndicator field="observation_date" />
                            </th>

                            <th className='td-styled-ob'
                                style={{ cursor: 'pointer' }}
                                onClick={() => onSort('last_action_date')}
                            >
                                Last Action Date&nbsp;
                                <SortIndicator field="last_action_date" />
                            </th>
                            <th className='td-styled-ob' 
                                style={{ cursor: 'pointer' }}
                                onClick={() => onSort('current_responsibility')}
                            >
                                Current Responsibility &nbsp; 
                                <SortIndicator field="current_responsibility" />
                            </th>
                            <th className='td-styled-ob'
                                style={{ cursor: 'pointer' }}
                                onClick={() => onSort('observed_by')}
                            >Observed By &nbsp;
                                <SortIndicator field="observed_by" />
                            </th>
                            {dashboardData.value == 11 && <th className='td-styled-ob'
                                style={{ cursor: 'pointer' }}
                                onClick={() => onSort('application_name')}
                            >
                                Application Name &nbsp; 
                                <SortIndicator field="application_name" />                            
                            </th>}
                            <th className='td-styled-ob'>Options</th>
                        </tr>
                    </thead>        
                    <tbody  className=''>
                        {data?.length === 0 ? (
                            <tr>
                                <td colSpan={dashboardData.value == 11 ? 10 : 9} className="text-center">
                                    No observation found
                                </td>
                            </tr>
                        ) : (
                            data?.map((item, index) => (
                                <tr key={index}>
                                    <td className='td-styled-ob-text'>{item.id || 'N/A'}</td>
                                    <td className='td-styled-ob-text'>{item.title.length > 85 ? `${item.title.substring(0, 85)}...` : item.title}</td>
                                    <td className='td-styled-ob-text'>{item.status}</td>
                                    <td className='td-styled-ob-text'>{replaceUnderScoreWithSpace(item.priority)}</td>
                                    <td className='td-styled-ob-text'>{item.observation_type.split('_').map(word => capitalizeFirstLetter(word)).join(' ')}</td>
                                    <td className='td-styled-ob-text' style={{whiteSpace:'break-spaces', width:'100px'}}>{item.createdDate}</td>
                                    <td className='td-styled-ob-text' style={{whiteSpace:'break-spaces', width:'100px'}}>{item.lastActionDate}</td>
                                    <td className='td-styled-ob-text'>{capitalizeFirstLetter(item.current_responsibility) === "User" ? "Business" : capitalizeFirstLetter(item.current_responsibility) === "Goqii" ? "GOQii" : capitalizeFirstLetter(item.current_responsibility)}</td>
                                    <td className='td-styled-ob-text'>{capitalizeFirstLetter(item.firstname) + ' ' + capitalizeFirstLetter(item.lastname)}</td>
                                    {dashboardData.value == 11 && <td className='td-styled-ob-text'>{item.dashboardName}</td>}
                                    <td className='td-styled-ob-text'>
                                        <Link style={{textDecoration: 'none'}} to={`${view}/${item.observation_id}`} className='mb-0 me-2 tw-text-black'>
                                            <i className="fa fa-eye"></i>
                                        </Link>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
                
               
                { 
                    pagination.totalPages > 0 && (
                        <div className="pagination-container">
                            <div className="rows-per-page">
                                <label>Rows per page: </label>
                                <select value={pagination.perPage} onChange={(e) =>{ 
                                    dispatch(setObservationsPerPage(Number(e.target.value))); 
                                    dispatch(setObseravationsCurrentPage(1));
                                }}>
                                    <option value={10}>10</option>
                                    <option value={15}>15</option>
                                    <option value={20}>20</option>
                                    <option value={25}>25</option>
                                </select>
                            </div>

                            <div className="records-info">
                                {`${(pagination.currentPage - 1) * pagination.perPage + 1}-${Math.min(pagination.currentPage * pagination.perPage, pagination.totalEntries)} of ${pagination.totalEntries} records`}
                            </div>

                            <div className="pagination-controls">
                                <button 
                                    disabled={pagination.currentPage === 1}
                                    onClick={() => handlePageChange(1)}
                                >
                                    <i className='bx bx-first-page'></i>
                                </button>
                                <button  
                                    disabled={pagination.currentPage === 1}
                                    onClick={() => handlePageChange(pagination.currentPage - 1)}
                                >
                                    <i className='bx bx-chevron-left'></i>
                                </button>
                                <button  
                                    disabled={pagination.currentPage === pagination.totalPages}
                                    onClick={() => handlePageChange(pagination.currentPage + 1)}
                                >
                                    <i className='bx bx-chevron-right'></i>
                                </button>
                                <button  
                                    disabled={pagination.currentPage === pagination.totalPages}
                                    onClick={() => handlePageChange(pagination.totalPages)}
                                >
                                    <i className='bx bx-last-page'></i>
                                </button>
                            </div>
                        </div>
                    )
                }
            </div>
         </>
    )
}

export default Table
