import common_axios from "./commonAxios";

let baseURL = common_axios.defaults.baseURL;

//fetch user list
export const fetch_user_data_list = () => {
    var dataPass = common_axios.get(baseURL + 'memberconnect/fetch_user_data_list');
    return dataPass;
};

//fetch program master data MTP
export const fetch_program_data = () => {
    var dataPass = common_axios.get(baseURL + 'met/fetch_program_data');
    return dataPass;
};

//fetch service master data MTP

export const fetch_service_data = () => {
    var dataPass = common_axios.get(baseURL + 'met/fetch_service_data');
    return dataPass;
};


export const fetch_admin_user_data = () => {
    var dataPass = common_axios.get(baseURL + 'met/fetch_admin_user_data');
    return dataPass;
};


//To get admin user information
export const mtp_fetch_admin_user_data = (userId) => {
    let params = { 'userId': userId }
    var dataPass = common_axios.post(baseURL + 'met/met_fetch_admin_user_data', params);
    return dataPass;
};

//To check username already exist.
export const mtp_check_username_available = (userName) => {
    var dataPass = common_axios.get(baseURL + 'met/mtp_check_username_available?userName=' + userName);
    return dataPass;
};

//To get pathway data with frequency services
export const fetch_pathway_frequency_data = (pathwayId) => {
    var dataPass = common_axios.get(baseURL + 'met/fetch_pathway_frequency_data?pathwayId=' + pathwayId);
    return dataPass;
};

//To get member communication data
export const fetch_member_communication_data = (data) => {
    var dataPass = common_axios.post( baseURL + 'met/fetch_member_communication_data',data);
    return dataPass;
};

export const fetch_member_communication_stats = (data) => {
    var dataPass = common_axios.post( baseURL + 'met/fetch_member_communication_stats',data);
    return dataPass;
}

//To get whatsapp template data
export const fetch_whatsapp_template_data = () => {
    var dataPass = common_axios.get(baseURL + 'met/fetch_whatsapp_template_data');
    return dataPass;
};

//To get selected whatapp template data
export const single_whatsapp_template = (templateId) => {
    var dataPass = common_axios.get(baseURL + 'met/single_whatsapp_template?templateId=' + templateId);
    return dataPass;
};

//get condition from program data
export const fetch_program_condition_data = (programId) => {
    var dataPass = common_axios.get(baseURL + 'met/fetch_program_condition_data?programId=' + programId);
    return dataPass;
};

// get pathway data from condition
export const fetch_program_condition_pathway_data = (conditionId) => {
    var dataPass = common_axios.get(baseURL + 'met/fetch_program_condition_pathway_data?conditionId=' + conditionId);
    return dataPass;
};


//post data user creation MTP
export const mtp_user_creation_data = (formData) => {
    var dataPass = common_axios.post(baseURL + 'met/met_user_creation_data', formData);
    return dataPass;
};

//post data for program (codition,pathway) frequency creation MTP
export const program_creation_frequency_data = (formData) => {
    var dataPass = common_axios.post(baseURL + 'met/program_creation_frequency_data', formData);
    return dataPass;
};

//create frequency configuration(services data assign) template MTP
export const frequency_configuration_template_creation = (formData) => {
    var dataPass = common_axios.post(baseURL + 'met/frequency_configuration_template_creation', formData);
    return dataPass;
};



//Update data admin user met
export const mtp_admin_user_update_data = (userId, formData) => {
    var dataPass = common_axios.post(baseURL + 'met/met_admin_user_update_data?userId=' + userId, formData);
    return dataPass;
};


export const fetch_ad_users_list = (searchString) => {
    var requestBody = { searchString };
    var dataPass = common_axios.post(baseURL + 'met/fetch_ad_users', requestBody);
    return dataPass;
};
export const add_new_services = (formData) => {
    var dataPass = common_axios.post(baseURL + 'met/add_new_services', formData);
    return dataPass;
};

export const frequency_service_update = (formData) => {
    var dataPass = common_axios.post(baseURL + 'met/frequency_service_update', formData);
    return dataPass;
};

export const fetch_single_frequency_configuration_template = (formData) => {
    var dataPass = common_axios.post(baseURL + 'met/fetch_single_frequency_configuration_template', formData);
    return dataPass;
};

export const agent_call_initiate = (userId) => {
    var userId = { userId };
    var dataPass = common_axios.post(baseURL + 'met/agent_call_initiate', userId);
    return dataPass;
};

export const agent_call_hold = (data) => {
    var dataPass = common_axios.post(baseURL + 'met/agent_call_hold', data);
    return dataPass;
};

export const agent_call_unhold = (data) => {
    var dataPass = common_axios.post(baseURL + 'met/agent_call_unhold', data);
    return dataPass;
};

export const agent_call_mute = (data) => {
    var dataPass = common_axios.post(baseURL + 'met/agent_call_mute', data);
    return dataPass;
};

export const agent_call_disconnect = (confNumber) => {
    var dataPass = common_axios.post(baseURL + 'met/agent_call_disconnect', { confNumber });
    return dataPass;
};

export const direct_agent_call_disconnect = (data) => {
    var dataPass = common_axios.post(baseURL + 'memberconnect/direct_agent_call_disconnect', data);
    return dataPass;
};

// carenavigatorId and confNumber required
export const agent_transfer_call = (formData) => {
    var dataPass = common_axios.post(baseURL + 'met/agent_transfer_call', formData);
    return dataPass;
};


export const fetch_uploaded_user_data_list = (startDate, endDate) => {
    let params = { 'startDate': startDate, 'endDate': endDate }
    var dataPass = common_axios.post(baseURL + 'met/fetch_uploaded_user_data_list', params);
    return dataPass;
};

export const fetch_single_user_data_list = (userId) => {
    var userId = { userId };
    var dataPass = common_axios.post(baseURL + 'memberconnect/fetch_single_user_data_list', userId);
    return dataPass;
};

export const fetch_family_user_data_list = (userId) => {
    var userId = { userId };
    var dataPass = common_axios.post(baseURL + 'memberconnect/fetch_single_user_data_list', userId);
    return dataPass;
};

export const fetch_soft_phone_token = (confNumber) => {
    var confNumber = { confNumber };
    var dataPass = common_axios.post(baseURL + 'met/fetchsoftphonetoken', confNumber);
    return dataPass;
};

export const fetch_user_callback_data_list = () => {
    var dataPass = common_axios.get(baseURL + 'memberconnect/fetch_user_callback_data_list');
    return dataPass;
};

export const fetch_uploaded_summery_data_list = (startDate, endDate) => {
    let params = { 'startDate': startDate, 'endDate': endDate }
    var dataPass = common_axios.post(baseURL + 'met/fetch_uploaded_summery_data_list', params);
    return dataPass;
};

export const fetch_soft_number = (data) => {
    console.log("fetch_soft_number");
    var dataPass = common_axios.post(baseURL + 'met/fetch_soft_number', data);
    return dataPass;
};

export const direct_fetch_soft_number = (data) => {
    var dataPass = common_axios.post(baseURL + 'memberconnect/direct_fetch_soft_number', data);
    return dataPass;
};

export const make_soft_phone_call = (confNumber) => {
    var confNumber = { confNumber };
    var dataPass = common_axios.post(baseURL + 'met/make_soft_phone_call', confNumber);
    return dataPass;
};


//fetch role master data  mcm
export const fetch_role_data = () => {
    var dataPass = common_axios.get(baseURL + 'met/fetch_role_data');
    return dataPass;
};

//fetch agent master data for mcm
export const fetch_agent_user_list = () => {
    var dataPass = common_axios.get(baseURL + 'met/fetch_agent_user_list');
    return dataPass;
};

//Enroll process
export const agent_enroll_user = (userId) => {
    //let params = {memberId:memberId}
    var dataPass = common_axios.get(baseURL + 'met/agent_enroll_user?userId=' + userId);
    return dataPass;
};


//assign care navigator process
export const agent_book_care_navigator = (userId, date, time, careNavigatorId) => {
    //let params = {memberId:memberId}
    var dataPass = common_axios.get(baseURL + 'met/agent_book_care_navigator?userId=' + userId + '&date=' + date + '&time=' + time + '&careNavigatorId=' + careNavigatorId);
    return dataPass;
};

//assign call back

export const agent_call_back_plan = (data) => {
    //let params = {memberId:memberId}
    var dataPass = common_axios.post(baseURL + 'memberconnect/agent_call_back_plan', data);
    return dataPass;
};


export const agent_create_pool = () => {
    //let params = {memberId:memberId}
    var dataPass = common_axios.get(baseURL + 'memberconnect/create_pool');
    return dataPass;
};

export const agent_stats = () => {
    //let params = {memberId:memberId}
    var dataPass = common_axios.get(baseURL + 'met/agent_stats');
    return dataPass;
};

export const fetch_uploaded_user_batch_list = (searchStr, startDate, endDate, pageNumber, perPage, sortColumn, sortDirection, download) => {
    const params = { 'searchStr': searchStr, 'startDate': startDate, 'endDate': endDate, 'pageNumber': pageNumber, 'perPage': perPage, 'sortColumn': sortColumn, 'sortDirection': sortDirection, 'download': download }
    var dataPass = common_axios.post(baseURL + 'met/fetch_uploaded_user_batch_list', params);
    return dataPass;
};

export const fetch_uploaded_user_batch_list_updated = (searchStr, startDate, endDate, pageNumber, perPage, sortColumn, sortDirection, download) => {

    const params = { 'searchStr': searchStr, 'startDate': startDate, 'endDate': endDate, 'pageNumber': pageNumber, 'perPage': perPage, 'sortColumn': sortColumn, 'sortDirection': sortDirection, 'download': download }

    var dataPass = common_axios.post(baseURL + 'met/fetch_uploaded_user_batch_list_updated', params);
    return dataPass;
};

export const fetch_member_insight_data_list = () => {
    var dataPass = common_axios.get(baseURL + 'met/fetch_member_insight_data_list');
    return dataPass;
};

export const agent_insight_stats = (startDate, endDate, agentId) => {
    let params = { 'startDate': startDate, 'endDate': endDate, 'agentId': agentId }
    var dataPass = common_axios.post(baseURL + 'met/agent_insight_stats', params);
    return dataPass;
};

export const fetch_member_agent_list = () => {
    var dataPass = common_axios.get(baseURL + 'met/fetch_member_agent_list');
    return dataPass;
};

export const agent_configuration_setting = (formData) => {
    var dataPass = common_axios.post(baseURL + 'met/agent_configuration_setting', formData);
    return dataPass;
};

export const fetchAgentWhatsappData = (startDate, endDate, agentId, requestFrom) => {
    const params = { 'startDate': startDate, 'endDate': endDate, 'agentId': agentId, 'requestFrom': requestFrom }
    var dataPass = common_axios.post(baseURL + 'met/fetch_agent_whatsapp_data', params)
    return dataPass;
};




export const agent_call_user_status = (confNumber) => {
    var dataPass = common_axios.post(baseURL + 'met/agent_call_user_status', { confNumber });
    return dataPass;
}


export const reject_enrollment = (data) => {

    var dataPass = common_axios.post(baseURL + 'met/reject_enrollment', data);
    return dataPass;
}

export const family_reject_enrollment = (data) => {
    var dataPass = common_axios.post(baseURL + 'memberconnect/reject_enrollment', data);
    return dataPass;
}


export const fetch_carenavigator_list = () => {

    var dataPass = common_axios.get(baseURL + 'met/fetch_care_list');
    return dataPass;
}

export const fetch_carenavigator_list_transfer = () => {
    var dataPass = common_axios.get(baseURL + 'met/fetch_care_list_transfer');
    return dataPass;
}

export const family_fetch_carenavigator_list = (data) => {
    var dataPass = common_axios.post(baseURL + 'memberconnect/fetch_care_list', data);
    return dataPass;
}

export const fetch_graph_enroll_program_per_agent = (startDate, endDate, agentId) => {
    let params = { 'startDate': startDate, 'endDate': endDate, 'agentId': agentId }
    var dataPass = common_axios.get(baseURL + 'met/fetch_graph_enroll_program_per_agent');
    return dataPass;
}


export const fetch_available_slot = (data) => {
    var dataPass = common_axios.post(baseURL + 'met/fetch_available_slot', data);
    return dataPass;
}


export const save_carenavigator_slot = (data) => {
    var dataPass = common_axios.post(baseURL + 'met/save_carenavigator_slot ', data);
    return dataPass;
}


export const call_disposition = (data) => {
    var dataPass = common_axios.post(baseURL + 'met/call_disposition', data);
    return dataPass;
}

export const direct_call_disposition = (data) => {
    var dataPass = common_axios.post(baseURL + 'memberconnect/direct_call_disposition', data);
    return dataPass;
}

export const family_direct_call_disposition = (data) => {
    var dataPass = common_axios.post(baseURL + 'memberconnect/direct_call_quality_data', data);
    return dataPass;
}

export const call_disposition_list = () => {
    var dataPass = common_axios.get(baseURL + 'met/call_disposition_list');
    return dataPass;
}

export const fetch_incorrect_data = () => {
    var dataPass = common_axios.get(baseURL + 'met/fetch_incorrect_data');
    return dataPass;
}


export const data_quality_list = () => {
    var dataPass = common_axios.get(baseURL + 'met/data_quality_list');
    return dataPass;
}


export const save_alt_number = (data) => {
    var dataPass = common_axios.post(baseURL + 'met/save_alt_number', data);
    return dataPass;
}

export const fetch_progtramcondition_data = () => {
    var dataPass = common_axios.get(baseURL + 'met/fetch_programcondition_data');
    return dataPass;
}

export const fetch_agent_setting_script_data = () => {
    var dataPass = common_axios.get(baseURL + 'met/fetch_agent_setting_script_data');
    return dataPass;
}

export const fetch_template_count_data = () => {
    var dataPass = common_axios.get(baseURL + 'met/fetch_template_count_data');
    return dataPass;
}

export const fetch_last_save_setting_list = (data) => {
    var dataPass = common_axios.get(baseURL + 'met/fetch_last_save_setting_list', data);
    return dataPass;
}


export const cn_call_exit = (data) => {
    let response = common_axios.post(baseURL + 'met/cn_call_exit', data);
    return response;
}

export const accept_enrollment = (data) => {
    let response = common_axios.post(baseURL + 'met/accept_enrollment', data);
    return response;
}

export const direct_accept_enrollment = (data) => {
    let response = common_axios.post(baseURL + 'memberconnect/direct_accept_enrollment', data);
    return response;
}

export const cn_call_user_status = (data) => {
    let response = common_axios.post(baseURL + 'met/cn_call_user_status', data);
    return response;
}
export const fetch_audio_play_list = (callId) => {
    let params = { 'callId': callId }
    let response = common_axios.post('met/fetch_audio_play_list', params);
    return response;
}


export const soft_number_logger = (data) => {
    return common_axios.post(baseURL + 'met/soft_number_logger', data);
}

export const fetch_user_attempt_call_list = (startDate, endDate, userId) => {
    let params = { 'startDate': startDate, 'endDate': endDate, 'userId': userId }
    return common_axios.post(baseURL + 'met/fetch_user_attempt_call_list', params);
}


export const fetch_setting_disposition_agent_list = () => {
    return common_axios.get(baseURL + 'met/fetch_setting_disposition_agent_list');
}

export const add_new_call_disposition = (formData) => {
    var dataPass = common_axios.post(baseURL + 'met/add_new_call_disposition', formData);
    return dataPass;
};

export const edit_new_call_disposition = (formData) => {
    console.log("form", formData);
    var dataPass = common_axios.post(baseURL + 'met/edit_new_call_disposition', formData);
    return dataPass;
};

export const edit_get_new_call_disposition = (id) => {
    let params = { 'dispositionId': id };
    var dataPass = common_axios.post(baseURL + 'met/edit_get_new_call_disposition', params);
    return dataPass;
};

export const single_delete_new_call_disposition = (id) => {
    let params = { 'dispositionId': id };
    var dataPass = common_axios.post(baseURL + 'met/single_delete_new_call_disposition', params);
    return dataPass;
};

export const data_quality_disposition_list = () => {
    var dataPass = common_axios.get(baseURL + 'met/data_quality_disposition_list');
    return dataPass;
}

export const incorrect_data = (data) => {
    var dataPass = common_axios.post(baseURL + 'met/incorrect_data', data);
    return dataPass;
}

export const family_incorrect_data = (data) => {
    var dataPass = common_axios.post(baseURL + 'memberconnect/incorrect_data', data);
    return dataPass;
}

export const enroll_later = (data) => {
    var dataPass = common_axios.post(baseURL + 'met/enroll_later', data);
    return dataPass
}

export const family_enroll_later = (data) => {
    var dataPass = common_axios.post(baseURL + 'memberconnect/enroll_later', data);
    return dataPass
}

export const fetch_agent_enroll_later_pending_list = (searchStr, programId, conditionId, pageNumber, perPage, sortColumn, sortDirection, download) => {
    const params = { 'searchStr': searchStr, 'programId': programId, 'conditionId': conditionId, 'pageNumber': pageNumber, 'perPage': perPage, 'sortColumn': sortColumn, 'sortDirection': sortDirection, 'download': download }
    var dataPass = common_axios.post(baseURL + 'met/fetch_agent_enroll_later_pending_list_v2', params);
    return dataPass
}

export const fetch_agent_enroll_later_approve_list = (searchStr, programId, conditionId, pageNumber, perPage, sortColumn, sortDirection, download) => {
    const params = { 'searchStr': searchStr, 'programId': programId, 'conditionId': conditionId, 'pageNumber': pageNumber, 'perPage': perPage, 'sortColumn': sortColumn, 'sortDirection': sortDirection, 'download': download }
    var dataPass = common_axios.post(baseURL + 'met/fetch_agent_enroll_later_approve_list_v2', params);
    return dataPass
}

export const update_enroll_later_agent = (data) => {
    var dataPass = common_axios.post(baseURL + 'met/update_enroll_later_agent', data);
    return dataPass
}

export const reject_enroll_later_agent = (data) => {
    var dataPass = common_axios.post(baseURL + 'met/reject_enroll_later_agent', data);
    return dataPass
}


export const fetchMemberPersonalData = (userId, cancelToken) => {
    const params = { 'userId': userId }
    var dataPass = common_axios.post(baseURL + 'met/fetch_member_personal_data', params, cancelToken)
    return dataPass;
};

export const checkMembership = (membershipNo, mainMembershipNo, cancelToken) => {
    const params = { 'membershipNo': membershipNo, currentMainMembershipNo: mainMembershipNo }
    var dataPass = common_axios.post(baseURL + 'met/check_member_details', params, cancelToken)
    return dataPass;
};

export const saveNewMember = (formData, memberType, mainMembershipNo, cancelToken) => {
    const params = { 'formData': formData, 'memberType': memberType, currentMainMembershipNo: mainMembershipNo }
    var dataPass = common_axios.post(baseURL + 'met/save_new_member', params, cancelToken)
    return dataPass;
};

export const agent_call_disposition_graph = (startDate, endDate, agentId, requestFrom) => {
    const params = { 'startDate': startDate, 'endDate': endDate, 'agentId': agentId, 'requestFrom': requestFrom }
    var dataPass = common_axios.post(baseURL + 'met/agent_call_disposition_graph', params)
    return dataPass;
};

export const members_age_group_conversion_graph = (startDate, endDate, agentId) => {
    const params = { 'startDate': startDate, 'endDate': endDate, 'agentId': agentId }
    var dataPass = common_axios.post(baseURL + 'met/members_age_group_conversion_graph', params)
    return dataPass;
};

export const agent_correct_incorrect_graph = (dataRating, startDate, endDate, agentId, requestFrom) => {
    const params = { 'dataRating': dataRating, 'startDate': startDate, 'endDate': endDate, 'agentId': agentId, 'requestFrom': requestFrom }
    var dataPass = common_axios.post(baseURL + 'met/agent_correct_incorrect_graph', params)
    return dataPass;
};

export const agent_data_quality_graph = (startDate, endDate, agentId, requestFrom) => {
    const params = { 'startDate': startDate, 'endDate': endDate, 'agentId': agentId, 'requestFrom': requestFrom }
    var dataPass = common_axios.post(baseURL + 'met/agent_data_quality_graph', params)
    return dataPass;
};

export const agent_call_conversion_graph = (startDate, endDate, agentId, requestFrom) => {
    const params = { 'startDate': startDate, 'endDate': endDate, 'agentId': agentId, 'requestFrom': requestFrom }
    var dataPass = common_axios.post(baseURL + 'met/agent_call_conversion_graph', params)
    return dataPass;
};

export const agent_daily_weekly_graph = (startDate, endDate, agentId) => {
    const params = { 'startDate': startDate, 'endDate': endDate, 'agentId': agentId }
    var dataPass = common_axios.post(baseURL + 'met/agent_daily_weekly_graph', params)
    return dataPass;
};

export const agent_call_made_graph = (startDate, endDate, agentId, requestFrom) => {
    const params = { 'startDate': startDate, 'endDate': endDate, 'agentId': agentId, 'requestFrom': requestFrom }
    var dataPass = common_axios.post(baseURL + 'met/agent_call_made_graph', params)
    return dataPass;
};

export const agent_members_reached_graph = (startDate, endDate, agentId, requestFrom) => {
    const params = { 'startDate': startDate, 'endDate': endDate, 'agentId': agentId, 'requestFrom': requestFrom }
    var dataPass = common_axios.post(baseURL + 'met/agent_members_reached_graph', params)
    return dataPass;
};

export const agent_members_enrolled_graph = (startDate, endDate, agentId, requestFrom) => {
    const params = { 'startDate': startDate, 'endDate': endDate, 'agentId': agentId, 'requestFrom': requestFrom }
    var dataPass = common_axios.post(baseURL + 'met/agent_members_enrolled_graph', params)
    return dataPass;
};

export const agent_target_achievement_graph = (startDate, endDate, agentId, requestFrom) => {
    const params = { 'startDate': startDate, 'endDate': endDate, 'agentId': agentId, 'requestFrom': requestFrom }
    var dataPass = common_axios.post(baseURL + 'met/agent_target_achievement_graph', params)
    return dataPass;
};

export const member_conversion_funnel_graph = (startDate, endDate, agentId) => {
    const params = { 'startDate': startDate, 'endDate': endDate, 'agentId': agentId }
    var dataPass = common_axios.post(baseURL + 'met/member_conversion_funnel_graph', params)
    return dataPass;
};


export const member_insight_summery_data = (startDate, endDate, agentId, requestFrom) => {
    const params = { 'startDate': startDate, 'endDate': endDate, 'agentId': agentId, 'requestFrom': requestFrom }
    var dataPass = common_axios.post(baseURL + 'met/member_insight_summery_data', params)
    return dataPass;
};

export const download_agent_data = (startDate, endDate, agentId, requestFrom) => {
    const params = { 'startDate': startDate, 'endDate': endDate, 'agentId': agentId, 'requestFrom': requestFrom }
    var dataPass = common_axios.post(baseURL + 'met/download_agent_data', params)
    return dataPass;
};

export const save_members_notes = (data) => {
    var dataPass = common_axios.post(baseURL + 'met/save_members_notes', data);
    return dataPass;
}

export const fetch_members_notes = (data) => {
    var dataPass = common_axios.post(baseURL + 'met/fetch_members_notes', data);
    return dataPass;
}


export const fetch_download_agent = (startDate, endDate, downloadEvent) => {
    let params = { 'startDate': startDate, 'endDate': endDate, 'downloadEvent': downloadEvent }
    var dataPass = common_axios.post(baseURL + 'met/fetch_download_agent', params);
    return dataPass;
}

export const fetch_reports_agent = () => {
    var dataPass = common_axios.get(baseURL + 'met/reports_agents_fetch');
    return dataPass;
}

export const getFileLink = (id) => {
    let params = { 'reportId': id }
    var dataPass = common_axios.post(baseURL + 'met/fetch_fileurl', params);
    return dataPass;
}

export const getuserCallBack = (userId) => {
    let params = { 'userId': userId }
    var dataPass = common_axios.post(baseURL + 'memberconnect/fetch_members_callhistory_callback', params);
    return dataPass;
}

export const updateMobileNo = (userId, mobileNo, type) => {
    let params = { 'userId': userId, 'mobileNumber': mobileNo, 'type': type }
    var dataPass = common_axios.post(baseURL + 'memberconnect/save_mobile_number', params);
    return dataPass;
}

export const getScripts = () => {
    var dataPass = common_axios.get(baseURL + 'met/agent_scripts');
    return dataPass;
}


export const request_enrollment = (data) => {
    var dataPass = common_axios.post('memberconnect/request_reenrollment', data);
    return dataPass;
}


export const fetch_all_members_reenroll = () => {
    var dataPass = common_axios.get('met/fetch_all_members_reenroll');
    return dataPass;
}


export const update_members_reenroll = (data) => {
    var dataPass = common_axios.post('met/update_members_reenroll', data);
    return dataPass;
}

export const updateMainMembershipNo = (data) => {
    var dataPass = common_axios.post('memberconnect/update_mainmembership', data);
    return dataPass;
}

export const fetch_program_condition = () => {
    var dataPass = common_axios.get('memberconnect/fetch_program_condition');
    return dataPass;
}

export const update_program_condition = (data) => {
    var dataPass = common_axios.post('memberconnect/update_program_condition', data);
    return dataPass;
}


export const fetch_priority_pool_members = (queries) => {
    const params = { 'filterCondition': queries }
    var dataPass = common_axios.post(baseURL + 'met/fetch_priority_pool_members', params)
    return dataPass;
}

export const action_priority_pool_members = (actionId, remarks) => {
    const params = { 'actionId': actionId, 'remarks': remarks }
    var dataPass = common_axios.post(baseURL + 'met/action_priority_pool_members', params)
    return dataPass;
}


export const fetch_fileurl_prioritize = () => {
    var dataPass = common_axios.get(baseURL + 'met/fetch_fileurl_prioritize');
    return dataPass;
};


export const displayProritizeList = (startDateNew, endDateNew) => {
    var dataPass = common_axios.post(baseURL + 'met/display_proritize_member_list', {
        "startRange": startDateNew,
        "endRange": endDateNew
    });
    return dataPass;
};

export const createTempLink = (fileUrl) => {
    const params = { 'fileUrl': fileUrl }
    var dataPass = common_axios.post(baseURL + 'met/create_temp_link', params)
    return dataPass;
}



export const updateWebexAgentSetting = (adminId, webexEnable) => {
    const params = { 'adminId': adminId, 'webexEnable': webexEnable }
    var dataPass = common_axios.post(baseURL + 'met/update_webex_setting', params)
    return true;
};
export const whatsapp_list = (data) => {
    var dataPass = common_axios.post(baseURL + 'memberconnect/whatsapp_list', data);
    return dataPass;
}


export const whatsapp_send = (data) => {
    var dataPass = common_axios.post(baseURL + 'memberconnect/whatsapp_send', data);
    return dataPass;
}


export const whatsapp_message_status = (data) => {
    var dataPass = common_axios.post(baseURL + 'memberconnect/whatsapp_message_status', data);
    return dataPass;
}

export const fetchProgramList = (programId) => {
    const params = { 'programId': programId }
    var dataPass = common_axios.post(baseURL + 'met/fetch_program_condition_list', params)
    return dataPass;
};

export const fetch_all_members_reenroll_new = (searchStr, pageNumber, perPage, sortColumn, sortDirection, download) => {
    const params = { 'searchStr': searchStr, 'pageNumber': pageNumber, 'perPage': perPage, 'sortColumn': sortColumn, 'sortDirection': sortDirection, 'download': download }
    var dataPass = common_axios.post(baseURL + 'met/fetch_all_members_reenroll_new', params);
    return dataPass
}

export const fetch_all_members_reenroll_past = (searchStr, pageNumber, perPage, sortColumn, sortDirection, download) => {
    const params = { 'searchStr': searchStr, 'pageNumber': pageNumber, 'perPage': perPage, 'sortColumn': sortColumn, 'sortDirection': sortDirection, 'download': download }
    var dataPass = common_axios.post(baseURL + 'met/fetch_all_members_reenroll_past', params);
    return dataPass
}



export const fetch_member_callback_data  = ()  => { 
    let dataPass = common_axios.get(baseURL + 'met/fetch_member_callback_data');
    return dataPass
}

export const add_back_to_pool = (data) => { 
    let dataPass = common_axios.post(baseURL + 'met/add_back_to_pool', data);
    return dataPass
}