import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import './HomeCareRequest.css';
import { fetchUserProfile, fetchHomeCare, saveHomeCareRequest } from '../services/Service';
import axios from "axios";
import { MultiSelect } from 'react-multi-select-component';
import Select from 'react-select';

const HomeCareRequest = ({ closeModal, selectedServiceRecord, callHomeBasedServicesHistory, selectedBulkServices }) => {

    const qsParams = useParams();
    const userId = qsParams.memberId;

    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [providerList, setProviderList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [serviceList, setServiceList] = useState([]);
    const [disabledButton, setDisabledButton] = useState(true);
    const [selectedOptions, setSelectedOptions] = useState([]);

    const [formData, setFormData] = useState({
        userId: userId,
        selectedServiceRecord: selectedServiceRecord,
        membership: '',
        name: '',
        approvalNumber: '',
        mobileNumber: '',
        deductible: '',
        partnerClinic: 'Direct Request',
        city: '',
        district: '',
        remarks: '',
        nationality: '',
        homeHealthCare: '',
        requestedHomeHealth: '',
        selectedUserNames: '',
        cityId: '',
    });

    useEffect(() => {
        fetchUserData();
        dropdownData();
    }, [])


    const fetchUserData = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        setDisabledButton(true);
        await fetchUserProfile(userId,'bupa')
            .then((response) => {
                if (response.data.code === 200) {
                    setFormData((prev) => ({ ...prev, membership: response.data.data.info.membershipNumber, name: response.data.data.info.memberName, mobileNumber: response.data.data.info.mobile, city: response.data.data.info.city, nationality: response.data.data.info.nationality }));
                    setDisabledButton(false);
                } else {
                    setError(response.data.data.message);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const dropdownData = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchHomeCare()
            .then((response) => {
                if (response.data.code === 200) {
                    setProviderList(response.data.data.provider);
                    setServiceList(response.data.data.service);
                    setCityList(response.data.data.city);

                    if (selectedBulkServices.length > 0) {
                        findServicesByLabel(response.data.data.service);
                    }
                } else {
                    setError(response.data.data.message);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const findServicesByLabel = (sl) => {
        // Use filter to find all matching objects
        const foundServices = sl.filter(service => selectedBulkServices.includes(service.label));
        console.log("Found services", selectedBulkServices, foundServices);
        // Update the filtered services state
        handleChangeService(foundServices);
    };



    const handleChange = async (e) => {
        const { name, value } = e.target;
        // if (name === "deductible") {
        //     if (!/^\d*\.?\d*$/.test(value)) {
        //         return false;
        //     }
        // }
        setFormData({
            ...formData,
            [name]: value,
        });

        setError('');
    };

    const handleChangeCity = (selectedOption) => {
        setFormData({
            ...formData,
            ['city']: selectedOption.label,
            ['cityId']: selectedOption.value,
        });
    };

    const validateForm = () => {
        const mandatoryFields = ['membership', 'name', 'mobileNumber', 'partnerClinic', 'deductible', 'homeHealthCare', 'city'];

        for (let field of mandatoryFields) {
            if ((field === 'city')) {
                if (formData[field] == "") {
                    setError('Enter all mandatory fields');
                    return false;
                }
            } else if (!formData[field].trim()) {
                setError('Enter all mandatory fields');
                return false;
            }
        }

        return true;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');
        if (validateForm()) {
            const request = axios.CancelToken.source();
            let ct = {
                cancelToken: request.token
            }
            setDisabledButton(true);
            await saveHomeCareRequest(formData)
                .then((response) => {
                    if (response.data.code === 200) {
                        setError('');
                        setMessage(response.data.data.message);
                        callHomeBasedServicesHistory();
                        setTimeout(() => {
                            closeModal();
                        }, 1000);
                    } else {
                        setMessage('');
                        setError(response.data.data.message);
                        setDisabledButton(false);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            return () => request.cancel();
        }
    }

    const handleChangeService = (selected) => {
        setSelectedOptions(selected);
        const selectedValues = selected.map(option => option.value).join(',');
        setFormData({ ...formData, requestedHomeHealth: selectedValues });
    };

    const selectedUserNames = selectedOptions.map(option => option.aliasName).join(', ');

    return (
        <>
            <div className='col-md-12'>
                <div className='card card-task-appoint' style={{ overflow: "visible" }}>
                    <div className='card-body'>
                        <div className='card-text'></div>
                        <form id="medicationForm" onSubmit={handleSubmit}>

                            <div className='row '>


                                <div className='col-md-6 mb-3 '>
                                    <div className='row'>
                                        <div className='col-md-6 mb-4'>
                                            <label className="form-label">Membership
                                                <span className='text-danger'>*</span></label>
                                            <h4 name="membership">{formData['membership']}</h4>
                                        </div>

                                        <div className='col-md-6 mb-4'>
                                            <label className="form-label">Name
                                                <span className='text-danger'>*</span></label>
                                            <h4 name="name">{formData['name']}</h4>
                                        </div>

                                        <div className='col-md-6 mb-3'>
                                            <label className="form-label">Mobile Number
                                                <span className='text-danger'>*</span>
                                            </label>
                                            {/* <h4 name="mobileNumber">{formData['mobileNumber']}</h4> */}
                                            <input type="text" name="mobileNumber" className='form-control control-input-ui' value={formData['mobileNumber']} onChange={handleChange} />
                                        </div>

                                        <div className='col-md-6 mb-3'>
                                            <label className="form-label">City
                                                <span className='text-danger'>*</span>
                                            </label>
                                            {/* <h4 name="city">{formData['city']}</h4> */}
                                            {/* <select className="form-select select-input-ui" name="city" id="city" value={formData['city']} onChange={handleChange} >
                            <option value="">-- Select City --</option>
                            {cityList.map(option => (
                                <option key={option.id} value={option.cityName}>{option.cityName}</option>
                            ))}
                        </select> */}

                                            <Select
                                                value={cityList.find(option => option.label === formData.city) || null}
                                                onChange={handleChangeCity}
                                                options={cityList}
                                                isSearchable={true}
                                                placeholder="Select City"
                                                className="form-control select-input-ui select-input-ui-select"
                                            />
                                        </div>

                                        <div className='col-md-6 mb-2'>
                                            <label className="form-label">District</label>
                                            <input type="text" name="district" className='form-control control-input-ui' value={formData['district']} onChange={handleChange} />
                                        </div>

                                        <div className='col-md-6 mb-2'>
                                            <label className="form-label">Nationality<span className='text-danger'>*</span>
                                            </label>
                                            <select className="form-select select-input-ui" name="nationality" id="nationality" value={formData['nationality']} onChange={handleChange} >
                                                <option value="">Select Nationality</option>
                                                <option value="Saudi Arabian">Saudi Arabian</option>
                                                <option value="Non Saudi Arabian">Non Saudi Arabian</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>



                                <div className='col-md-6 mb-3'>
                                    <div className='row'>
                                        <div className='col-md-6 mb-3'>
                                            <label className="form-label">Approval Number
                                            </label>
                                            <input type="text" name="approvalNumber" className='form-control control-input-ui' value={formData['approvalNumber']} onChange={handleChange} />
                                        </div>

                                        <div className='col-md-6 mb-3'>
                                            <label className="form-label">Partner Clinic
                                                <span className='text-danger'>*</span>
                                            </label>
                                            <input type="text" name="partnerClinic" className='form-control control-input-ui' disabled={true} value={formData['partnerClinic']} onChange={handleChange} />
                                        </div>

                                        <div className='col-md-6 mb-3'>
                                            <label className="form-label">Deductible
                                                <span className='text-danger'>*</span>
                                            </label>
                                            <input type="text" name="deductible" className='form-control control-input-ui' value={formData['deductible']} onChange={handleChange} />
                                        </div>

                                        <div className='col-md-6 mb-3'>
                                            <label className="form-label">Home Health care provider Name
                                                <span className='text-danger'>*</span>
                                            </label>
                                            <select className="form-select select-input-ui" name="homeHealthCare" id="homeHealthCare" value={formData['homeHealthCare']} onChange={handleChange} >
                                                <option value="">Select Home Health care</option>
                                                {providerList.map(option => (
                                                    <option key={option.id} value={option.id}>{option.providerName}</option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className='col-md-6 mb-3'>
                                            <label className="form-label">Home Health Care service
                                            </label>
                                            {/* <select className="form-select select-input-ui" name="requestedHomeHealth" id="requestedHomeHealth" value={formData['requestedHomeHealth']} onChange={handleChange} >
                                <option value="">-- Select Requested Home Health --</option>
                                {serviceList.map(option => (
                                    <option key={option.id} value={option.id}>{option.aliasName}</option>
                                ))}
                        </select> */}

                                            <MultiSelect
                                                options={serviceList}
                                                value={selectedOptions}
                                                onChange={handleChangeService}
                                                labelledBy="Select"
                                                hasSelectAll={false}
                                                disableSearch={false}
                                                overrideStrings={{
                                                    selectAll: 'Select All',
                                                    allItemsAreSelected: 'All items are selected',
                                                    selectSomeItems: 'Select items',
                                                }}
                                                className="form-select select-input-ui select-input-ui-select"
                                                name="requestedHomeHealth"
                                                id="requestedHomeHealth"

                                            />
                                        </div>

                                        <div className='col-md-6 mb-3'>
                                            <label className="form-label">Remarks</label>
                                            <input type="text" name="remarks" className='form-control control-input-ui' value={formData['remarks']} onChange={handleChange} />
                                        </div>
                                        <div className='col-md-12 mb-3'>
                                            {selectedUserNames}
                                        </div>
                                    </div>



                                </div>
                                <div className='col-md-12 text-center mt-2'>
                                    <div className={`${error != "" ? "errorDiv" : "hide"}`}>{error}</div>
                                    <div className={`${message != "" ? "messageDiv" : "hide"}`}>{message}</div>
                                    <Button variant="primary" type="submit" className="btn btn-primary save-btn me-3" disabled={disabledButton}>Save Home Care Request</Button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>

        </>
    )
}

export default HomeCareRequest;