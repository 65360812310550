import React, { useEffect, useState } from 'react'
import { Modal, Tab, Tabs, Button, Dropdown, DropdownButton, Row, Col } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { transferBulkMembers } from '../../../services/medengage/Service';

const BulkMemberTransfer = (props) => {
    const [transferReason, setTransferReason] = useState('');
    const [transferReasonOthers, setTransferReasonOthers] = useState('');
    const [transferType, setTransferType] = useState('');
    const [transferToCN, setTransferToCN] = useState("");
    const [transferToCNCapacity, setTransferToCNCapacity] = useState("");
    const [transferToCNTM, setTransferToCNTM] = useState("");
    const [memberCountToTransfer, setMemberCountToTransfer] = useState("");
    const [transferFromCNTotalMembers, setTransferFromCNTotalMembers] = useState(props.transferFromCNTotalMembers);
    const [dateRange, setDateRange] = useState(props.getNextWeekRange);
    const [startDate, endDate] = dateRange;
    const [loading, setLoading] = useState(false);
    const [dateStart, setDateStart] = useState(new Date());
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [successPerc, setSuccessPerc] = useState(0);
    const [failurePerc, setFailurePerc] = useState(0);

    const handleIntegerInput = (e) => {
        setError("");
        setMessage("");
        const inputValue = e.target.value;

        if (/^\d+$/.test(inputValue) && parseInt(inputValue, 10) > 0) {
            setMemberCountToTransfer(inputValue);
        } else if (inputValue === "") {
            setMemberCountToTransfer("");
        }
    }
    const handleCapacityCheck = (e) => {
        const { options, selectedIndex } = e.target;
        const cnId = e.target.value;
        const capacity = options[selectedIndex].getAttribute('data-capacity');
        const totalMembers = options[selectedIndex].getAttribute('data-totalMembers');
        setTransferToCN(cnId);
        setTransferToCNCapacity(capacity);
        setTransferToCNTM(totalMembers);
    }

    const handleSubmit = async () => {
        setError("");
        setMessage("");
        setSuccessPerc(0);
        setFailurePerc(0);
        if (transferReason == "") {
            setError("Please select the reason to transfer.");
        } else if (transferReason == "Others" && transferReasonOthers == "") {
            setError("Please mention the other reason.");
        } else if (transferType == "") {
            setError("Please select the transfer type.");
        } else if (transferType == "") {
            setError("Please select the transfer type.");
        } else if (transferToCN == "") {
            setError("Please select the CareNavigator to whom you want the members to be transferred.");
        } else if (memberCountToTransfer == "" || memberCountToTransfer <= 0) {
            setError("Please enter the member count to be transferred to the selected CareNavigator.");
        } else if (parseInt(transferToCNCapacity - transferToCNTM) < parseInt(memberCountToTransfer)) {
            setError("Count of members to be transferred is higher than the remaining capacity of the selected CareNavigator.");
        } else if (parseInt(memberCountToTransfer) > parseInt(transferFromCNTotalMembers)) {
            setError("You cannot transfer more than the remaining members of the CN");
        } else {
            //return false;
            setLoading(true);
            transferBulkMembers(props.transferFromCNID, transferReason, transferReasonOthers, transferType, transferToCN, memberCountToTransfer, props.formatDateYmd(startDate), props.formatDateYmd(endDate), props.formatDateYmd(dateStart))
                .then((response) => {
                    if (response.data.code == 200) {
                        props.loadUserData();
                        setTransferReason("");
                        setTransferType("");
                        setDateStart(new Date());
                        setTransferToCN("");
                        setMemberCountToTransfer("");
                        setTransferFromCNTotalMembers(response.data.data.careTotalMembers);

                        setMessage(response.data.data.message);
                        setSuccessPerc(response.data.data.successPerc);
                        setFailurePerc(response.data.data.failurePerc);
                    } else {
                        setTransferReason("");
                        setTransferType("");
                        setDateStart(new Date());
                        setTransferToCN("");
                        setMemberCountToTransfer("");

                        setError(response.data.data.message);
                        setSuccessPerc(response.data.data.successPerc);
                        setFailurePerc(response.data.data.failurePerc);
                    }
                    setLoading(false);
                });
        }
    }
    return (
        <Modal id="metricsAdd" show={props.showBulkTransferModal} centered onHide={() => props.setShowBulkTransferModal(false)} size="" backdrop="static" keyboard={false} fullscreen={false}>
            <Modal.Header closeButton>
                <h1 className='modal-title'>Bulk Member Transfer</h1>
            </Modal.Header>
            <Modal.Body>
                {
                    (error != "" || message != "") &&
                    <Row>
                        <Col>
                            <center>
                                <div className={`${error != "" ? "errorDiv" : "hide"} `}>{error}</div>
                                <div className={`${message != "" ? "messageDiv" : "hide"}`}>{message}</div>
                            </center>
                        </Col>
                    </Row>
                }
                {
                    (successPerc > 0 || failurePerc > 0) &&
                    <Row>
                        <Col className='mb-3'>
                            <div className="progress-stacked" style={{ "height": "6px" }}>
                                <div className="progress" role="progressbar" style={{ width: `${successPerc}%` }}>
                                    <div className="progress-bar" style={{ "backgroundColor": "#64C920" }}></div>
                                </div>
                                <div className="progress" role="progressbar" style={{ width: `${failurePerc}%` }}>
                                    <div className="progress-bar" style={{ "backgroundColor": "#FF0000" }}></div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                }

                {
                    transferFromCNTotalMembers > 0 &&
                    <>
                        <Row>
                            <Col className='mb-3'>
                                <label className="form-label mb-1">Transfer From:</label>
                                <h6 className=' mb-0'>{props.transferFromCNName} (Total members: {transferFromCNTotalMembers})</h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='mb-3'>
                                <label className="form-label">Transfer Reason:</label>
                                <select
                                    value={transferReason}
                                    onChange={e => {
                                        setTransferReason(e.target.value);
                                    }}
                                    className='form-select input-field-ui'
                                >
                                    <option value="">--Select Reason--</option>
                                    <option value="Medical Leave">Care Navigator on Medical Leave</option>
                                    <option value="Extended Vacation">Care Navigator on Extended Vacation</option>
                                    <option value="Temporary Assignment">Care Navigator on Temporary Assignment</option>
                                    <option value="Training Development">Care Navigator on Training/Professional Development</option>
                                    <option value="Performance Issues">Care Navigator's Performance Issues</option>
                                    <option value="Preference Change">Member's Preference Change</option>
                                    <option value="Reassignment">Operational Reassignment</option>
                                    <option value="Unforeseen Circumstances">Coverage for Unforeseen Circumstances</option>
                                    <option value="Others">Others</option>
                                </select>
                                {transferReason == 'Others' &&
                                    <>
                                        <div className="col-auto" style={{ paddingTop: "5px" }}>
                                            <input type="text" className="form-control input-field-ui" id="transferReasonOthers" value={transferReasonOthers} onChange={e => setTransferReasonOthers(e.target.value)} placeholder="Enter Reason" />
                                        </div>
                                    </>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col className='mb-3'>
                                <label className="form-label">Transfer Type:</label>
                                <select value={transferType} onChange={e => setTransferType(e.target.value)} className='form-select input-field-ui' >
                                    <option value="">--Select Type--</option>
                                    <option value="temporary">Temporary Transfer</option>
                                    <option value="permanant">Permanent Transfer</option>
                                </select>
                            </Col>
                        </Row>
                        {
                            transferType === "temporary" &&
                            <>
                                <Row>
                                    <Col className='mb-3'>
                                        <label className="form-label">Transfer Plan-Back Date:</label>
                                        <div className="date-picker-wrapper" style={{ position: 'relative' }}>
                                            <DatePicker
                                                selectsRange={true}
                                                startDate={startDate}
                                                endDate={endDate}
                                                className="form-control input-field-ui"
                                                onChange={(update) => {
                                                    setDateRange(update);
                                                }}
                                                isClearable={false}
                                                dateFormat={
                                                    "dd/MM/yyyy"
                                                }
                                                icon="bx bx-calendar"
                                                minDate={new Date()}
                                                placeholderText="Select Date"
                                                onKeyDown={(e) => e.preventDefault()}
                                                onPaste={(e) => e.preventDefault()}
                                            />
                                            <i className='bx bx-calendar'
                                                style={{
                                                    position: 'absolute',
                                                    right: '10px',
                                                    top: '50%',
                                                    transform: 'translateY(-50%)',
                                                    pointerEvents: 'none',
                                                    fontSize: '20px',
                                                    color: '#666'
                                                }}></i>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        }
                        {
                            transferType === "permanant" &&
                            <>
                                <Row>
                                    <Col className='mb-3'>
                                        <label className="form-label">Transfer Plan Date:</label>

                                        <div className="date-picker-wrapper" style={{ position: 'relative' }}>
                                            <DatePicker
                                                selectsRange={false}
                                                selected={dateStart}
                                                className="form-control input-field-ui"
                                                onChange={(date) => setDateStart(date)}
                                                isClearable={false}
                                                dateFormat="dd/MM/yyyy"
                                                icon="bx bx-calendar"
                                                minDate={new Date()}
                                                placeholderText="Select Date"
                                                onKeyDown={(e) => e.preventDefault()}
                                                onPaste={(e) => e.preventDefault()}
                                            />
                                            <i className='bx bx-calendar'
                                                style={{
                                                    position: 'absolute',
                                                    right: '10px',
                                                    top: '50%',
                                                    transform: 'translateY(-50%)',
                                                    pointerEvents: 'none',
                                                    fontSize: '20px',
                                                    color: '#666'
                                                }}></i>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        }
                        <Row>
                            <Col className='mb-3'>
                                <label className="form-label">Transfer To CareNavigator:</label>
                                <select
                                    className="form-select input-field-ui"
                                    onChange={handleCapacityCheck}
                                    value={transferToCN || ''}
                                >
                                    <option value="">Select</option>
                                    {
                                        props.transferToCNList.map((item, index) => {
                                            return <option value={item.cnID} data-capacity={item.capacity} data-totalMembers={item.totalMembers} >{item.cnName} ({item.totalMembers})</option>
                                        })
                                    }
                                </select>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='mb-4'>
                                <label className="form-label">Member Count to Transfer:</label>
                                <input className='form input-field-ui' type="text" name="memberCount" value={memberCountToTransfer} onChange={handleIntegerInput} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <center>
                                    <button className='btn program-save-btn me-2' onClick={handleSubmit}>Transfer</button>
                                </center>
                            </Col>
                        </Row>
                    </>
                }
                {
                    transferFromCNTotalMembers == 0 &&
                    <Row>
                        <Col>
                            <center>
                                <button className='btn program-save-btn me-2' onClick={() => props.setShowBulkTransferModal(false)}>Close</button>
                            </center>
                        </Col>
                    </Row>
                }
            </Modal.Body>
        </Modal>
    )
}

export default BulkMemberTransfer