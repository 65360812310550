import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, Tab, Tabs, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import axios from 'axios';
import alertify from 'alertifyjs';
import { Link } from 'react-router-dom';
import './PathwayChangeLogs.css';
import { fetchPathwayChangeLogs } from "../../../../services/cn/Service";


const PathwayChangeLogs = forwardRef((props, ref) => {
    const qsParams = useParams();
    const userId = qsParams.memberId;
    const [changeLogs, setChangeLogs] = useState([]);

    useEffect(() => {
        getPathwayChangeLogs();
    }, [userId])

    useImperativeHandle(ref, () => ({
        getPathwayChangeLogs
    }));

    const getPathwayChangeLogs = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchPathwayChangeLogs(userId, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setChangeLogs(response.data.data.logs);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }


    return (
        <>
            {
                changeLogs.length > 1 &&
                <div className='col-md-12 mt-3 mb-3'>
                    <div className='card card-task'>
                        <div className='card-body'>
                            <div className='card-text'>
                                <div className='row align-items-center' >
                                    <div className='col'>
                                        <h5 className='card-title'>Pathway Change Logs</h5>
                                        <div className='table-responsive mt-2' style={{ maxHeight: "380px", overflowX: "hidden" }}>
                                            <table className="table table-striped table-bordered mb-0">
                                                <thead style={{ position: "sticky", top: "0" }}>
                                                    <tr>
                                                        <th scope="col">Condition</th>
                                                        <th scope="col">Pathway</th>
                                                        {/*<th scope="col">HbA1c</th>*/}
                                                        <th scope="col" style={{ width: "15%" }}>Date</th>
                                                        <th scope="col" style={{ width: "15%" }}>Comments</th>
                                                        <th scope="col" style={{ width: "15%" }}>Assigned By</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        changeLogs.map((item, index) => {
                                                            return <>
                                                                <tr style={{ verticalAlign: 'middle' }}>
                                                                    <td className='bg-td-green'>{item.conditionName}</td>
                                                                    <td className='bg-td-green'>{item.pathwayName}</td>
                                                                    {/*<td className='bg-td-green'>{item.hba1c}</td>*/}
                                                                    <td className='bg-td-green'>{item.date}</td>
                                                                    <td className='bg-td-green'>{item.notes}</td>
                                                                    <td className='bg-td-green'>{item.assignedBy}</td>
                                                                </tr>
                                                            </>
                                                        })
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            }

        </>
    )
});

export default PathwayChangeLogs