import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { Modal } from 'react-bootstrap';
import { fetchDoctorList,fetchDoctorSlots,bookMemberAppointment,memberDetailsEdge } from '../services/Service';
import axios from "axios";
import DatePicker from "react-datepicker";
import { CustomLoaderTele } from '../common/Utils';
import { updateTeleHealthService } from '../../../../services/cn/Service';

const TeleHealthBook = (props) => {
    
    const [error, setError] = useState("");
    const [errorNew, setErrorNew] = useState("");
    const [errorSlot, setErrorSlot] = useState("");
    const [message, setMessage] = useState("");
    const [doctors, setDoctors] = useState([]);
    const [showDoctor, setShowDoctor] = useState(false);
    // const [appointmentDate, setAppointmentDate] = useState(new Date());
    
    const [appointmentDate, setAppointmentDate] = useState('');
    const [minSelectableTime, setMinSelectableTime] = useState('');
    const [slotsData, setSlotsData] = useState([]);
    const [selectedDoctor, setSelectedDoctor] = useState('');
    const [selectedDoctorName, setSelectedDoctorName] = useState('');
    const [showCard, setShowCard] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState('')
    const [selectedSlotTime, setSelectedSlotTime] = useState('')
    const [showLoader, setShowLoader] = useState(false);
    const [memberError, setMemberError] = useState(true);
    const [bookingNotes, setBookingNotes] = useState("");
    const [loading, setLoading] = useState(true);
    const [loadingNew, setLoadingNew] = useState(true);
    const [patientId, setPatientId] = useState('');
    const [speciality, setSpeciality] = useState('');
    const [callValue, setCallValue] = useState('');
    
    const [totalPages, setTotalPages] = useState(0);
    const [currentRecordsRange, setCurrentRecordsRange] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loadingSlots, setLoadingSlots] = useState(false);
    
    
    const membershipNo = props.membershipNo;
    const userId = props.userId;

    const formObject = {
        location: "",
        gender: "",
        speciality:"",
    }
    const [formData, setFormData] = useState(formObject);

    useEffect(() => {
        checkMemberEdge();
    }, [])

    useEffect(() => {
        const pages = Math.ceil(totalRecords / rowsPerPage);
        setTotalPages(pages);
    }, [rowsPerPage, totalRecords]);

    useEffect(() => {
        callNewFunction();
    }, [callValue]);

    useEffect(() => {
        if(!props.rescheduleCall){
            searchExternalMemberDetails();
        }
    }, [currentPage, rowsPerPage]);

    const goToFirstPage = () => {
        setCurrentPage(1);
        setLoadingNew(true);
    }
    const goToPreviousPage = () => {
        setCurrentPage(prev => Math.max(prev - 1, 1));
        setLoadingNew(true);
    }
    const goToNextPage = () => {
        setCurrentPage(prev => Math.min(prev + 1, totalPages));
        setLoadingNew(true);
    }
    const goToLastPage = () => {
        setCurrentPage(totalPages);
        setLoadingNew(true);
    }

    const formatDateTime = (date) => {
        if (!date) return "";
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
      
        return `${year}-${month}-${day} ${hours}:${minutes}`;
    };

    const setNewSlots = (slotId,slotTime) => {
        setSelectedSlot(slotId);
        setSelectedSlotTime(slotTime);
    };

    const handleChange = async (e) => {
        
        const { name, value } = e.target;

        if (name == "location" || name == "gender") {
            if (!/^[a-zA-Z\s]*$/.test(value)) {
                return false;
            }
        }
        setFormData({
            ...formData,
            [name]: value,
        });

        setError('');
    };

    const resetFormData = async () => {
        setFormData(formObject);
        setCallValue(callValue + 1);
    }

    const callNewFunction = async () => {
        if(currentPage == 1){
            searchExternalMemberDetails();
        }else{
            setCurrentPage(1);
        }
    }

    const getRoundedDate = () => {
        const date = new Date();
        const minutes = date.getMinutes();
        const roundedMinutes = Math.ceil(minutes / 15) * 15;
        date.setMinutes(roundedMinutes);
        date.setSeconds(0);
        return date;
    };

    
    const searchExternalMemberDetailsNew = async () => {
        setLoadingNew(true);
        if(currentPage == 1){
            searchExternalMemberDetails();
        }else{
            setCurrentPage(1);
        }
    }

    const searchExternalMemberDetails = async () => {
        
        // e.preventDefault()        
        setBookingNotes("");
        setDoctors([]);
        setShowCard(false);
        setSelectedDoctor('');
        setSelectedDoctorName('');
        setErrorNew("");
        setAppointmentDate("");
        setSlotsData("");
        
        if (1 != 1 && (formData['location'] == "" && formData['gender'] == "")) {
            setError("Please enter search criteria");
        } else {
            setMessage("");
            setError("");

            const request = axios.CancelToken.source();
            let cancelToken = {
                cancelToken: request.token
            }

            await fetchDoctorList(formData,currentPage,rowsPerPage, cancelToken)
                .then((response) => {
                    
                    if (response.data.code === 200) {
                        if (response.data.data.doctorList.length > 0) {
                            setLoadingNew(false);
                            setDoctors(response.data.data.doctorList);
                            const roundedDate = getRoundedDate();
                            setAppointmentDate(roundedDate);
                            setMinSelectableTime(roundedDate);
                            if(props.rescheduleCall){
                                setShowCard(true);
                                setSelectedDoctor(props.rescheduleCall);
                                setSelectedDoctorName(props.doctorName);
                            }else{
                                setTotalRecords(response.data.data.totalDoctors)
                                const start = (currentPage - 1) * rowsPerPage + 1;
                                const end = Math.min(currentPage * rowsPerPage, response.data.data.totalDoctors);
                                if (start > 0 && end > 0) {
                                    setCurrentRecordsRange(`${start}-${end}`);
                                }
                            }
                           
                        } else {
                            if(memberError != ''){
                                setError("No Doctors found with the given criteria");
                            }else{
                                setError(false);
                            }
                        }
                    } else {
                        setError(response.data.data.message);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            return () => request.cancel();
        }
    }


    const doctorSelection = async (doctorId,doctorName) => {
        setSelectedDoctor(doctorId);
        setSelectedDoctorName(doctorName);
        setShowCard(true);
    }

    const checkMemberEdge = async () => {

        const request = axios.CancelToken.source();
        let cancelToken = {
            cancelToken: request.token
        }
        await memberDetailsEdge(membershipNo,userId, cancelToken)
                .then((response) => {
                    if (response.data.data.status == 0) {
                        setMemberError(response.data.data.msg);
                        setLoadingNew(false);
                        setError(false);
                    }else{
                        setPatientId(response.data.data.msg);
                        setShowDoctor(true);
                        setMemberError(false);
                        setSpeciality(response.data.data.specialityData);
                        // if(!props.rescheduleCall){
                        //     searchExternalMemberDetails();
                        // }
                    }
                    setLoading(false);
                }).catch(function (error) {
                    console.log(error);
                });
            return () => request.cancel();
    }

    const slotFetchSelection = async () => {
        setLoadingSlots(true);
        setMessage("");
        setErrorNew("");
        setError("");
        setSlotsData("");
        setSelectedSlot("");
        setSelectedSlotTime("");
        if(appointmentDate == ''){
            setErrorNew("Please select date and time for your slot");
            return false;
        }else if(selectedDoctor == ''){
            setErrorNew("Somehing went wrong! Try again later");
            return false;
        }

        const request = axios.CancelToken.source();
        let cancelToken = {
            cancelToken: request.token
        }
        await fetchDoctorSlots(selectedDoctor, formatDateTime(appointmentDate), cancelToken)
                .then((response) => {
                    if (response.data.code === 200) {
                        if (response.data.data.doctorSlots.slots) {
                            setSlotsData(response.data.data.doctorSlots.slots);
                        } else {
                            setErrorNew("No appointment slots available for the selected doctor.");
                        }
                        setLoadingSlots(false);
                    } else {
                        setError(response.data.data.message);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            return () => request.cancel();
    }

    const bookAppointment = async () => {
        if(selectedSlotTime == ''){
            setErrorSlot("Please select a slot");
            return false;
        }else if(selectedDoctor == ''){
            setErrorSlot("Somehing went wrong! Try again later");
            return false;
        }
        // else if(bookingNotes == ''){
        //     setErrorSlot("Please add booking notes");
        //     return false;
        // }
        setShowLoader(true);
        setMessage("");
        setErrorNew("");
        setErrorSlot("");
        setSlotsData("");
        setError("");
        const request = axios.CancelToken.source();
        let cancelToken = {
            cancelToken: request.token
        }

        if(props.rescheduleCall){
            await updateTeleHealthService('reschedule',props.bookingId,props.teleCallId, formatDateTime(appointmentDate),selectedSlotTime,bookingNotes, cancelToken)
            .then((response) => {
                if (response.data.data.bookingData.status == "0") {
                    setMessage(response.data.data.bookingData.bookingStatus);
                    props.callCallsHistory();
                    props.closeTelehealthModal();
                    // if(response.data.data.bookingData.showPopup){
                    props.handleShowUploadTeleHealthReschedule(response.data.data.bookingData.bookingId,response.data.data.bookingData.callId,response.data.data.bookingData.fileErrorMsg);
                    // }
                    
                } else {
                    setError(response.data.data.bookingData.bookingStatus);
                }
                setShowLoader(false);
                setSelectedSlot("");
                setSelectedSlotTime("");
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
        }else{
            await bookMemberAppointment(selectedDoctor,selectedDoctorName, formatDateTime(appointmentDate),selectedSlotTime,membershipNo,userId,bookingNotes,patientId, cancelToken)
            .then((response) => {
                if (response.data.data.bookingData.status == "0") {
                    setMessage(response.data.data.bookingData.bookingStatus);
                    props.callCallsHistory();
                    props.closeTelehealthModal();
                    // if(response.data.data.bookingData.showPopup){
                    props.handleShowUploadTeleHealthReschedule(response.data.data.bookingData.bookingId,response.data.data.bookingData.callId,response.data.data.bookingData.fileErrorMsg);
                    // }
                } else {
                    setError(response.data.data.bookingData.bookingStatus);
                }
                setShowLoader(false);
                setSelectedSlot("");
                setSelectedSlotTime("");
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
        }
    }


    const isToday = (date) => {
        const today = new Date();
        return (
            date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear()
        );
    };

    
    return (
        <>
            <Modal id="externalMemberSearch" show={props.showTelehealthModal} onHide={props.closeTelehealthModal} size="xl" backdrop="static" keyboard={false} fullscreen={false}>
                <Modal.Header closeButton>
                    <h1 className='modal-title'>TeleHealth Consultation</h1>
                </Modal.Header>
                <Modal.Body>

                    {memberError ? 
                        <div className='col-12'>
                            <div className={`${memberError != "" ? "errorDiv" : "hide"}`} style={{ fontWeight: "400 !important" }}>{memberError}</div>
                        </div>
                    :
                    !props.rescheduleCall &&
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className="row">
                                    <div className='col-md-12 mb-3'>
                                        <div className='row'>
                                            <div className='col-3'>
                                                <label className="form-label">City</label>
                                                <input type="text" className='form-control control-input-ui-addmember' placeholder='City' name="location" value={formData['location']} onChange={handleChange} />
                                            </div>
                                            <div className='col-3'>
                                                <label className="form-label">Gender</label>
                                                <select class="form-select select-input-ui" name="gender" value={formData['gender']} onChange={handleChange}>
                                                    <option value="">--Select Gender--</option>
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                </select>
                                            </div>

                                            <div className='col-3'>
                                                <label className="form-label">Speciality</label>
                                                <select class="form-select select-input-ui" name="speciality" value={formData['speciality']} onChange={handleChange}>
                                                    <option value="">--Select Speciality--</option>
                                                    {speciality.map((option) => (
                                                        <option key={option.edgeId} value={option.edgeId}>
                                                            {option.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className={`${error != "" ? "errorDiv" : "hide"}`} style={{ fontWeight: "400 !important" }}>{error}</div>
                                                <div className={`${message != "" ? "messageDiv" : "hide"}`} style={{ fontWeight: "400 !important" }}>{message}</div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-3 d-flex'>
                                                <Button variant="primary" type="submit" className="btn btn-primary save-btn btn btn-primary mt-2 me-2" style={{ background: "#03335b", border: "1px solid #03335b" }} onClick={searchExternalMemberDetailsNew} >Search</Button>
                                                <Button variant="secondary" type="button" className="btn btn-secondary save-btn btn mt-2" style={{ background: "#e9e9e9", border: "1px solid #03335b" }} onClick={(e) => resetFormData()} >Reset</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {(loading || loadingNew) && <div><img src='https://storage.googleapis.com/ksabupatest/2024/09/24/48lld/8hx4bl7sy4.gif' style={{width:'100px'}}></img></div>}
                    

                    {error && props.rescheduleCall && !memberError && <div className='row'>
                        <div className='col-12'>
                            <div className={`${error != "" ? "errorDiv" : "hide"}`} style={{ fontWeight: "400 !important" }}>{error}</div>
                        </div>
                    </div> }
                    {!showCard && showDoctor && <div className='row'>
                        {
                            doctors?.map((item, index) => {
                                return (
                                    <div className='col-md-4' key={index}>
                                        <div className="userslistSearch my-2" key={item.doctorId}>
                                            <div className='row'>
                                                <div className="col col-md" style={{ cursor: 'pointer' }} onClick={() => doctorSelection(item.doctorId, `${item.firstNameEn} ${item.lastNameEn}`)}>
                                                    <h5 className='text-small'><b>{item.firstNameEn} {item.lastNameEn}</b></h5>
                                                    <h5 className='text-small'><span>{item.gender}</span>, <span className='ps-1'>{item.location}</span></h5>
                                                    <h5 className='text-small'><span>{item.experience}</span></h5>
                                                    {item.speciality && <h5 className='text-small'><span className='pe-2'>{item.speciality.name}</span></h5> }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                        {doctors.length > 0 && showDoctor && 
                            <div className="pagination-container">
                                <div className="rows-per-page">
                                    <label>Rows per page: </label>
                                    <select value={rowsPerPage} onChange={(e) => setRowsPerPage(Number(e.target.value))}>
                                        <option value={10}>10</option>
                                        <option value={15}>15</option>
                                        <option value={20}>20</option>
                                        <option value={25}>25</option>
                                    </select>
                                </div>
                
                                <div className="records-info">
                                    {currentRecordsRange && totalRecords > 0 && `${currentRecordsRange} of ${totalRecords} records`}
                                </div>

                                {totalRecords > 0 && (
                                    <div className="pagination-controls">
                                        <button onClick={goToFirstPage} disabled={currentPage === 1}>
                                            <i className='bx bx-first-page'></i>
                                        </button>
                                        <button onClick={goToPreviousPage} disabled={currentPage === 1}>
                                            <i className='bx bx-chevron-left'></i>
                                        </button>
                                        <button onClick={goToNextPage} disabled={currentPage === totalPages}>
                                            <i className='bx bx-chevron-right'></i>
                                        </button>
                                        <button onClick={goToLastPage} disabled={currentPage === totalPages}>
                                            <i className='bx bx-last-page'></i>
                                        </button>
                                    </div>
                                )}
                            </div>
                        }
                    </div> }
                   

                    {showCard && showDoctor && 
                        <div className='row'>
                            <div className='col-md-12'>
                            {!props.rescheduleCall && <hr className='mt-2 mb-4'/> }
                            </div>
                            
                            {!props.rescheduleCall && <div className='col-md-6'>
                            {
                                doctors?.map((item, index) => {
                                    return <>
                                        {item.doctorId == selectedDoctor &&
                                        <div className='col-md-12' key={index}>
                                            <div className="profile-box-n1 mb-2">
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <h5 className='profile-card-title'><span className='bold'>{item.firstNameEn} {item.lastNameEn}</span></h5>
                                                    </div>
                                                    <div className='col-4'>
                                                        <h6 className='profile-card-title' style={{fontSize:"14px"}}><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/5shux/73lal8g48h.png" style={{height:"16px"}} alt=""/> <span className=''>{item.gender}</span></h6>
                                                    </div>
                                                    <div className='col-4'>
                                                        <h6 className='profile-card-title' style={{fontSize:"14px"}}><i class="bi bi-geo-alt-fill"></i> <span className=''>{item.location}</span></h6>
                                                    </div>
                                                    <div className='col-4'>
                                                        <h6 className='profile-card-title' style={{fontSize:"14px"}}><i class="bi bi-briefcase-fill"></i> <span className=''>{item.experience}</span></h6>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-6'>
                                                            <h6 className='profile-card-title' style={{fontSize:"14px"}}><i class="bi bi-award-fill"></i> <span className=''>{item.position}</span></h6>
                                                        </div>
                                                        <div className='col-6'>
                                                            {item.speciality && <h6 className='profile-card-title' style={{fontSize:"14px"}}><i class="bi bi-clipboard2-plus"></i> <span className='pe-2'>{item.speciality.name}</span></h6> }
                                                        </div>
                                                    </div>
                                                    <div className='col-12'>
                                                        <h6 className='profile-card-title' style={{fontSize:"14px"}}><i class="bi bi-translate"></i> <span className=''>{item.languages}</span></h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> }

                                    </>
                                })
                            }
                            </div>}


                            {props.rescheduleCall && <div className='col-md-6'>
                            {
                                <div className='col-md-12'>
                                    <div className="profile-box-n1 mb-2">
                                        <div className='row'>
                                            <div className='col-12'>
                                                <h5 className='profile-card-title'><span className='bold'>{props.doctorName}</span></h5>
                                            </div>
                                        </div>
                                    </div>
                                </div> 

                            }
                            </div>}
                           
                            <div className='col-md-6'>
                            {showCard && <div className="date-picker-wrapper" style={{ position: 'relative' }}>
                                <DatePicker
                                    selectsRange={false}
                                    selected={appointmentDate}
                                    onChange={(date) => setAppointmentDate(date)}
                                    showTimeSelect
                                    timeFormat="h:mm aa"
                                    timeIntervals={15}
                                    dateFormat="dd MMMM yyyy, h:mm aa"
                                    minDate={new Date()}
                                    minTime={isToday(appointmentDate) ? minSelectableTime : new Date(new Date().setHours(0, 0, 0, 0))}
                                    maxTime={new Date(new Date().setHours(23, 59, 59))}
                                    placeholderText="Select Date and Time"
                                    className="form-control input-field-ui"
                                    isClearable={false}
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                />
                                <i className='bx bx-calendar me-2'
                                    style={{
                                        position: 'absolute',
                                        right: '10px',
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                        pointerEvents: 'none',
                                        fontSize: '20px',
                                        color: '#666'
                                    }}>
                                </i>
                            </div> }
                            <div className='row'>
                                <div className='col-12'>
                                    <div className={`${errorNew != "" ? "errorDiv" : "hide"}`} style={{ fontWeight: "400 !important" }}>{errorNew}</div>
                                </div>
                            </div>
                            {showCard && <div class="text-right">
                                <button type="button" class="btn btn-dark coach-call-enroll-btn me-2 mt-3" onClick={(e) => slotFetchSelection()}>Fetch Slots</button>
                            </div> }
                            </div>
                        </div>
                    }

                    {loadingSlots && <div><img src='https://storage.googleapis.com/ksabupatest/2024/09/24/48lld/8hx4bl7sy4.gif' style={{width:'100px'}}></img></div>}

                    {slotsData.length > 0 && <div class="col-12 mt-1">
                        <div class="col-md-12">
                            <label class="col-form-label">
                                Slots Available<span className='text-danger'> *</span>
                            </label>
                            <div class="d-flex flex-wrap">
                                {
                                    
                                    slotsData?.map((item, index) => {
                                        return <>
                                            <div key={index} className={`col-auto mb-2 time_slot_book ${
                                                    item.slotId === selectedSlot
                                                      ? "active"
                                                      : ""
                                                  }`} onClick={() => {
                                                    setNewSlots(item.slotId,item.time);
                                                  }}>
                                                {item.time}
                                            </div>
                                        </>
                                    })
                                }
                            </div>
                            {errorSlot && <div className='row'>
                                <div className='col-12'>
                                    <div className={`${errorSlot != "" ? "errorDiv" : "hide"}`} style={{ fontWeight: "400 !important" }}>{errorSlot}</div>
                                </div>
                            </div> }
                            <div class="mt-1">
                                <label class="col-form-label">
                                    Consultation Notes
                                </label>
                                <textarea type="text" placeholder="Consultation Notes" rows="3" className='form-control control-input-ui' onChange={(e) => setBookingNotes(e.target.value)} value={bookingNotes}/>
                            </div>
                            <div class="text-center">
                                <button type="button" class="btn btn-dark coach-call-enroll-btn me-2 mt-3" onClick={(e) => bookAppointment()}>Book</button>
                            </div>
                            
                        </div>
                    </div> }
                    { showLoader ? <CustomLoaderTele /> : null }
                    
                </Modal.Body>
            </Modal>
        </>
    )
}

export default TeleHealthBook