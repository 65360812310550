import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from "axios";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { fetchCalendarCalls, fetchEmptyCalendarSlots, fetchUsersList, scheduleCarenavigatorCall } from "../components/services/Service";
import './AppointmentSchedule.css';
import moment from 'moment';
import { event } from 'jquery';
import { LoaderLoader } from '../../utils/CommonLibrary';
// Import your images
// import callIcon from '../../../images/cn/icon-calendar-call.png';
// import doctorCallIcon from '../../../images/cn/icon-calendar-doctor.png';

const callIcon = 'https://storage.googleapis.com/ksabupatest/2024/09/24/f4g47/83ns44rj8s.png';
const doctorCallIcon = 'https://storage.googleapis.com/ksabupatest/2024/09/24/x8jsw/ms74sjr8ta.png';

const AppointmentSchedule = ({ onUserClick }) => {
  const [loading, setLoading] = useState(false);
  const calendarRef = useRef(null);
  const [events, setEvents] = useState([]);
  const [calendarCalls, setCalendarCalls] = useState([]);
  const [emptySlots, setEmptySlots] = useState([]);
  const selectedUserObject = { userId: "", name: "" };
  const [selectedUser, setSelectedUser] = useState(selectedUserObject);
  const [users, setUsers] = useState([]);
  const [searchStr, setSearchStr] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [error, setError] = useState("");
  const [currentDateRange, setCurrentDateRange] = useState({ start: null, end: null });
  const [showScheduleCallModal, setShowScheduleCallModal] = useState(false);


  const [details, setDetails] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);


  const today = new Date();
  const nextMonth = new Date(today.getFullYear(), today.getMonth() + 2, 0);
  const validEndDate = new Date(nextMonth);
  validEndDate.setDate(validEndDate.getDate() + 2);

  //===================

  let selectedViewType = localStorage.getItem('CNCalendarViewType');
  if (selectedViewType === "" || selectedViewType === null) selectedViewType = "dayGridMonth";

  //===================

  useEffect(() => {
    getCalendarCalls();
  }, []);

  useEffect(() => {
    const mergedEvents = [...calendarCalls, ...emptySlots];
    setEvents(mergedEvents);
  }, [calendarCalls, emptySlots])

  const handleDatesSet = (arg) => {
    setError("");

    const viewType = arg.view.type;
    const startTime = arg.start;
    const endTime = arg.end;
    localStorage.setItem('CNCalendarViewType', viewType);
    if (viewType == 'timeGridDay') {
      const formattedStartTime = moment(startTime).format('YYYY-MM-DD');
      const formattedEndTime = moment(endTime).format('YYYY-MM-DD');
      const formattedCurrentStart = currentDateRange.start ? moment(currentDateRange.start).format('YYYY-MM-DD') : null;
      const formattedCurrentEnd = currentDateRange.end ? moment(currentDateRange.end).format('YYYY-MM-DD') : null;

      if (formattedStartTime !== formattedCurrentStart || formattedEndTime !== formattedCurrentEnd) {
        getEmptyCalendarSlots(formattedStartTime)
        setCurrentDateRange({ start: startTime, end: endTime });
      }

    }
  };

  const getEmptyCalendarSlots = async (date) => {
    const request = axios.CancelToken.source();
    let ct = {
      cancelToken: request.token
    };
    await fetchEmptyCalendarSlots(date, ct)
      .then((response) => {
        if (response.data.code === 200) {
          const calendarEmptySlots = response.data.data.slots;

          /*if (events.length > 0) {
            console.log("Empty 1 = ", JSON.stringify(events), JSON.stringify(emptySlots));
            const mergedEvents = [...events, ...emptySlots];
            console.log("empty 2 = ", JSON.stringify(mergedEvents));
            setEvents(mergedEvents)
          } else {
            setEvents(emptySlots)
          }*/
          setEmptySlots(calendarEmptySlots);
        }
      }).catch(function (error) {
        console.log(error);
      });
    return () => request.cancel();
  };

  const getCalendarCalls = async (dt) => {
    const request = axios.CancelToken.source();
    let ct = {
      cancelToken: request.token
    };
    await fetchCalendarCalls(ct)
      .then((response) => {
        if (response.data.code === 200) {
          const calls = response.data.data.calls
          /*if (events.length > 0) {
            console.log("1 = ", JSON.stringify(events), JSON.stringify(calls));
            const mergedEvents = [...events, ...calls];
            console.log("2 = ", JSON.stringify(mergedEvents));
            setEvents(mergedEvents);
          } else {
            setEvents(calls);
          }*/
          setCalendarCalls(calls);
          if (dt !== undefined) {
            getEmptyCalendarSlots(dt);
          }
        }
      }).catch(function (error) {
        console.log(error);
      });
    return () => request.cancel();
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'DoctorCall':
        return doctorCallIcon;
      case 'Cancelled':
        return callIcon;
      case 'Scheduled':
        return callIcon;
      case 'Completed':
        return callIcon;
      case 'Missed':
        return callIcon;
      default:
        return null; // Default icon or none
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'DoctorCall':
        return '#D9EBFF';
      case 'Cancelled':
        return '#F76E66';
      case 'Scheduled':
        return '#1b75bc';
      case 'Completed':
        return '#84bd93';
      case 'In-progress':
        return '#3BA947';
      case 'Unsuccessful':
        return '#F76E66';
      case 'Missed':
        return '#FBC525';
      default:
        return '#C0C0C0'; // Default color
    }
  };

  const renderEventContent = (eventInfo) => {
    const status = eventInfo.event.extendedProps.status;
    const icon = getStatusIcon(status);
    const backgroundColor = getStatusColor(status);
    let textColor = 'white'; // Default text color for most statuses

    if (status === 'DoctorCall') {
      textColor = '#333'; // Change text color to black for 'DoctorCall' status
      return (
        <div style={{ backgroundColor, color: textColor }} className='calendar-label-box'>
          {eventInfo.event.title}
          <img src={icon} alt="Status Icon" style={{ marginLeft: '5px', height: '18px' }} />
        </div>
      );
    }

    return (
      <div style={{ backgroundColor, color: textColor }} className='calendar-label-box'>
        {
          (eventInfo.event.extendedProps.attruid) ?
            <>
              <div className='cal-position'>
              <Link to={'/members/' + encodeURIComponent(eventInfo.event.extendedProps.attruid, "UTF-8")} className='name' onClick={() => onUserClick({ userId: eventInfo.event.extendedProps.attruid, name: eventInfo.event.title })}>
                <b className="icon-phone-schd"><label className='timeText'>{eventInfo.timeText}</label> <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/f4g47/83ns44rj8s.png" alt="Status Icon" style={{ marginLeft: '5px', height: '14px' }} /></b>
                <span className='mx-0'>{eventInfo.event.title}</span>
              </Link>
              <i style={{ marginLeft: '10px', marginTop:"2px", fontSize:"13px" }} className="fa fa-info-circle followup-current" aria-hidden="true" onClick={() => {
                handleDetailsModal(eventInfo.event.extendedProps.callsdetails)
              }}></i>
              </div>
            </>
            :
            <b className="icon-phone-schd"><label className='timeText'>{eventInfo.timeText}</label> <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/f4g47/83ns44rj8s.png" alt="Status Icon" style={{ marginLeft: '5px', height: '14px' }} /></b>
        }

      </div>

    );
  };

  const handleDateClick = (arg) => {
    if (arg.view.type == 'timeGridDay') {
      setSelectedDate(arg.dateStr);
      setUsers([]);
      setError("");
      setSelectedUser(selectedUserObject);
      setSearchStr("");
      setShowScheduleCallModal(true);
    }
  };

  const handleSearch = (e) => {
    setError("");
    setSearchStr(e.target.value);
    if (e.target.value != "") {
      getUsersList(e.target.value);
    } else {
      setUsers([])
    }
  }

  const getUsersList = async (searchStr) => {
    const request = axios.CancelToken.source();
    let ct = {
      cancelToken: request.token
    }
    await fetchUsersList({}, searchStr, 1, ct)
      .then((response) => {
        if (response.data.data.users)
          setUsers(response.data.data.users);
      }).catch(function (error) {
        console.log(error);
      });
    return () => request.cancel();
  }

  const handleSuggestionClick = (suggestion) => {
    setError("");
    setSelectedUser({ userId: suggestion.userId, name: suggestion.memberName });
    setSearchStr(suggestion.memberName);
    setUsers([]);
  };

  const scheduleCall = async () => {
    setError("");
    if (selectedUser.userId == "") {
      setError("Please select a member.");
    } else if (selectedDate == null) {
      setError("Please select the date and time.");
    } else {
      setLoading(true);
      const request = axios.CancelToken.source();
      let ct = {
        cancelToken: request.token
      }
      await scheduleCarenavigatorCall(selectedUser.userId, moment(selectedDate).format('YYYY-MM-DD HH:mm:ss'), ct)
        .then((response) => {
          if (response.data.code === 200) {
            setSelectedUser(selectedUserObject);
            setShowScheduleCallModal(false);
            setTimeout(() => {
              getCalendarCalls(moment(selectedDate).format('YYYY-MM-DD'));
              setLoading(false);
            }, 2000);

            /*setTimeout(() => {
              getEmptyCalendarSlots(moment(selectedDate).format('YYYY-MM-DD'));
            }, 1000);*/

            setSelectedDate(null);
          } else {
            setLoading(false);
            setError(response.data.data.message);
          }
        }).catch(function (error) {
          console.log(error);
        });
      return () => request.cancel();
    }
  }

  const handleDetailsModal = async (item) => {
    setDetails(item);
    setShowDetailModal(true);
  }

  return (
    <>
      <LoaderLoader isLoading={loading} />
      <div className="calendar-container mt-3">
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay',
          }}
          initialView={selectedViewType}
          editable={false}
          eventStartEditable={false}
          eventDurationEditable={false}
          selectable={false}
          selectMirror={true}
          dayMaxEvents={true}
          weekends={true}
          events={events}
          height="1150px"
          slotDuration="00:15:00"
          eventContent={renderEventContent}
          dateClick={handleDateClick}
          datesSet={handleDatesSet}
        /*validRange={{
          end: validEndDate.toISOString().split('T')[0],
        }}*/
        />
      </div>
      <Modal id="appointmentSchedule" show={showScheduleCallModal} onHide={() => setShowScheduleCallModal(false)} centered size="" backdrop="static" keyboard={false} fullscreen={false}>
        <Modal.Header closeButton>
          <h1 className='modal-title'>Schedule Call - {moment(selectedDate).format('DD MMM, YYYY [at] h:mm a')}</h1>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 mb-3">
              <label className="form-label">Name</label>
              <input type="text" name="name" value={searchStr} className='form-control control-input-ui' onChange={handleSearch} />
              {
                users.length > 0 &&
                <ul className="suggestions-list">
                  {users.map((item) => (
                    <li
                      key={item.userId}
                      onClick={() => handleSuggestionClick(item)}
                    >
                      {item.memberName}
                    </li>
                  ))}
                </ul>
              }
            </div>
            <div className="col-12 mt-2">
              <div className={`${error != "" ? "errorDiv" : "hide"}`}>{error}</div>
              <button type="button" className="btn btn-primary save-btn" onClick={() => scheduleCall()}>Save</button>
            </div>
          </div>

        </Modal.Body>
      </Modal>

      <Modal id="CallDetailsModal" show={showDetailModal} centered onHide={() => setShowDetailModal(false)} size="xl" backdrop="static" keyboard={false} fullscreen={false}>
        <Modal.Header closeButton>
          <h1 className='modal-title' style={{ width: "100%" }}>Call Details</h1>
        </Modal.Header>
        <Modal.Body>
          <>
            {
              <div>
                <Row>
                  <Col className='mb-3'>
                    <label>Date:</label><br />
                    <span  style={{ fontWeight: 'bold' }}>{details.date}</span>
                  </Col>
                  <Col className='mb-3'>
                    <label>Call Scheduled By:</label><br />
                    <span style={{ fontWeight: 'bold' }}>{details.scheduledByName}</span>
                  </Col>
                  <Col className='mb-3'>
                    <label>Call By:</label><br />
                    <span style={{ fontWeight: 'bold' }}>{details.cnName}</span>
                  </Col>
                </Row>
                <Row>
                  <Col className='mb-3'>
                    <label>Call Type:</label><br />
                    <span style={{ fontWeight: 'bold' }}>{details.callType}</span>
                  </Col>
                  <Col className='mb-3'>
                    <label>Call Sub-Type:</label><br />
                    <span style={{ fontWeight: 'bold' }}>{details.callSubType}</span>
                  </Col>
                  <Col className='mb-3'>
                    <label>Call Status:</label><br />
                    <span style={{ fontWeight: 'bold' }}>{details.callCurrentStatus}</span>
                  </Col>
                </Row>
                <Row>
                  <Col className='mb-3'>
                    <label>Internal Notes:</label><br />
                    <span style={{ fontWeight: 'bold' }}>{details.internalNotes}</span>
                  </Col>
                  <Col className='mb-3'>
                    <label>External Notes:</label><br />
                    <span style={{ fontWeight: 'bold' }}>{details.externalNotes}</span>
                  </Col>
                  <Col className='mb-3'>
                  </Col>
                </Row>
              </div>
            }
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AppointmentSchedule;
