import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import { searchMedicationDrugsMetaDoc } from "../services/Service";


const MedicationsList = (props) => {
    const c = props.c;
    const listType = props.listType;
    const data = props.data;
    const removeComponent = props.removeComponent;
    const handleFieldChange = props.handleFieldChange;
    const handleSuggestionClick = props.handleSuggestionClick;
    const [drugs, setDrugs] = useState([]);
    const [searchStr, setSearchStr] = useState("");
    const [showSearchBox, setShowSearchBox] = useState(false);
    const searchBoxRef = useRef(null);

    const handleFocus = () => {
        setShowSearchBox(true);
    };

    const handleBlur = (e) => {
        if (searchBoxRef.current && searchBoxRef.current.contains(e.relatedTarget)) {
            return;
        }
        setShowSearchBox(false);
    };

    useEffect(() => {
        // getAssessmentCategories();
    }, []);

    const handleSearch = (e) => {
        setSearchStr(e.target.value);
        getMedicationDrugs(e.target.value);
    }


    const getMedicationDrugs = async (searchStr) => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await searchMedicationDrugsMetaDoc(searchStr, ct)
            .then((response) => {
                if (response.data.data.drugs)
                    setDrugs(response.data.data.drugs);
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const handleSuggestionDrugClick = (suggestion) => {
        handleSuggestionClick(suggestion, c)
        setSearchStr("");
        setShowSearchBox(false);
        setDrugs([]);
    };

    const addHandleChange = (e) => {
        handleFieldChange(e, c, listType);
    }


    return (
        <>
            <tr style={{ verticalAlign: "middle" }}>
                <td>
                    <input type="text" readOnly={true} className="form-control control-input-ui" id="productName" name="productName" style={{ width: "auto !important" }} placeholder="Medicine Name" value={data.productName ? data.productName : ""} onFocus={handleFocus}
                        onBlur={handleBlur}
                    />
                    {showSearchBox > 0 && (

                        < div ref={searchBoxRef} style={{
                            position: 'absolute',
                            left: '2%',
                            border: '1px solid #ccc',
                            backgroundColor: '#fff',
                            zIndex: 1,
                            padding: '10px',
                            borderRadius: '10px',
                            width: '30%',
                            maxHeight: '200px',
                            overflowY:'auto'
                        }}
                        >
                            <label>Search:</label>
                            <input className='form-control control-input-ui' type="text" name="searchStr" value={searchStr} onChange={handleSearch} />
                            <ul className="suggestions-list">
                                {drugs.map((item) => (
                                    <li
                                        key={item.productId}
                                        onClick={() => handleSuggestionDrugClick(item)}
                                    >
                                        {item.productName}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </td>
                <td>
                    <input type="text" className="form-control control-input-ui" id="quantity" name="quantity" style={{ width: "auto !important" }} placeholder="Quantity" value={data.quantity} onChange={addHandleChange} />
                </td>
                <td>
                    <input type="text" className="form-control control-input-ui" id="comments" style={{ width: "auto !important" }} placeholder="Comments" name="comments" value={data.comments} onChange={addHandleChange} />
                </td>
                <td>
                    <button type="button" className="btn-close cross-btn-close" aria-label="Close" onClick={() => removeComponent(c, listType)}></button>
                </td>
            </tr >
        </>
    )
}

export default MedicationsList