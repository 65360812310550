import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from "axios";
import { useUserTabs } from '../components/common/UserTabsContext';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import alertify from 'alertifyjs';
import Button from 'react-bootstrap/Button';
import HealthManagement from '../components/healthmanagement/HealthManagement';
import CarePlanChange from '../components/careplanchange/CarePlanChange';
import './Members.css'
import TopBar from '../components/topbar/TopBar';
import Chat from '../components/chat/Chat';
import { Modal } from 'react-bootstrap';
import {
    fetchUserProfile, fetchPathways, assignPathway, addWeight, disEnrollRequest, fetchMemberServices, bookMemberServices, updateMemberServicesStatus, cn_call_disposition, fetchServiceCategories, fetchServiceByCategory, addMemberAdditionalServices, data_disposition_list, updateAltMobile, fetchDisenrollmentReasons, fetchProgramConditionPathway, addNewProgram, stoppingPathway, restartingPathway, removePathway, updateMemberFromEdge
} from "../components/services/Service";
import FormsModal from '../components/FormsModal/FormsModal';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Column from '../components/graphs/Column';
import Line from '../components/graphs/Line';
import AddMember from '../components/addmember/AddMember';
import MemberServices from '../components/memberservices/MemberServices';
import HealthLocker from '../components/healthLocker/HealthLocker';
import PathwayChangeLogs from '../components/pathway/PathwayChangeLogs';
import EventHistory from '../components/memberservices/EventHistory';
import HomeBasedServicesHistory from '../components/memberservices/HomeBasedServicesHistory';
import MemberCalls from '../components/memberservices/MemberCalls';
import MemberCallHistory from '../components/memberservices/MemberCallHistory';
import MemberNotes from '../components/memberservices/MemberNotes';
import { useSelector } from 'react-redux';
import { Commingsoon } from '../../cn/components/common/Utils';
import { encryptDataEdge, LoaderLoaderConnectNew, ModalBoxButton } from '../../utils/CommonLibrary';
import TeleHealthBook from '../components/TeleHealthBook/TeleHealthBook';
import UploadTeleHealthBook from '../components/TeleHealthBook/UploadTeleHealthBook';
import { LoaderLoaderConnect } from '../../utils/CommonLibrary';
import FormsModalMetaDoc from '../components/FormsModal/FormsModalMetaDoc';
import MetricsAdd from '../components/memberservices/MetricsAdd';
import MetricsInsights from '../components/memberservices/MetricsInsights';
import HealthMetrics from './HealthMetrics';
import EducationContent from '../components/EducationContent/EducationContent';


const Members = ({ openOutGoingCallWindow, showDisposition, setShowDisposition, showCallBlock }) => {

    const webex = useSelector(state => state.webex);

    const qsParams = useParams();
    const userId = qsParams.memberId;

    const navigate = useNavigate();

    const [refreshVar, setRefreshVar] = useState(new Date().getTime());

    const MemberServicesRef = useRef();
    const HomeBasedServicesHistoryRef = useRef();
    const MemberChatRef = useRef();
    const MemberCallHistoryRef = useRef();
    const EventHistoryRef = useRef();
    const PathwayChangeLogsRef = useRef();

    //Stop/Restart pathway 
    const restartStopObj = {
        pathwayId: "",
        pathwayRelId: "",
        conditionName: "",
    }
    const [showStopModal, setShowStopModal] = useState(false);
    const [showRestartModal, setShowRestartModal] = useState(false);
    const [selectedRestartStopPathwayDetails, setSelectedRestartStopPathwayDetails] = useState(restartStopObj);
    const [totalActivePathways, setTotalActivePathways] = useState(0);
    const [restartRemarks, setRestartRemarks] = useState("");
    const [stopRemarks, setStopRemarks] = useState("");
    const [restartError, setRestartError] = useState("");
    const [stopError, setStopError] = useState("");
    const [restartMessage, setRestartMessage] = useState("");
    const [stopMessage, setStopMessage] = useState("");
    const [firebaseNode, setFireBaseNode] = useState("")
    //=====================

    const [metricsArr, setMetricsArr] = useState({});
    const [showMetricsModal, setShowMetricsModal] = useState(false);

    const [metricsInsightsArr, setMetricsInsightsArr] = useState({});
    const [showMetricsInsightsModal, setShowMetricsInsightsModal] = useState(false);


    //const [sharedMemberServices, setSharedMemberServices] = useState([]);

    const { userTabs, addUserTab, closeUserTab } = useUserTabs();
    const handleUserClick = (user) => {
        addUserTab(user);
    };
    const openFamilyMemberProfile = (e) => {
        const userId = e.target.value;
        const memberName = e.target.options[e.target.selectedIndex].text;

        if (userId !== "" && memberName !== "") {
            handleUserClick({ userId: userId, name: memberName });
            navigate('/members/' + encodeURIComponent(userId, "UTF-8"));
        }

    }

    const [showAddServicesModal, setShowAddServicesModal] = useState(false);
    const handleShowServicesModal = () => {
        setShowAddServicesModal(true);
    }

    const [memberBasicInfo, setMemberBasicInfo] = useState([]);
    const [memberPathways, setMemberPathways] = useState([]);
    const [pathways, setPathways] = useState([]);
    const [pwUpdateMessage, setPWUpdateMessage] = useState("");
    const [pwUpdateError, setPWUpdateError] = useState("");
    const [showPWUpdateForm, setShowPWUpdateForm] = useState(false);
    const [showSyncButton, setShowSyncButton] = useState(true);

    const [weightUpdateMessage, setWeightUpdateMessage] = useState("");
    const [weightUpdateError, setWeightUpdateError] = useState("");
    const [weight, setWeight] = useState("");
    const [height, setHeight] = useState("");
    const [bmi, setBMI] = useState("");
    const [bmiObservation, setBMIObservation] = useState("");

    const [disEnrollmentMessage, setDisEnrollmentMessage] = useState("");
    const [disEnrollmentError, setDisEnrollmentError] = useState("");
    const [disEnrollmentNote, setDisEnrollmentNote] = useState("");
    const [disEnrollmentReason, setDisEnrollmentReason] = useState("");
    const [showModal, setShowModal] = useState(false);

    const selectedRecordObject = { recordId: "", serviceName: "", serviceType: "", serviceRequestType: "" }
    const [selectedBulkServices, setSelectedBulkServices] = useState([]);
    const [selectedServiceRecord, setSelectedServiceRecord] = useState(selectedRecordObject);

    const [showAltMobileModal, setShowAltMobileModal] = useState(false);
    const [alternateMobileNumber, setAlternateMobileNumber] = useState("");
    const [altMobileUpdateError, setAltMobileUpdateError] = useState("");
    const [altMobileUpdateMessage, setAltMobileUpdateMessage] = useState("");

    const [showModalIframe, setShowModalIframe] = useState(false);
    const [modalBodyIframe, setModalBodyIframe] = useState(false);
    const [iframeUrl, setIframeUrl] = useState("");
    const [rescheduleCall, setRescheduleCall] = useState('');
    const [doctorName, setDoctorName] = useState('');
    const [errrorMsg, setErrrorMsg] = useState('');
    const [refreshPage, setRefreshPage] = useState(0);


    const [bookingId, setBookingId] = useState('');
    const [teleCallId, setTeleCallId] = useState('');
    const [showMedicationMetaDocModal, setShowMedicationMetaDocModal] = useState('');


    const [disenrollmentReasons, setDisenrollmentReasons] = useState([]);

    const handleConfirmConditionRemove = (conditionName, pathwayRelId) => {
        console.log(pathwayRelId);
        alertify.confirm(`Confirm Removal`, `Are you sure you want to delete condition ${conditionName}?`, () => {
            deletePathway(pathwayRelId);
        }, () => {
        });
    }

    useEffect(() => {
        if (weight !== "" && height !== "") {
            //const heightInInches = height / 2.54;
            //let BMI = ((weight * 703) / (heightInInches * heightInInches));
            const heightInMeters = height / 100;
            let BMI = (weight / (heightInMeters * heightInMeters));

            BMI = parseFloat(BMI).toFixed(1)
            let observation = (BMI < 18.5) ? "Underweight" : (BMI >= 18.5 && BMI <= 24.9) ? "Healthy" : (BMI >= 25 && BMI <= 29.9) ? "Overweight" : "Obese";

            setBMI(BMI);
            setBMIObservation(observation);
        } else {
            setBMI("");
            setBMIObservation("");
        }
    }, [weight, height])



    const pathwayObject = {
        programId: "",
        conditionId: "",
        oldPathwayId: "",
        pathwayId: "0",
        notes: "",
        hba1c: "",
        hba1cDate: ""
    }
    const [pathwayFormData, setPathwayFormData] = useState(pathwayObject);

    /*const addServiceObject = {
        pathwayId: (memberBasicInfo.memberPathways) ? memberBasicInfo.memberPathways[0].pathwayId : "",
        categoryId: '',
        serviceId: '',
        date: new Date(),
        time: null,
        notes: ""
    }
    const [addServiceData, setAddServiceData] = useState(addServiceObject);
    const [categories, setCategories] = useState([]);
    const [services, setServices] = useState([]);
    const [addServiceError, setAddServiceError] = useState("");
    const [addServiceMessage, setAddServiceMessage] = useState("");
    
    const now = new Date();
    const isTodayAS = addServiceData.date instanceof Date && (addServiceData.date).toDateString() === now.toDateString();
    const minTimeAS = isTodayAS ? now : new Date().setHours(0, 0, 0, 0);*/



    useEffect(() => {
        getMemberBasicInfo();
        getDispositionReasonsList();
    }, [userId, refreshPage]);



    const [modalTitle, setModalTitle] = useState(false);
    const [formShort, setFormShort] = useState('');

    const [showHRAModal, setShowHRAModal] = useState(false);
    const [showTelehealthModal, setShowTelehealthModal] = useState(false);
    const [showEducationModal, setShowEducationModal] = useState(false);
    
    const [showUploadTelehealthModal, setShowUploadTelehealthModal] = useState(false);


    const [teleDisable, setTeleDisable] = useState(false);
    const [showSmokingHRAModal, setShowSmokingHRAModal] = useState(false);
    const [showMedicationModal, setShowMedicationModal] = useState(false);

    const [errorEdge, setErrorEdge] = useState('');
    const [messageEdge, setMessageEdge] = useState('');

    const handleCloseHRAModal = () => setShowHRAModal(false);
    const handleCloseSmokingHRAModal = () => setShowSmokingHRAModal(false);
    const handleShowHRAModal = () => setShowHRAModal(true);

    const handleShowSmokingHRAModal = () => setShowSmokingHRAModal(true);

    const handleShowTeleHealth = () => {
        setShowTelehealthModal(true);
        setRescheduleCall('');
        setDoctorName('');
        setBookingId('');
        setTeleCallId('');
    }
    const handleShowTeleHealthReschedule = (bookingId, callId, doctorId, doctorName) => {
        setShowTelehealthModal(true);
        setRescheduleCall(doctorId);
        setDoctorName(doctorName);
        setBookingId(bookingId);
        setTeleCallId(callId);
    }

    const handleShowUploadTeleHealthReschedule = (bookingId, callId, errrorMsg) => {
        setShowUploadTelehealthModal(true);
        setBookingId(bookingId);
        setTeleCallId(callId);
        setErrrorMsg(errrorMsg);

    }

    const handleShowEducationContent = () => {
        setShowEducationModal(true);
    }

    const handleCloseMedicationModal = () => {
        setShowMedicationModal(false);
        setSelectedServiceRecord(selectedRecordObject);
        callMemberServicesMonthCategoryWiseFunc();
    }

    const handleCloseMedicationMetaDocModal = () => {
        setShowMedicationMetaDocModal(false);
        setSelectedServiceRecord(selectedRecordObject);
        callMemberServicesMonthCategoryWiseFunc();
    }

    const handleShowMedicationModal = (header, formShortName) => {
        setSelectedServiceRecord(selectedRecordObject);
        setModalTitle(header);
        setFormShort(formShortName);
        setShowMedicationModal(true);
    }

    const handleShowMetaDocModal = (header) => {
        setSelectedServiceRecord(selectedRecordObject);
        setModalTitle(header);
        setModalTitle(header)
        setShowMedicationMetaDocModal(true);
    }

    const handleShowMedicationMetaDocModal = (header) => {
        setSelectedServiceRecord(selectedRecordObject);
        setModalTitle(header)
        setShowMedicationMetaDocModal(true);
    }

    const handleMemberBulkHomeCareRequest = (bulkServices, header) => {
        setSelectedBulkServices(bulkServices);
        setModalTitle(header)
        setShowMedicationModal(true);
    }

    const handleShowMedicationModalServiceWise = (header) => {
        setModalTitle(header)
        setShowMedicationModal(true);
    }


    const [modalPathwayName, setModalPathwayName] = useState(false);
    const [showCarePlanModal, setShowCarePlanModal] = useState(false);
    const handleCloseCarePlanModal = () => setShowCarePlanModal(false);
    const handleShowCarePlanModal = (pathwayName) => {
        setShowCarePlanModal(true);
        setModalPathwayName(pathwayName)
    }

    const [showAddWeightModal, setShowAddWeightModal] = useState(false);
    const handleShowWeightModal = () => {
        setWeight("");
        setHeight("");
        setWeightUpdateError("");
        setWeightUpdateMessage("");
        setShowAddWeightModal(true);
    }

    const [showHealthMetricsPopUpModal, setShowHealthMetricsPopUpModal] = useState(false);
    const handleShowHealthMetricsPopUpModal = () => {
        setShowHealthMetricsPopUpModal(true);
    }


    const [showDisEnrollmentModal, setShowDisEnrollmentModal] = useState(false);
    const handleShowDisEnrollmentModal = () => {
        if (memberBasicInfo.memberPathways.length > 0) {
            getDisenrollmentReasons(memberBasicInfo.memberPathways[0]['programId'], memberBasicInfo.memberPathways[0]['conditionId']);
        }
        setDisEnrollmentNote("");
        setDisEnrollmentError("");
        setShowDisEnrollmentModal(true);
    }

    const [showNotesModal, setShowNotesModal] = useState(false);
    /*const selectedRecordObject = { recordId: "", serviceName: "", serviceType: "" }
    const bookingFormObject = {
        date: new Date(),
        time: "",
        notes: ""
    }
    
    const bookingStatusUpdateObject = {
        status: "",
        notes: "",
        files: [{
            id: new Date(),
            title: "",
            file: null,
            originalName: ""
        }]
    }
    const [bookingFormData, setBookingFormData] = useState(bookingFormObject);
    const [bookingStatusUpdateFormData, setBookingStatusUpdateFormData] = useState(bookingStatusUpdateObject);
    
    const [selectedServiceRecord, setSelectedServiceRecord] = useState(selectedRecordObject);
    const [showBookMemberServiceModal, setShowBookMemberServiceModal] = useState(false);
    const [showUpdateMemberServiceModal, setShowUpdateMemberServiceModal] = useState(false);
    const [bookingMessage, setBookingMessage] = useState("");
    const [bookingError, setBookingError] = useState("");
    
    const [msUpdateMessage, setMsUpdateMessage] = useState("");
    const [msUpdateError, setMsUpdateError] = useState("");*/

    // const [showDisposition, setShowDisposition] = useState(false);

    const [requestStatus, setRequestStatus] = useState({
        isDispositionFormLoading: false
    });

    const [dispositionForm, setDispositionForm] = useState({
        callStatus: "",
        notes: "",
        callbackDateAndTime: ""
    });

    const [dispositionErrors, setDispositionErrors] = useState({
        callStatus: "",
        notes: "",
        preferredChannel: "",
        prefferedTime: {
            from: "",
            to: ""
        },
        callbackDateAndTime: ""
    });

    const [dispositionSubmissionMessage, setDispositionSubmissionMessage] = useState({
        status: false,
        message: ""
    });

    const [confNumber, setConfNumber] = useState(null);


    const [dispositionReasons, setDispositionReasons] = useState([]);


    const getMemberBasicInfo = async () => {
        setTeleDisable(true);
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchUserProfile(userId, '', ct)
            .then((response) => {
                if (response.data.code === 200) {
                    console.log(response.data.data.info);
                    setMemberBasicInfo(response.data.data.info);
                    setMemberPathways(response.data.data.info.memberPathways);

                    console.log("checking", response.data.data.info.k);
                    console.log("checking", response.data.data.info.membershipNumber);

                    const memberNo = encryptDataEdge(response.data.data.info.membershipNumber, response.data.data.info.k);
                    const isAramco = encryptDataEdge(response.data.data.info.isAramco, response.data.data.info.k);
                    const appSource = encryptDataEdge(response.data.data.info.appSource, response.data.data.info.k);
                    const iframeUser = encryptDataEdge(response.data.data.info.iframeUser, response.data.data.info.k);

                    setFireBaseNode(response.data.data.info.firbasenode);

                    const iframeUrlTemp = response.data.data.info.iframeUrl;
                    const frameUrl = `${iframeUrlTemp}memberNo=${memberNo};isAramco=${isAramco};appSource=${appSource};user=${iframeUser}`;

                    setIframeUrl(frameUrl);
                    setTotalActivePathways(response.data.data.info.memberPathways.filter(
                        pathway => pathway.pathwayStatus === 'active' || pathway.pathwayStatus === 'notset'
                    ).length);
                    setTeleDisable(false);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }



    const getPathways = async (programId, conditionId, oldPathwayId) => {
        setPWUpdateMessage("");
        setPWUpdateError("");
        setPathwayFormData(pathwayObject);
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchPathways(userId, programId, conditionId, oldPathwayId, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setPathwayFormData({
                        ...pathwayFormData,
                        programId: programId,
                        conditionId: conditionId,
                        oldPathwayId: oldPathwayId
                    })
                    setPathways(response.data.data.pathways);
                    setShowPWUpdateForm(true);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const getDisenrollmentReasons = async (programId, conditionId) => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchDisenrollmentReasons(userId, programId, conditionId, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setDisenrollmentReasons(response.data.data.reasons)
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const handlePWForm = async (e) => {
        e.preventDefault()
        setPWUpdateError("");
        setPWUpdateMessage("");
        if (pathwayFormData.pathwayId === "0") {
            setPWUpdateError("Please select the pathway");
        } else {
            const request = axios.CancelToken.source();
            let ct = {
                cancelToken: request.token
            }
            const formattedDate = (moment(pathwayFormData.hba1cDate).isValid()) ? moment(pathwayFormData.hba1cDate).format('YYYY-MM-DD') : "";

            await assignPathway(userId, pathwayFormData.programId, pathwayFormData.conditionId, pathwayFormData.oldPathwayId, pathwayFormData.pathwayId, pathwayFormData.notes, pathwayFormData.hba1c, formattedDate, ct)
                .then((response) => {
                    if (response.data.code === 200) {
                        getMemberBasicInfo();
                        setPWUpdateMessage(response.data.data.message);
                        setPathwayFormData(pathwayObject);
                        //getMemberServices(userId);
                        setShowPWUpdateForm(false);
                        callPathwayChangeLogs();
                        setRefreshVar(new Date().getTime());
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            return () => request.cancel();
        }
    }

    const handleAddWeightForm = async () => {
        setWeightUpdateError("");
        setWeightUpdateMessage("");
        if (weight === "") {
            setWeightUpdateError("Please enter weight");
        } else if (height === "") {
            setWeightUpdateError("Please enter height");
        } else {
            const request = axios.CancelToken.source();
            let ct = {
                cancelToken: request.token
            }
            await addWeight(userId, weight, height, ct)
                .then((response) => {
                    if (response.data.code === 200) {
                        setWeight("");
                        setHeight("");
                        setBMI("");
                        setBMIObservation("");
                        getMemberBasicInfo(userId);
                        setShowAddWeightModal(false);
                        setWeightUpdateMessage(response.data.data.message);
                        setRefreshVar(new Date().getTime());
                    } else {
                        setWeightUpdateError(response.data.data.message);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            return () => request.cancel();
        }
    }

    const handleDisEnrollment = async () => {
        setDisEnrollmentError("");
        setDisEnrollmentMessage("");
        if (disEnrollmentNote === "" || disEnrollmentReason == "") {
            setDisEnrollmentError("Please enter your notes and select reason why the member wants de-enrollment.");
        } else {
            const request = axios.CancelToken.source();
            let ct = {
                cancelToken: request.token
            }
            await disEnrollRequest(userId, disEnrollmentNote, disEnrollmentReason, ct)
                .then((response) => {
                    if (response.data.code === 200) {
                        setDisEnrollmentNote("");
                        getMemberBasicInfo(userId);
                        setDisEnrollmentMessage(response.data.data.message);
                    } else {
                        setDisEnrollmentError(response.data.data.message);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            return () => request.cancel();
        }
    }


    const handleAltMobileUpdate = async () => {
        setAltMobileUpdateError("");
        setAltMobileUpdateMessage("");
        if (alternateMobileNumber === "") {
            setAltMobileUpdateError("Please enter the new alternate mobile number .");
        } else {
            const request = axios.CancelToken.source();
            let ct = {
                cancelToken: request.token
            }
            await updateAltMobile(userId, alternateMobileNumber, ct)
                .then((response) => {
                    if (response.data.code === 200) {
                        setAlternateMobileNumber("");
                        setAltMobileUpdateMessage(response.data.data.message);
                        getMemberBasicInfo(userId);
                        setShowAltMobileModal(false);
                    } else {
                        setAltMobileUpdateError(response.data.data.message);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            return () => request.cancel();
        }
    }

    const formatDateTime = (date) => {
        if (!date) return "";
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}`;
    };


    // submit disposition 
    async function submitDisposition(e) {
        e.preventDefault();
        setRequestStatus({ ...requestStatus, isDispositionFormLoading: true });
        try {
            await cn_call_disposition({ confNumber, userId, notes: dispositionForm.notes, nextScheduleDate: formatDateTime(dispositionForm.callbackDateAndTime), callStatus: dispositionForm.callStatus });
            setShowDisposition(false);
            setDispositionForm({
                callStatus: "",
                notes: "",
                callbackDateAndTime: ""
            });
        } catch (e) { }
        setRequestStatus({ ...requestStatus, isDispositionFormLoading: false });
    }

    const [showMemberProfileDetailsModal, setShowMemberProfileDetailsModal] = useState(false);
    const handleShowMemberProfileDetailsModal = () => {
        setShowModal(true);
    }

    const refreshPageMember = async () => {
        MemberChatRef.current.refreshchat(userId);
        setShowEducationModal(false);
    } 

    const refreshMemberDetailsEdge = async () => {

        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        setShowSyncButton(false);
        await updateMemberFromEdge(userId, memberBasicInfo.membershipNumber, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    if (response.data.data.status == "1") {
                        setMessageEdge(response.data.data.message);
                        setRefreshPage(refreshPage + 1);
                    } else {
                        setErrorEdge(response.data.data.message);
                    }
                } else {
                    setErrorEdge('Something Went Wrong! Try again later');
                }
                setTimeout(() => {
                    setMessageEdge(false);
                    setErrorEdge(false);
                }, 2000);
                setShowSyncButton(true);
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const [showHealthLockerModal, setShowHealthLockerModal] = useState(false);
    const handleShowHealthLockerModal = () => {
        setShowHealthLockerModal(true);
    }
    const handleCloseHealthLockerModal = () => setShowHealthLockerModal(false);


    // get reasons list 
    async function getDispositionReasonsList() {
        let result;

        try {
            result = await data_disposition_list();
        } catch (e) { }

        if (result && result.data?.code === 200) {
            setDispositionReasons(result.data.data.disposition || []);
        }
    }

    const openRespectiveServiceModal = (sr) => {
        setSelectedServiceRecord(sr);
        handleShowMedicationModalServiceWise(sr.serviceRequestType);
    }

    const callMemberServicesMonthCategoryWiseFunc = () => {
        MemberServicesRef.current.getMemberServicesMonthCategoryWise(userId);
    };

    const callHomeBasedServicesHistory = () => {
        HomeBasedServicesHistoryRef.current.getHomeBasedServicesHistory(userId);
    }

    const callCallsHistory = () => {
        MemberCallHistoryRef.current.getCallsHistory(userId);
    }

    const callServiceEventHistory = () => {
        EventHistoryRef.current.getServiceEventHistory(userId);
    }

    const callPathwayChangeLogs = () => {
        PathwayChangeLogsRef.current.getPathwayChangeLogs(userId);
    }

    const handleAddWeightHeight = (e) => {
        const { name, value } = e.target;
        if (name == "weight" || name == "height") {
            if (!/^\d*\.?\d*$/.test(value)) {
                return false;
            }
            if (name === "weight") {
                setWeight(value);
            }
            if (name == "height") {
                setHeight(value)
            }
        }

    }
    const [showNewProgramModal, setShowNewProgramModal] = useState(false);
    const [programConditionPathways, setProgramConditionPathways] = useState([]);
    const pcpFormObject = {
        programId: '',
        conditionId: '',
        pathwayId: ''
    };
    const [pcpFormData, setPcpFormData] = useState(pcpFormObject);

    const [pcpError, setPCPError] = useState("");
    const [pcpMessage, setPCPMessage] = useState("");

    const showAddProgramModal = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchProgramConditionPathway(userId, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setProgramConditionPathways(response.data.data.programs);
                    setShowNewProgramModal(true);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const handleAddNewPCP = async () => {
        setPCPError("");
        setPCPMessage("");
        if (pcpFormData.programId === "") {
            setPCPError("Please select the program");
        } else if (pcpFormData.conditionId === "") {
            setPCPError("Please select the condition");
        } else {
            const request = axios.CancelToken.source();
            let ct = {
                cancelToken: request.token
            }
            await addNewProgram(userId, pcpFormData, ct)
                .then((response) => {
                    if (response.data.code === 200) {
                        getMemberBasicInfo();
                        setPCPError("");
                        setPCPMessage("");
                        setPcpFormData(pcpFormObject);
                        setRefreshVar(new Date().getTime());
                        setShowNewProgramModal(false);
                    } else {
                        setWeightUpdateError(response.data.data.message);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            return () => request.cancel();
        }
    }

    const stopPathway = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await stoppingPathway(userId, selectedRestartStopPathwayDetails.pathwayId, selectedRestartStopPathwayDetails.pathwayRelId, stopRemarks, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    getMemberBasicInfo();
                    setShowStopModal(false);
                    setRefreshVar(new Date().getTime());
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const restartPathway = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await restartingPathway(userId, selectedRestartStopPathwayDetails.pathwayId, selectedRestartStopPathwayDetails.pathwayRelId, restartRemarks, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    getMemberBasicInfo();
                    setShowRestartModal(false);
                    setRefreshVar(new Date().getTime());
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const deletePathway = async (pathwayRelId) => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await removePathway(userId, pathwayRelId, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    getMemberBasicInfo();
                    setRefreshVar(new Date().getTime());
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const openMemberDetailsIframe = () => {
        setShowModalIframe(true);
        setModalBodyIframe(<div className="iframe-container">
            <iframe
                src={iframeUrl}
                title="Member Details"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ height: '75vh', width: '100%' }}
            />
        </div>
        );
    }

    const handleCloseNew = () => {
        setShowModalIframe(false);
    }

    const closeTelehealthModal = () => {
        setShowTelehealthModal(false);
    }

    const closeEducationModal = () => {
        setShowEducationModal(false);
    }

    const closeUploadTelehealthModal = () => {
        setShowUploadTelehealthModal(false);
    }

    const openHealthLockerTeleHealth = () => {
        setShowUploadTelehealthModal(false);
        setShowHealthLockerModal(true);
    }


    return (
        <>
            <div id="content-wrapper" className="ContentDashboard">
                <div className="content-dashboard mb-3">
                    <div className="container-fluid CNDashboardSection">
                        <LoaderLoaderConnectNew isLoading={webex.isWebexRegistering} />
                        <TopBar userTabs={userTabs} closeUserTab={closeUserTab} />
                        <Row>
                            <Col md={9} className='pe-0'>
                                <Row>
                                    <div className='col-md-12 mt-3'>
                                        <div >
                                            <div className="profile-card priority-bg-default">
                                                <div className='row'>
                                                    <div className='col-md-auto'>
                                                        <div className="profile-image">
                                                            <img src={memberBasicInfo.imageUrl} alt="Profile Image" className=' priority-border-default' style={{ cursor: 'pointer' }} onClick={openMemberDetailsIframe} />
                                                        </div>
                                                    </div>

                                                    <div className='col-md ps-2'>
                                                        <div className='row '>
                                                            <div className='col-md'>
                                                                <div className="profile-info">
                                                                    <div className="info-line">
                                                                        <div className="info">
                                                                            <Link onClick={handleShowMemberProfileDetailsModal} className="name">{memberBasicInfo.memberName}</Link>
                                                                            <span className="location"><i className="fa fa-map-marker" aria-hidden="true"></i>
                                                                                {memberBasicInfo.city}</span>
                                                                            {/*
                                                                                memberBasicInfo.hof == "yes" &&
                                                                                <span className="tag-hof"> <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/yhbez/j1z48jbjs8.png" style={{ height: "12px", verticalAlign: "baseline" }} alt="" /> HOF</span>*/
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                    <div className="info-line">
                                                                        <div className="info">
                                                                            <span className="gender big-text me-3"><i className="" aria-hidden="true"><img src={memberBasicInfo.gender == "M" ? "https://storage.googleapis.com/ksabupatest/2024/09/24/5shux/73lal8g48h.png" : "https://storage.googleapis.com/ksabupatest/2024/09/24/hhqe5/gl8hw4hhl9.png"} style={{ height: "18px" }} alt="" /></i> {memberBasicInfo.gender == "M" ? "Male" : "Female"} {memberBasicInfo.age}</span>
                                                                            <span className="physical big-text me-3"><i className="" aria-hidden="true"><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/8u7jd/njt3shm0c4.png" style={{ height: "18px" }} alt="" /></i> {memberBasicInfo.height} cms</span>
                                                                            <span className="physical big-text me-3"><i className="" aria-hidden="true"><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/f4jhy/qhl8vj7lx4.png" style={{ height: "18px" }} alt="" /></i> {memberBasicInfo.weight} kgs</span>
                                                                            <span className="physical big-text"><i className="" aria-hidden="true"><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/zetj7/b4loqjyhqa.png" style={{ height: "18px" }} alt="" /></i> {memberBasicInfo.bmi}</span>
                                                                            <span className="physical big-text"><i className="" aria-hidden="true"><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/qem1c/m9x870b478.png" style={{ height: "18px" }} alt="" /></i> {memberBasicInfo.hba1c}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="info-line">
                                                                        <div className="info">
                                                                            <span className="email"><i className="fa fa-envelope pad-right-10" aria-hidden="true"></i>{memberBasicInfo.email}</span>
                                                                            <span className="phone"><i className="fa fa-phone pad-right-10" aria-hidden="true"></i>
                                                                                {memberBasicInfo.mobile}</span>
                                                                            <span className="phone"><i className="fa fa-phone pad-right-10" aria-hidden="true"></i>
                                                                                {(memberBasicInfo.altMobile) ? memberBasicInfo.altMobile : "-"}</span>
                                                                            <span>
                                                                                {
                                                                                    memberBasicInfo.readonly === "N" && <i className="fa fa-pencil" aria-hidden="true" onClick={() => {
                                                                                        setAltMobileUpdateMessage("");
                                                                                        setAltMobileUpdateError("");
                                                                                        setAlternateMobileNumber("");
                                                                                        setShowAltMobileModal(true);
                                                                                    }}></i>
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <hr className='my-2' />
                                                                    <div className="info-line">
                                                                        <div className="info row">
                                                                            <div className='tw-flex tw-flex-row tw-items-center tw-gap-2 tw-w-1/2'>
                                                                                <h5 className="membership-id tw-mb-2"><b>Membership:</b> {memberBasicInfo.membershipNumber}</h5>
                                                                                {showSyncButton ?
                                                                                    <span className="tw-cursor-pointer tw-mb-2.5" onClick={(e) => refreshMemberDetailsEdge(e)}><i className="bi bi-arrow-clockwise tw-text-xs"></i></span>
                                                                                    :
                                                                                    <span className="tw-mb-2.5"><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/3lsab/y968s2s843.gif" style={{ height: "20px" }} alt="" /></span>
                                                                                }
                                                                            </div>
                                                                            <h5 className="membership-id col-6 tw-mb-0 tw-pt-2"><b>Care Navigator:</b> {(memberBasicInfo.carenavigatorName) ? memberBasicInfo.carenavigatorName : "-"}</h5>
                                                                        </div>
                                                                        <div className={`${errorEdge != "" ? "tw-text-red-600 tw-m-0 tw-p-0 tw-text-xs" : "hide"}`} style={{ fontWeight: "400 !important" }}>{errorEdge}</div>
                                                                        <div className={`${messageEdge != "" ? "tw-text-green-600 tw-m-0 tw-p-0 tw-text-xs" : "hide"}`} style={{ fontWeight: "400 !important" }}>{messageEdge}</div>
                                                                    </div>
                                                                    <div className="info-line">
                                                                        <div className="info row">
                                                                            <h5 className="membership-id col-6 mb-2"><b>Main Membership:</b> {memberBasicInfo.mainMembershipNumber}</h5>
                                                                            <h5 className="membership-id col-6 mb-2"><b>Membership Type:</b> {(memberBasicInfo.policyMemberType) ? memberBasicInfo.policyMemberType : "-"}</h5>
                                                                        </div>
                                                                    </div>
                                                                    <div className="info-line">
                                                                        <div className="info row">
                                                                            <h5 className="dates col-6 mb-2"><b>Policy Start Date:</b> {memberBasicInfo.policyStartDate} </h5>
                                                                            <h5 className="dates col-6 mb-2"><b>Policy End Date:</b> {memberBasicInfo.policyEndDate}</h5>
                                                                            {/*|</span><span className="membership-id"><b>HOF:</b> {memberBasicInfo.hof}</span>*/}
                                                                        </div>
                                                                    </div>
                                                                    <div className="info-line">
                                                                        <div className="info row">
                                                                            <h5 className="membership-id col-6 mb-2"><b>Contract No:</b> {memberBasicInfo.contractNumber}</h5>
                                                                            <h5 className="dates col-6 mb-2"><b>Contract Name:</b> {memberBasicInfo.contractName}</h5>
                                                                        </div>
                                                                    </div>

                                                                </div>


                                                            </div>
                                                            <div className='col-md-3 ps-0'>
                                                                <div className='row tag-height-d' >
                                                                    {
                                                                        (memberBasicInfo.family) && (memberBasicInfo.family.length > 0) &&
                                                                        <div className='col-12'>
                                                                            <label className="membership-id mb-1">Family Member:</label>
                                                                            <select className="service-action" value="" onChange={(e) => openFamilyMemberProfile(e)}>
                                                                                <option value="">Select</option>
                                                                                {
                                                                                    memberBasicInfo.family.map((familyMember, familyIndex) => {
                                                                                        return <option value={familyMember.userId} key={familyIndex}>{familyMember.memberName}</option>
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        (memberBasicInfo.hraScore) ?
                                                                            <div className='col-12'>
                                                                                <div className="profile-info">
                                                                                    <div className="risk-badge">
                                                                                        <div className="risk-circle" style={{ backgroundColor: memberBasicInfo.hraColor }}>{memberBasicInfo.hraScore}<br /><span>HRA</span></div>
                                                                                        <div className="risk-label" style={{ color: memberBasicInfo.hraColor }}>{memberBasicInfo.hraObservation}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            : ""
                                                                    }

                                                                </div>

                                                            </div>

                                                        </div>


                                                    </div>

                                                    <div className='col-md-12 mt-3'>
                                                        <div className="button-row">
                                                            {
                                                                memberBasicInfo.readonly === "N" &&
                                                                <div className="dropdown">
                                                                    <button
                                                                        className="btn btn-secondary dropdown-toggle action-button blue-btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/j438d/k4whjjr37s.png" style={{ height: "12px" }} className='me-2' alt=""

                                                                        />Phone Call
                                                                    </button>
                                                                    <ul className="dropdown-menu">

                                                                        {
                                                                            !webex.webexLine || webex.isErrorInWebex ? <li className="disabled"><a className="dropdown-item" style={{ "cursor": "default" }}>Audio Call</a></li> :

                                                                                <li onClick={e => {
                                                                                    if (webex.isErrorInWebex) return;
                                                                                    openOutGoingCallWindow({ userId, memberName: memberBasicInfo.memberName, mobileNo: memberBasicInfo.mobile, extension: memberBasicInfo.extensionNo });
                                                                                }}
                                                                                    className={`${showCallBlock || webex.isErrorInWebex ? 'disabled' : ''}`}
                                                                                ><a className="dropdown-item" style={{ "cursor": `${showCallBlock ? 'default' : 'pointer'}` }}
                                                                                >Audio Call</a></li>
                                                                        }


                                                                        <li><a className="dropdown-item" style={{ "cursor": "pointer" }}
                                                                            data-bs-toggle="modal" data-bs-target="#upcomingFeatureModal"
                                                                        >Video Call</a></li>
                                                                        <li><a className="dropdown-item" style={{ "cursor": "pointer" }}
                                                                            data-bs-toggle="modal" data-bs-target="#upcomingFeatureModal"
                                                                        >Phone call </a></li>

                                                                        {/* upcomingFeatureModal */}
                                                                    </ul>
                                                                </div>
                                                            }
                                                            {
                                                                memberBasicInfo.readonly === "N" &&
                                                                <button className="action-button blue-btn" onClick={handleShowServicesModal}><i className="bi bi-file-earmark-plus-fill me-2"></i> Add Services</button>
                                                            }
                                                            {
                                                                memberBasicInfo.readonly === "N" && memberBasicInfo.showHealthMetricsButton == "Y" &&
                                                                <button className="action-button blue-btn" onClick={handleShowHealthMetricsPopUpModal}><img src="https://storage.googleapis.com/ksabupatest/2024/12/18/7e7sm/j44874k4n3.png" style={{ height: "12px" }} className='me-2' alt="" />Health Metrics</button>
                                                            }

                                                            {
                                                                memberBasicInfo.readonly === "N" &&
                                                                <button className="action-button blue-btn" onClick={handleShowWeightModal}><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/82l9s/3hfawo3u6h.png" style={{ height: "12px" }} className='me-2' alt="" />Add Weight</button>
                                                            }



                                                            <button className="action-button blue-btn" onClick={handleShowHRAModal}><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/v3528/ksb9v1c4nh.png" style={{ height: "12px" }} className='me-2' alt="" />Health Assessment</button>

                                                            <button className="action-button blue-btn" disabled={teleDisable} onClick={handleShowTeleHealth}><img src="https://storage.googleapis.com/ksabupatest/2024/10/16/lhjlk/z3xu6ac0va.png" style={{ height: "12px" }} className='me-2' alt="" /> TeleHealth Consultation</button>
                                                            {
                                                                memberBasicInfo.nabed_enable == "1" && <button className="action-button blue-btn" disabled={teleDisable} onClick={handleShowEducationContent}><img src="https://storage.googleapis.com/ksabupatest/2024/10/16/lhjlk/z3xu6ac0va.png" style={{ height: "12px" }} className='me-2' alt="" /> Educational Journey</button>
                                                            }

                                                            {
                                                                memberBasicInfo.readonly === "N" &&
                                                                <div className="dropdown">
                                                                    <button className="btn btn-secondary dropdown-toggle action-button blue-btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/v3528/ksb9v1c4nh.png" style={{ height: "12px" }} className='me-2' alt="" />Home Based Services
                                                                    </button>
                                                                    <ul className="dropdown-menu">
                                                                        {
                                                                            memberBasicInfo.homeService?.med == "Y" ?
                                                                                <li><a className="dropdown-item" style={{ "cursor": "pointer" }} onClick={(e) => handleShowMedicationModal("Medication Request", "medmetadoc")}>Medication Request</a></li>
                                                                                :
                                                                                <li><a className="dropdown-item" style={{ "cursor": "pointer" }} onClick={(e) => handleShowMedicationModal("Medication Request", "med")}>Medication Request</a></li>
                                                                        }

                                                                        {
                                                                            memberBasicInfo.homeService?.hbl == "Y" ?
                                                                                <li><a className="dropdown-item" style={{ "cursor": "pointer" }} onClick={(e) => handleShowMedicationModal("Lab Request", "hblmetadoc")}>Lab Request</a></li>
                                                                                :
                                                                                <li><a className="dropdown-item" style={{ "cursor": "pointer" }} onClick={(e) => handleShowMedicationModal("Lab Request", "hbl")}>Lab Request</a></li>
                                                                        }

                                                                        {
                                                                            memberBasicInfo.homeService?.hhc == "Y" ?
                                                                                <li><a className="dropdown-item" style={{ "cursor": "pointer" }} onClick={(e) => handleShowMedicationModal("Home Care Request", "hhcmetadoc")}>Home Care Request</a></li>
                                                                                :
                                                                                <li><a className="dropdown-item" style={{ "cursor": "pointer" }} onClick={(e) => handleShowMedicationModal("Home Care Request", "hhc")}>Home Care Request</a></li>
                                                                        }

                                                                        {
                                                                            memberBasicInfo.homeService?.hbv == "Y" ?
                                                                                <li><a className="dropdown-item" style={{ "cursor": "pointer" }} onClick={(e) => handleShowMedicationModal("Home Based Vaccine", "hbvmetadoc")}>Home Based Vaccine</a></li>
                                                                                :
                                                                                <li><a className="dropdown-item" style={{ "cursor": "pointer" }} onClick={(e) => handleShowMedicationModal("Home Based Vaccine", "hbv")}>Home Based Vaccine</a></li>
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            }

                                                            {
                                                                // memberBasicInfo.readonly === "N" &&
                                                                // <div className="dropdown">
                                                                //     <button className="btn btn-secondary dropdown-toggle action-button blue-btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                //         <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/v3528/ksb9v1c4nh.png" style={{ height: "12px" }} className='me-2' alt="" />MetaDoc Services
                                                                //     </button>
                                                                //     <ul className="dropdown-menu">
                                                                //         <li><a className="dropdown-item" style={{ "cursor": "pointer" }} onClick={(e) => handleShowMetaDocModal("Lab Request")}>Lab Request</a></li>
                                                                //     </ul>
                                                                // </div>
                                                            }


                                                            <button className="action-button blue-btn" onClick={handleShowHealthLockerModal}><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/v3528/ksb9v1c4nh.png" style={{ height: "12px" }} className='me-2' alt="" />Health Locker </button>

                                                            <button className="action-button blue-btn" onClick={() => {
                                                                setShowNotesModal(true);
                                                            }}><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/v3528/ksb9v1c4nh.png" style={{ height: "12px" }} className='me-2' alt="" />Notes </button>

                                                            {
                                                                memberBasicInfo.readonly === "N" &&
                                                                <button className="action-button red-btn" onClick={handleShowDisEnrollmentModal}><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/5ljhf/w86ewhd2ya.png" style={{ height: "12px" }} className='me-2' alt="" />De-enroll</button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                    <div className='col-md-12 mt-3'>
                                        <div className='card card-task'>
                                            <div className='card-body'>
                                                <div className='row align-items-center'>
                                                    <div className="col"><h5 className="card-title">Programs</h5></div>
                                                    <div className="col-auto">
                                                        {
                                                            memberBasicInfo.readonly === "N" &&
                                                            <>
                                                                <button className="btn btn-primary addPrograms-1-btn" onClick={() => showAddProgramModal()}>Assign Program <i className="bi bi-plus-lg"></i></button>
                                                            </>
                                                        }

                                                    </div>

                                                </div>
                                                <div className='row'>
                                                    {
                                                        memberPathways.map((item, index) => {
                                                            return (

                                                                <div className='col-md-4 mt-2' key={index}>
                                                                    <div className='profile-box-n1'>

                                                                        <div className="info-line row">
                                                                            <div className='col'>
                                                                                <h3 className='profile-card-title mb-2'>{item.programName}</h3>
                                                                                <h5 className="program" ><b>Condition:</b><br /> {item.conditionName}
                                                                                    {
                                                                                        item.showInsights == "Y" &&
                                                                                        <i className="fa fa-bar-chart" aria-hidden="true" style={{ marginLeft: '8px', cursor: 'pointer' }} onClick={() => {
                                                                                            setMetricsInsightsArr({ "userId": userId, "conditionId": item.conditionId, "conditionName": item.conditionName, "metrics": item.metrics })
                                                                                            setShowMetricsInsightsModal(true);
                                                                                        }}></i>
                                                                                    }

                                                                                </h5>
                                                                                {/* <h5 className="program " ><b>Program:</b><br /> {item.programName}</h5> */}
                                                                                <div className="info-line mt-2">
                                                                                    {
                                                                                        (item.pathwayName !== "Pending") ?
                                                                                            <>
                                                                                                <div className='row'>
                                                                                                    <div className='col'>
                                                                                                        <h5 className="status mb-0"><b>Pathway:</b></h5>
                                                                                                        <h5 className="status d-flex"><Link style={{ textDecoration: "underline", fontWeight: "500" }} onClick={(e) => handleShowCarePlanModal(item.pathwayId)}>{item.pathwayName}</Link> {
                                                                                                            memberBasicInfo.readonly === "N" && <i className="fa fa-pencil ps-2" aria-hidden="true" onClick={() => getPathways(item.programId, item.conditionId, item.pathwayId)}></i>
                                                                                                        }</h5>
                                                                                                    </div>

                                                                                                    {/* <div className='col-6'>
                                                                                        <h5 className={`${item.currentPathwayMonth === "" ? "hide" : "status"}`}>
                                                                                        <b>Current Pathway Month:</b><br />
                                                                                        {item.currentPathwayMonth}
                                                                                        </h5>
                                                                                    </div>                             */}
                                                                                                </div>

                                                                                            </> :
                                                                                            <>

                                                                                                <div className='row'>
                                                                                                    <div className='col'>
                                                                                                        <h5 className="status mb-0"><b>Pathway:</b></h5>
                                                                                                        <h5 className="status">
                                                                                                            {item.pathwayName}
                                                                                                            {
                                                                                                                memberBasicInfo.readonly === "N" && <i className="fa fa-pencil ms-2" aria-hidden="true" onClick={() => getPathways(item.programId, item.conditionId, item.pathwayId)}></i>
                                                                                                            }
                                                                                                        </h5>

                                                                                                    </div>
                                                                                                    {/* <div className='col-6'>
                                                                                                <div className='row'>
                                                                                                    <div className='col'>
                                                                                                        <h5 className="status mb-0"><b>Pathway:</b></h5>
                                                                                                        <h5 className="status">{item.pathwayName}</h5>
                                                                                                        {
                                                                                                            memberBasicInfo.readonly === "N" && <i className="fa fa-pencil" aria-hidden="true" onClick={() => getPathways(item.programId, item.conditionId, item.pathwayId)}></i>
                                                                                                        }
                                                                                                    </div>
                                                                                                    {/* <div className='col-6'>
                                                                                    <h5 className={`${item.currentPathwayMonth === "" ? "hide" : "status"}`}>
                                                                                        <b>Current Pathway Month:</b><br />
                                                                                        {item.currentPathwayMonth}
                                                                                        </h5>
                                                                                    </div> */}
                                                                                                </div>
                                                                                            </>
                                                                                    }

                                                                                </div>
                                                                            </div>
                                                                            {/* <div className='col-auto'>
                                                                        <h5 className="status-big-text mb-0" >34</h5>
                                                                     </div> */}

                                                                        </div>


                                                                        <div className="info-line mt-2 d-flex">
                                                                            {
                                                                                memberBasicInfo.readonly === "N" && item.pathwayStatus === "active" && totalActivePathways > 1 &&
                                                                                < button className="action-button blue-btn me-2" style={{ fontSize: "12px", padding: "6px 10px", fontWeight: "500" }} onClick={() => {
                                                                                    setStopRemarks("");
                                                                                    setSelectedRestartStopPathwayDetails({
                                                                                        pathwayId: item.pathwayId,
                                                                                        pathwayRelId: item.pathwayRelId,
                                                                                        conditionName: item.conditionName
                                                                                    });
                                                                                    setShowStopModal(true);
                                                                                }}>Stop Condition</button>
                                                                            }
                                                                            {
                                                                                memberBasicInfo.readonly === "N" && item.pathwayStatus === "stopped" &&
                                                                                <button className="action-button blue-btn me-2" style={{ fontSize: "12px", padding: "6px 10px", fontWeight: "500" }} onClick={() => {
                                                                                    setRestartRemarks("");
                                                                                    setSelectedRestartStopPathwayDetails({
                                                                                        pathwayId: item.pathwayId,
                                                                                        pathwayRelId: item.pathwayRelId,
                                                                                        conditionName: item.conditionName
                                                                                    });
                                                                                    setShowRestartModal(true);
                                                                                }}>Restart Condition</button>
                                                                            }
                                                                            {
                                                                                memberBasicInfo.readonly === "N" && item.pathwayStatus === "notset" && totalActivePathways > 1 &&
                                                                                < button className="action-button blue-btn me-2" style={{ fontSize: "12px", padding: "6px 10px", fontWeight: "500" }} onClick={() => {
                                                                                    handleConfirmConditionRemove(item.conditionName, item.pathwayRelId);
                                                                                }}>Remove Condition</button>
                                                                            }
                                                                            {
                                                                                memberBasicInfo.readonly === "N" && (item.pathwayStatus === "active" || item.pathwayStatus === "notset") && item.metrics.length > 0 &&
                                                                                <button className="action-button blue-btn" style={{ fontSize: "12px", padding: "6px 10px", fontWeight: "500" }} onClick={() => {
                                                                                    setMetricsArr({ "pathwayRelId": item.pathwayRelId, "userId": userId, "metrics": item.metrics, "conditionName": item.conditionName })
                                                                                    setShowMetricsModal(true);
                                                                                }}>Add Medical Parameters</button>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div >
                                                            )
                                                        })
                                                    }
                                                    {/* {
                                                memberBasicInfo.readonly === "N" &&
                                                <div className='col-md-2'>
                                                    <button type="button" className="btn" onClick={() => showAddProgramModal()}>
                                                        <i className="bi bi-plus-lg" style={{ fontSize: "60px", color: "#e3e3e3" }}></i>
                                                    </button>
                                                </div>
                                            } */}
                                                </div>
                                            </div >

                                            {/*<div className='row mt-3'>

                                        <div className='col-md-3'>
                                            <div className='profile-box-n2'>
                                                <div className='row align-items-center'>
                                                    <div className='col'>
                                                        <h5 className="profile-card-subtitle mb-0">HRA</h5>
                                                    </div>
                                                    <div className='col-auto text-center'>
                                                        <h5 className="profile-card-count mb-0">53</h5>
                                                        <h6 className='profile-card-label mb-0'>Border Line</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-md-3 ps-0'>
                                            <div className='profile-box-n3'>
                                                <div className='row align-items-center'>
                                                    <div className='col'>
                                                        <h5 className="profile-card-subtitle mb-0">BMI</h5>
                                                    </div>
                                                    <div className='col-auto text-center'>
                                                        <h5 className="profile-card-count mb-0">34<span>.1</span></h5>
                                                        <h6 className='profile-card-label mb-0'>Obese</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-md-3 ps-0'>
                                            <div className='profile-box-n4'>
                                                <div className='row align-items-center'>
                                                    <div className='col'>
                                                        <h5 className="profile-card-subtitle mb-0">HBA1c</h5>
                                                    </div>
                                                    <div className='col-auto text-center'>
                                                        <h5 className="profile-card-count mb-0">7<span>.3</span></h5>
                                                        <h6 className='profile-card-label mb-0'>Diabetes</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-md-3 ps-0'>
                                            <div className='profile-box-n5'>
                                                <div className='row align-items-center'>
                                                    <div className='col'>
                                                        <h5 className="profile-card-subtitle mb-0">Cholesterol</h5>
                                                    </div>
                                                    <div className='col-auto text-center ps-0'>
                                                        <h5 className="profile-card-count mb-0">210<span>mg/dL</span></h5>
                                                        <h6 className='profile-card-label mb-0'>High</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                      </div>*/}
                                        </div>
                                    </div>


                                    <MemberCalls memberBasicInfo={memberBasicInfo} userId={userId} callCallsHistory={callCallsHistory} openOutGoingCallWindow={openOutGoingCallWindow}  {...{ userId, memberName: memberBasicInfo.memberName, mobileNo: memberBasicInfo.mobile, extension: memberBasicInfo.extensionNo }}

                                    />

                                    <MemberServices openRespectiveServiceModal={openRespectiveServiceModal} memberBasicInfo={memberBasicInfo} userId={userId} ref={MemberServicesRef} handleMemberBulkHomeCareRequest={handleMemberBulkHomeCareRequest} callServiceEventHistory={callServiceEventHistory} showAddServicesModal={showAddServicesModal} setShowAddServicesModal={setShowAddServicesModal} handleShowServicesModal={handleShowServicesModal} />

                                    <HomeBasedServicesHistory memberBasicInfo={memberBasicInfo} ref={HomeBasedServicesHistoryRef} />
                                    <MemberCallHistory ref={MemberCallHistoryRef} callCallsHistory={callCallsHistory} handleShowTeleHealthReschedule={handleShowTeleHealthReschedule} handleShowUploadTeleHealthReschedule={handleShowUploadTeleHealthReschedule} />
                                    <EventHistory memberBasicInfo={memberBasicInfo} ref={EventHistoryRef} />
                                    <PathwayChangeLogs ref={PathwayChangeLogsRef} />
                                    <MemberNotes userId={userId} memberBasicInfo={memberBasicInfo} showNotesModal={showNotesModal} setShowNotesModal={setShowNotesModal} />
                                    {
                                        showMetricsModal === true &&
                                        <MetricsAdd memberBasicInfo={memberBasicInfo} showMetricsModal={showMetricsModal} setShowMetricsModal={setShowMetricsModal} metricsArr={metricsArr} setRefreshVar={setRefreshVar} getMemberBasicInfo={getMemberBasicInfo} />
                                    }
                                    {
                                        showMetricsInsightsModal === true &&
                                        <MetricsInsights memberBasicInfo={memberBasicInfo} showMetricsInsightsModal={showMetricsInsightsModal} setShowMetricsInsightsModal={setShowMetricsInsightsModal} metricsInsightsArr={metricsInsightsArr} setRefreshVar={setRefreshVar} userId={userId} refreshVar={refreshVar} />
                                    }
                                    {
                                        memberBasicInfo.showHRAGraph === "Y" &&
                                        <div className='col-md-12 mt-3'>
                                            <Column columnGraphTitle="HRA History" columnGraphType="hra_history" userId={userId} />
                                        </div>
                                    }
                                    {
                                        memberBasicInfo.showWeightGraph === "Y" &&
                                        <div className='col-md-12 mt-3'>
                                            <Line lineGraphTitle="Weight History" lineGraphType="weight_history" userId={userId} refreshVar={refreshVar} />
                                        </div>
                                    }
                                    {
                                        memberBasicInfo.showHBA1CGraph === "Y" &&
                                        <div className='col-md-12 mt-3'>
                                            <Line lineGraphTitle="HBA1C History" lineGraphType="hba1c_history" userId={userId} refreshVar={refreshVar} />
                                        </div>
                                    }


                                </Row>
                            </Col>
                            <Col md={3} className=' ps-0'>
                                <Chat userId={userId} firebaseNode={firebaseNode} ref={MemberChatRef}/>
                            </Col>

                        </Row >
                        <Row>
                            <Col>
                                <HealthManagement memberBasicInfo={memberBasicInfo} showHRAModal={showHRAModal} handleShowHRAModal={handleShowHRAModal} handleCloseHRAModal={handleCloseHRAModal} getMemberBasicInfo={getMemberBasicInfo} callServiceEventHistory={callServiceEventHistory} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormsModal formShort={formShort} header={modalTitle} memberBasicInfo={memberBasicInfo} showHRAModal={showMedicationModal} handleShowMedicationModal={handleShowMedicationModal} selectedServiceRecord={selectedServiceRecord} handleCloseHRAModal={handleCloseMedicationModal} callHomeBasedServicesHistory={callHomeBasedServicesHistory} selectedBulkServices={selectedBulkServices} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormsModalMetaDoc header={modalTitle} memberBasicInfo={memberBasicInfo} showHRAModal={showMedicationMetaDocModal} handleShowMedicationModal={handleShowMedicationMetaDocModal} selectedServiceRecord={selectedServiceRecord} handleCloseHRAModal={handleCloseMedicationMetaDocModal} callHomeBasedServicesHistory={callHomeBasedServicesHistory} selectedBulkServices={selectedBulkServices} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {
                                    showCarePlanModal && <CarePlanChange pathwayId={modalPathwayName} showCarePlanModal={showCarePlanModal} handleShowCarePlanModal={handleShowCarePlanModal} handleCloseCarePlanModal={handleCloseCarePlanModal} memberPathways={memberPathways} />
                                }
                            </Col>
                        </Row>




                        {showModal && <AddMember status="view" heading="View Member Details" hideModal={() => setShowModal(false)} />}


                        {showHealthLockerModal && <HealthLocker memberBasicInfo={memberBasicInfo} showHealthLockerModal={showHealthLockerModal} onHide={handleShowHealthLockerModal} handleCloseHealthLockerModal={handleCloseHealthLockerModal} callCallsHistory={callCallsHistory} />}




                        <Modal id="pathwayUpdate" show={showPWUpdateForm} centered onHide={() => setShowPWUpdateForm(false)} size="" backdrop="static" keyboard={false} fullscreen={false}>
                            <Modal.Header closeButton>
                                <h1 className='modal-title'>Pathway Status</h1>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='row'>
                                    <div className='col-12 mb-3'>
                                        <label className="form-label">Select Pathway: <span className='required'>*</span></label>
                                        <select name="pathwayId" className="form-select select-input-ui" value={pathwayFormData.pathwayId} onChange={(e) => setPathwayFormData({
                                            ...pathwayFormData,
                                            ['pathwayId']: e.target.value
                                        })}>
                                            <option value="0">Select</option>
                                            {
                                                pathways.map((item, index) => {
                                                    return <option key={index} value={item.pathwayId}>{item.pathwayName}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    {/*<div className='col-6 mb-3'>
                                        <label className="form-label">HBA1c:</label>
                                        <input type='text' name="hba1c" value={pathwayFormData.hba1c} className='form-control control-input-ui' placeholder='Enter HBA1c' onChange={(e) => setPathwayFormData({
                                            ...pathwayFormData,
                                            ['hba1c']: e.target.value
                                        })} />
                                    </div>
                                    <div className='col-6 mb-3'>
                                        <div className="col-auto">
                                            <label className="form-label">Date:</label>

                                        </div>
                                        <div className="col">

                                            <DatePicker
                                                dateFormat="dd-MM-yyyy"
                                                //minDate={new Date()}
                                                className='form-control control-input-ui'
                                                selected={pathwayFormData.hba1cDate || null} // Ensure null when no date is selected
                                                placeholderText="Enter Date" // Use placeholderText for DatePicker
                                                name="hba1cDate"
                                                maxDate={new Date()}
                                                onChange={(date) => {
                                                    setPathwayFormData({
                                                        ...pathwayFormData,
                                                        hba1cDate: date,
                                                    });
                                                }}
                                                onKeyDown={(e) => e.preventDefault()}
                                                onPaste={(e) => e.preventDefault()}
                                            />

                                        </div>
                                    </div>*/}

                                    <div className='col-12 mb-3'>

                                        <label className="form-label">Remarks:</label>
                                        <textarea name="notes" className='form-control control-input-ui' placeholder='Enter Remarks' onChange={(e) => setPathwayFormData({
                                            ...pathwayFormData,
                                            ['notes']: e.target.value
                                        })} value={pathwayFormData.notes}></textarea>
                                    </div>

                                </div>


                                <div className='col-12 text-center'>
                                    <div className={`${pwUpdateError != "" ? "errorDiv" : "hide"} `}>{pwUpdateError}</div>
                                    <div className={`${pwUpdateMessage != "" ? "messageDiv" : "hide"}`}>{pwUpdateMessage}</div>
                                    <button type="button" className="btn btn-primary save-btn me-3" onClick={handlePWForm}>Save</button>

                                </div>

                            </Modal.Body>
                        </Modal>

                        {/*Weight Modal*/}
                        <Modal id="weightUpdate" show={showAddWeightModal} onHide={() => setShowAddWeightModal(false)} centered size="" backdrop="static" keyboard={false} fullscreen={false}>
                            <Modal.Header closeButton>
                                <h1 className='modal-title'>Add Weight</h1>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='row'>
                                    <div className='col-6 mb-3'>
                                        <label className="form-label">Weight (in kgs):</label>
                                        <input type="text" value={weight} name="weight" className='form-control control-input-ui' onChange={handleAddWeightHeight} />
                                    </div>
                                    <div className='col-6 mb-3'>
                                        <label className="form-label">Height (in cms):</label>
                                        <input type="text" value={height} name="height" className='form-control control-input-ui' onChange={handleAddWeightHeight} />
                                    </div>
                                    <div className='col-12'>
                                        <span className={`${bmiObservation !== "" ? "" : "hide"}`}>BMI: {bmi}  ({bmiObservation})</span>
                                    </div>
                                    <div className='col-12 text-center'>
                                        <div className={`${weightUpdateError != "" ? "errorDiv" : "hide"}`}>{weightUpdateError}</div>
                                        <div className={`${weightUpdateMessage != "" ? "messageDiv" : "hide"}`}>{weightUpdateMessage}</div>

                                        <button className="btn btn-primary save-btn me-3" onClick={handleAddWeightForm}>Save</button>

                                    </div>
                                </div>


                            </Modal.Body>
                        </Modal>


                        <Modal id="HealthMetricsPopUp" show={showHealthMetricsPopUpModal} onHide={() => setShowHealthMetricsPopUpModal(false)} size="xl" backdrop="static" keyboard={false} fullscreen={false}>
                            <Modal.Header closeButton>
                                <h1 className='modal-title'>{memberBasicInfo.memberName}</h1>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='row'>
                                    <HealthMetrics memberBasicInfo={memberBasicInfo} userId={userId} getMemberBasicInfo={getMemberBasicInfo} refreshVar={refreshVar} />
                                </div>
                            </Modal.Body>
                        </Modal>



                        <Modal id="disEnrollment" centered show={showDisEnrollmentModal} onHide={() => setShowDisEnrollmentModal(false)} size="" backdrop="static" keyboard={false} fullscreen={false}>
                            <Modal.Header closeButton>
                                <h1 className='modal-title'>De-enroll</h1>
                            </Modal.Header>
                            <Modal.Body>
                                {
                                    (memberBasicInfo.pendingDisEnrollmentRequest == "Y") ?
                                        <div className='text-center'>
                                            The request you raised is pending for approval.<br /><br />
                                            <button className="btn btn-primary cancel-btn" onClick={() => {
                                                setShowDisEnrollmentModal(false);
                                            }}>Close</button>
                                        </div>
                                        :
                                        <>
                                            <div className='row'>
                                                <div className='col-12 mb-3'>

                                                    <label className="form-label">Reason:<span className='text-danger'>*</span></label>
                                                    {/*<select className="form-select select-input-ui" aria-label="Default select example" value={disEnrollmentReason} onChange={(e) => setDisEnrollmentReason(e.target.value)}>
                                                        <option value="">Select</option>
                                                        <option value="No Longer Interested">No Longer Interested</option>
                                                        <option value="Health Deteriorated">Health Deteriorated</option>
                                                        <option value="Relocated">Relocated</option>
                                                        <option value="Financial Reasons">Financial Reasons</option>
                                                        <option value="Others">Others</option>
                                                    </select>*/}
                                                    <select className="form-select select-input-ui" aria-label="Default select example" value={disEnrollmentReason} onChange={(e) => setDisEnrollmentReason(e.target.value)}>
                                                        <option value="">Select</option>
                                                        {
                                                            disenrollmentReasons.map((item, index) => {
                                                                return <option value={item.recordId} key={index}>{item.reason}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                <div className='col-12 mb-3'>
                                                    <label>Remarks:<span className='text-danger'>*</span></label>
                                                    <textarea name="disEnrollmentNote" className='form-control control-input-ui' onChange={(e) => setDisEnrollmentNote(e.target.value)} value={disEnrollmentNote}></textarea>
                                                </div>
                                                <div className='col-12 text-center'>
                                                    <div className={`${disEnrollmentError != "" ? "errorDiv" : "hide"}`}>{disEnrollmentError}</div>
                                                    {/* <div className={`${disEnrollmentMessage != "" ? "messageDiv" : "hide"}`}>{disEnrollmentMessage}</div> */}
                                                    <button className="btn btn-primary save-btn me-3" onClick={handleDisEnrollment}>Save</button>
                                                </div>
                                            </div>



                                        </>
                                }


                            </Modal.Body >
                        </Modal >


                        <Modal id="memberCallDispositionModal" show={showDisposition} centered onHide={() => setShowDisposition(false)} size="md" backdrop="static" keyboard={false} fullscreen={false}>
                            <Modal.Header closeButton>
                                <h1 className='modal-title' style={{ width: "90%" }}>{/*selectedServiceRecord.serviceName*/}</h1>
                            </Modal.Header>
                            <Modal.Body>
                                <               div className='member-engagement mb-3 coach-call-box-1'>
                                    <div className='row align-items-center'>
                                        <div className='col-auto'>
                                            <h5 className="user-card-title" >{memberBasicInfo.memberName}</h5>
                                            <h5 className="user-card-subtitle">Member ID: {memberBasicInfo.membershipNumber}</h5>
                                        </div>
                                    </div>
                                </div>

                                <div className='member-engagement mb-3'>
                                    <div className="row align-items-center">
                                        <div className="col-md-12  mb-3">
                                            <h6 className="call-disposition-title">Call Status</h6>

                                            <select
                                                className="form-select input-field-ui"
                                                id="SelectProgram"
                                                aria-label="Default select example"
                                                value={dispositionForm.callStatus}
                                                onChange={e => setDispositionForm({ ...dispositionForm, callStatus: e.target.value })}
                                            >
                                                <option>Select</option>
                                                {
                                                    dispositionReasons.map((reason,index) => (
                                                        <option value={reason} key={index}>{reason}</option>
                                                    ))
                                                }
                                            </select>


                                            <div className="form-group mt-3">
                                                <h6 className="call-disposition-title">Remarks</h6>
                                                <textarea
                                                    name="notes"
                                                    placeholder="Remarks"
                                                    className='form-control control-input-ui'
                                                    value={dispositionForm.notes}
                                                    onChange={e => setDispositionForm({ ...dispositionForm, notes: e.target.value })}
                                                >
                                                </textarea>
                                            </div>


                                        </div>

                                        <div className="col-auto">
                                            <label htmlFor='inputdate' className="col-form-label">Schedule a Callback:</label>
                                        </div>
                                        <div className="col">
                                            <DatePicker
                                                selected={dispositionForm.callbackDateAndTime}
                                                onChange={update => setDispositionForm({ ...dispositionForm, callbackDateAndTime: update })}
                                                showTimeSelect
                                                timeIntervals={15}
                                                timeInputLabel="Time:"
                                                dateFormat="dd/MM/yyyy h:mm aa"
                                                placeholderText="Select Date and Time"
                                                className='form-control control-input-ui'
                                            />

                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-12 text-center">
                                        <button type="button" className="btn btn-primary save-btn"
                                            onClick={e => submitDisposition(e)}
                                            disabled={requestStatus.isDispositionFormLoading ? "Disabeld" : ""}
                                        >{requestStatus.isDispositionFormLoading ? "Saving..." : "Save"}</button>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>

                        <Modal id="altMobileModal" centered show={showAltMobileModal} onHide={() => setShowAltMobileModal(false)} size="" backdrop="static" keyboard={false} fullscreen={false}>
                            <Modal.Header closeButton>
                                <h1 className='modal-title'>Update Alternate Mobile Number</h1>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='row'>
                                    <div className='col-12 mb-3'>
                                        <label className="form-label">Alternate Mobile Number:</label>
                                        <input type="text" value={alternateMobileNumber} name="alternateMobileNumber" className='form-control control-input-ui' onChange={(e) => {
                                            if (!/^\d*\.?\d*$/.test(e.target.value)) {
                                                return false;
                                            }
                                            setAlternateMobileNumber(e.target.value)
                                        }
                                        } />
                                    </div>
                                    <div className='col-12 text-center'>
                                        <div className={`${altMobileUpdateError != "" ? "errorDiv" : "hide"}`}>{altMobileUpdateError}</div>
                                        <div className={`${altMobileUpdateMessage != "" ? "messageDiv" : "hide"}`}>{altMobileUpdateMessage}</div>
                                        <button className="btn btn-primary save-btn me-3" onClick={handleAltMobileUpdate}>Save</button>
                                    </div>
                                </div>
                            </Modal.Body >
                        </Modal >
                        <Modal id="newProgramModal" centered show={showNewProgramModal} onHide={() => {
                            setShowNewProgramModal(false);
                            setPcpFormData(pcpFormObject)
                        }} size="" backdrop="static" keyboard={false} fullscreen={false}>
                            <Modal.Header closeButton>
                                <h1 className='modal-title'>Assign New Program</h1>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='row'>
                                    <div className='col-12 mb-3'>
                                        <label className="form-label">Select Program: <span className='required'>*</span></label>
                                        <select className='form-control control-input-ui' name="programId" value={pcpFormData.programId} onChange={(e) => setPcpFormData({
                                            ...pcpFormData,
                                            programId: e.target.value
                                        })}>
                                            <option value="">Select</option>
                                            {
                                                programConditionPathways.map((program, pindex) => {
                                                    return <option value={program.programId}>{program.programName}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    {
                                        pcpFormData.programId != "" &&
                                        <div className='col-12 mb-3'>
                                            <label className="form-label">Select Condition: <span className='required'>*</span></label>
                                            <select
                                                className='form-control control-input-ui'
                                                name="conditionId"
                                                value={pcpFormData.conditionId || ''}
                                                onChange={(e) => setPcpFormData({
                                                    ...pcpFormData,
                                                    conditionId: e.target.value
                                                })}
                                            >
                                                <option value="">Select</option>
                                                {
                                                    Array.isArray(programConditionPathways) && programConditionPathways.length > 0 &&
                                                    (programConditionPathways.find((program) => program.programId == pcpFormData.programId)?.conditions || [])
                                                        .map((condition, cindex) => (
                                                            <option key={cindex} value={condition.conditionId}>{condition.conditionName}</option>
                                                        ))
                                                }
                                            </select>
                                        </div>
                                    }
                                    {
                                        pcpFormData.conditionId != "" &&
                                        <div className='col-12 mb-3'>
                                            <label className="form-label">Select Pathway:</label>
                                            <select
                                                className='form-control control-input-ui'
                                                name="pathwayId"
                                                value={pcpFormData.pathwayId || ''}
                                                onChange={(e) => setPcpFormData({
                                                    ...pcpFormData,
                                                    pathwayId: e.target.value
                                                })}
                                            >
                                                <option value="">Select</option>
                                                {
                                                    Array.isArray(programConditionPathways) && programConditionPathways.length > 0 &&
                                                    (programConditionPathways.find((program) => program.programId == pcpFormData.programId)?.conditions.find((condition) => condition.conditionId == pcpFormData.conditionId)?.pathways || [])
                                                        .map((pathway, paindex) => (
                                                            <option key={paindex} value={pathway.pathwayId}>{pathway.pathwayName}</option>
                                                        ))
                                                }
                                            </select>
                                        </div>

                                    }

                                    <div className='col-12 text-center'>
                                        <div className={`${pcpError != "" ? "errorDiv" : "hide"}`}>{pcpError}</div>
                                        <div className={`${pcpMessage != "" ? "messageDiv" : "hide"}`}>{pcpMessage}</div>
                                        <button className="btn btn-primary save-btn me-3" onClick={handleAddNewPCP}>Save</button>
                                    </div>
                                </div>
                            </Modal.Body >
                        </Modal>

                        <Modal id="stopPathway" centered show={showStopModal} onHide={() => {
                            setShowStopModal(false);
                            setSelectedRestartStopPathwayDetails(restartStopObj);
                        }} size="" backdrop="static" keyboard={false} fullscreen={false}>
                            <Modal.Header closeButton>
                                <h1 className='modal-title'>Stop Condition - {selectedRestartStopPathwayDetails.conditionName}</h1>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='row'>
                                    <div className='col-12 mb-3'>
                                        <label className="form-label">Remarks: <span className='required'>*</span></label>
                                        <textarea name="notes" className='form-control control-input-ui' placeholder='Enter Remarks' onChange={(e) => setStopRemarks(e.target.value)} value={stopRemarks}></textarea>
                                    </div>
                                </div>
                                <div className='col-12 text-center'>
                                    <div className={`${stopError != "" ? "errorDiv" : "hide"}`}>{stopError}</div>
                                    <div className={`${stopMessage != "" ? "messageDiv" : "hide"}`}>{stopMessage}</div>
                                    {
                                        stopRemarks !== "" &&
                                        <button className="btn btn-primary save-btn me-3" onClick={stopPathway}>Stop</button>
                                    }
                                </div>
                            </Modal.Body >
                        </Modal>

                        <Modal id="restartPathway" centered show={showRestartModal} onHide={() => {
                            setShowRestartModal(false);
                            setSelectedRestartStopPathwayDetails(restartStopObj);
                        }} size="" backdrop="static" keyboard={false} fullscreen={false}>
                            <Modal.Header closeButton>
                                <h1 className='modal-title'>Restart Condition - {selectedRestartStopPathwayDetails.conditionName}</h1>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='row'>
                                    <div className='col-12 mb-3'>
                                        <label className="form-label">Remarks: <span className='required'>*</span></label>
                                        <textarea name="notes" className='form-control control-input-ui' placeholder='Enter Remarks' onChange={(e) => setRestartRemarks(e.target.value)} value={restartRemarks}></textarea>
                                    </div>
                                </div>
                                <div className='col-12 text-center'>
                                    <div className={`${restartError != "" ? "errorDiv" : "hide"}`}>{restartError}</div>
                                    <div className={`${restartMessage != "" ? "messageDiv" : "hide"}`}>{restartMessage}</div>
                                    {
                                        restartRemarks !== "" &&
                                        <button className="btn btn-primary save-btn me-3" onClick={restartPathway}>Restart</button>
                                    }
                                </div>
                            </Modal.Body >
                        </Modal>
                    </div >
                </div >
            </div >

            <Commingsoon />

            {showModalIframe ? <ModalBoxButton handleClose={handleCloseNew} showModaln={showModalIframe} titlen="Member Details" bodyn={modalBodyIframe} type="modal-fullscreen" button={undefined} hideButton={handleCloseNew} /> : null}
            {
                showTelehealthModal && <TeleHealthBook showTelehealthModal={showTelehealthModal} closeTelehealthModal={closeTelehealthModal} handleShowUploadTeleHealthReschedule={handleShowUploadTeleHealthReschedule} callCallsHistory={callCallsHistory} membershipNo={memberBasicInfo.membershipNumber} userId={userId} rescheduleCall={rescheduleCall} doctorName={doctorName} bookingId={bookingId} teleCallId={teleCallId} />
            }

            {
                showEducationModal && <EducationContent showEducationModal={showEducationModal} closeEducationModal={closeEducationModal} membershipNo={memberBasicInfo.membershipNumber} userId={userId}  refreshPageMember={refreshPageMember}/>
            }

            {
                showUploadTelehealthModal && <UploadTeleHealthBook showUploadTelehealthModal={showUploadTelehealthModal} closeUploadTelehealthModal={closeUploadTelehealthModal} openHealthLockerTeleHealth={openHealthLockerTeleHealth} membershipNo={memberBasicInfo.membershipNumber} userId={userId} rescheduleCall={rescheduleCall} bookingId={bookingId} teleCallId={teleCallId} errrorMsg={errrorMsg} />
            }

        </>
    )
}

export default Members;