import React, { useState } from 'react';
import ReactDOM from 'react-dom';

// const templates = [
//   { 
//     name: 'coach_message_1', 
//     body: 'Hi {{1}}, I am your coach {{2}} we will be doing your lifestyle changes on here. Please let me know if you have any diet restrictions or allergies.',
//     type: 'text'
//   },
//   { 
//     name: 'coach_message_2_ut', 
//     body: 'Good morning, Ready to make some lifestyle changes?',
//     type: 'text'
//   },
//   { 
//     name: 'live_welcome_1_rev', 
//     body: "Hello 👋\n\nYou're on the verge of a significant health breakthrough!\n\nWith GOQii, you're tapping into a comprehensive health platform that will support you every step of the way on your {{1}} journey. 🌟\n\nImagine easy access to personalised health insights, coaching & a supportive community—all within the GOQii app! This journey is about more than just managing diabetes; it's about embracing a vibrant, healthier life. 💪 🌿\n\nReady to start this new chapter together? Let's do this! 🚀",
//     footer: '#BETHEFORCE',
//     buttons: [{ text: 'Download GOQii', url: 'https://link.interakt.ai/api/wa/{{1}}' }],
//     type: 'text'
//   }
// ];

const TemplateItem = ({ template, onSelect, isSelected }) => (
  <div 
    className={`tw-p-2 tw-border tw-border-gray-300 tw-rounded tw-mb-2 tw-cursor-pointer hover:tw-bg-gray-100 ${isSelected ? 'tw-bg-blue-100' : ''}`}
    onClick={() => onSelect(template)}
  >
    {template.templateName}
  </div>
);

const TemplateMockup = ({ template, variables }) => {
  // Function to replace variables in content
  const replaceVariables = (content) => {
    if (!content) return "";

    // URL regex for better link detection
    const urlRegex = /(?:(?:https?:\/\/)?(?:www\.)?)?[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z]{2,})+(?:\/[^\s]*)?/gi;
    
    const commonTLDs = ['com', 'org', 'net', 'edu', 'gov', 'mil', 'io', 'co', 'ai', 'app', 'dev', 'uk', 'us', 'eu', 'in', 'de', 'fr'];

     
     // First handle line breaks
     content = content.replace(/\\n/g, "<br />");

     // Split content by HTML tags and process each part
     const parts = content.split(/(<[^>]*>)/);
     content = parts.map(part => {
         // If it's an HTML tag, return it unchanged
         if (part.startsWith('<') && part.endsWith('>')) {
             return part;
         }
         // If part contains Arabic/Urdu, wrap it in RTL div
         if (/[\u0600-\u06FF\uFB50-\uFDFF\uFE70-\uFEFF]/.test(part)) {
             return `<div class="tw-text-right" dir="rtl" style="text-align: right;">${part}</div>`;
         }
         // If it's non-empty text without Arabic/Urdu, wrap it in regular div
         if (part.trim()) {
             return `<div>${part}</div>`;
         }
         // Return empty parts unchanged
         return part;
     }).join('');

     console.log(content,'content')

    return (
      content
        // Handle line breaks first
        // .replace(/\\n/g, "<br />")

        // Bold: *text* (not preceded or followed by space)
        .replace(/\*([^*]+)\*/g, "<strong>$1</strong>")

        // Italic: _text_ (not preceded or followed by space)
        .replace(/(?<!\s)_(.*?)_(?!\s)/g, "<em>$1</em>")

        // Strikethrough: ~text~ (not preceded or followed by space)
        .replace(/(?<!\s)~(.*?)~(?!\s)/g, "<del>$1</del>")

        // Monospace: ```text```
        .replace(/```(.*?)```/g, "<code>$1</code>")

        // Single line code: `text`
        .replace(/`(.*?)`/g, "<code>$1</code>")

        // Enhanced URL detection and replacement
        .replace(urlRegex, (url) => {
          // Check if this matches common URL patterns
          const isLikelyURL = commonTLDs.some(tld => 
            url.toLowerCase().includes(`.${tld}`) &&
            url.match(/^[^@\s]+\.[^\s]+$/) // Exclude email addresses
          );

          if (!isLikelyURL) return url;

          // Add protocol if missing
          let fullUrl = url;
          if (!url.match(/^https?:\/\//i)) {
            fullUrl = 'https://' + url;
          }

          // Validate URL
          try {
            new URL(fullUrl);
            return `<a href="${fullUrl}" class="tw-text-blue-600 hover:tw-underline" target="_blank" rel="noopener noreferrer">${url}</a>`;
          } catch {
            return url;
          }
        })

        // WhatsApp style quotes: > at start of line
        .replace(
          /(^|\n)&gt; ?([^\n]*)/g,
          '$1<div class="tw-border-l-4 tw-border-gray-300 tw-pl-3 tw-my-1 tw-text-gray-600">$2</div>'
        )

        // Lists: - or • at start of line
        .replace(
          /(^|\n)[-•] ?([^\n]*)/g,
          '$1<div class="tw-flex tw-gap-2 tw-my-1">•<span>$2</span></div>'
        )

        // Handle multiple underscores or asterisks in a single word
        .replace(/(\*{2}|_{2})(.*?)\1/g, "<strong>$2</strong>")
        .replace(/(\*{3}|_{3})(.*?)\1/g, "<strong><em>$2</em></strong>")

        // Fix any potentially broken HTML from nested formatting
        .replace(/<(\/?)(strong|em|del|code)>/g, "<$1$2>")
    );
  };

  return (
    <div className="tw-w-full md:tw-w-[360px] tw-mx-auto">
      <div className="tw-relative tw-h-[600px]">
        <div className="tw-w-full tw-h-full tw-bg-[url('https://appcdn.goqii.com/storeimg/49871_1729598479.png')] tw-bg-contain tw-bg-no-repeat tw-bg-center"></div>

        <div className="tw-absolute tw-top-[85px] tw-left-[25px] tw-right-[25px] tw-bottom-[85px] tw-overflow-y-auto [&::-webkit-scrollbar]:tw-hidden [-ms-overflow-style:'none'] [scrollbar-width:'none']">
          <div className="tw-text-xs tw-text-gray-700 tw-bg-white tw-rounded-lg tw-px-2 tw-w-1/3 tw-text-center tw-mx-auto tw-mb-1 tw-font-semibold">
            Today
          </div>
          
          <div className="tw-bg-white tw-rounded-lg tw-p-3 tw-shadow-sm">
            <div className="tw-text-gray-600 tw-text-sm tw-mb-3">
              {template?.mediaUrl && (
                <div className="tw-mb-2">
                  {template.type === "Image" ? (
                    <img 
                      src={template.mediaUrl}
                      alt={template.name}
                      className="tw-w-full tw-h-full tw-object-cover"
                    />
                  ) : template.type === "Video" ? (
                    <video className="tw-w-full tw-h-full" controls>
                      <source src={template.mediaUrl} type="video/mp4" />
                    </video>
                  ) : template.type === "Document" ? (
                    <embed
                      src={template.mediaUrl}
                      type="application/pdf"
                      className="tw-w-full tw-h-full"
                    />
                  ) : null}
                </div>
              )}
              
              <p className="tw-text-wrap" dangerouslySetInnerHTML={{ __html: replaceVariables(template.body) }}></p>
            </div>

            {template.footer && (
              <p className="tw-text-sm tw-text-gray-500 tw-mb-2">{template.footer}</p>
            )}

            {template.buttons && (
              <div className="tw-flex tw-flex-col tw-gap-2">
                {template.buttons.map((button, index) => (
                  <span
                    key={index}
                    className="tw-text-sm tw-cursor-pointer tw-py-1 tw-px-3 tw-rounded tw-text-center tw-border-0 tw-bg-gray-50 tw-text-blue-500"
                    dangerouslySetInnerHTML={{ __html: replaceVariables(button.title) }}
                  >
                  </span>
                ))}
              </div>
            )}

{template.websiteUrl && template.websiteUrl.length > 0 && (
  <div className="tw-flex tw-flex-col tw-gap-px tw-mt-3 tw-mx-[-12px] tw-mb-[-12px]">
    {template.websiteUrl.map((link, index) => (
      <span
        key={index}
        className={`
          tw-text-[#1c4cba] dark:tw-text-[#4d82ff]
          tw-text-sm
          tw-font-medium
          tw-py-3
          tw-px-4
          tw-text-center
          tw-bg-white
          hover:tw-bg-gray-50
          active:tw-bg-gray-100
          tw-transition-colors
          tw-border-t
          tw-border-gray-200
          first:tw-border-t-0
          ${index === 0 ? 'tw-rounded-b-lg' : ''}
          tw-cursor-pointer
          tw-flex
          tw-items-center
          tw-justify-center
          tw-gap-2
          tw-w-full
        `}
      >
        {link.type === 'URL' && (
          <>
            <svg 
              className="tw-w-4 tw-h-4" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth="2" 
                d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
              />
            </svg>
            <span className="tw-truncate">{link.title}</span>
          </>
        )}
        {link.type === 'PHONE_NUMBER' && (
          <>
            <svg
              className="tw-w-4 tw-h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
              />
            </svg>
            <span className="tw-truncate">{link.title}</span>
          </>
        )}
      </span>
    ))}
  </div>
)}
          </div>
        </div>
      </div>
    </div>
  );
};

const VariableInputs = ({ template, variables, onVariableChange }) => {
  if (!template) return null;

  const extractVariables = (content) => {
    const matches = new Set();
    if (content) {
      const bodyMatches = content.match(/{{(\d+)}}/g) || [];
      bodyMatches.forEach(match => {
        matches.add(match.replace(/[{}]/g, ""));
      });
    }
    return Array.from(matches).sort((a, b) => Number(a) - Number(b));
  };

  const vars = extractVariables(template.body);
  if (vars.length === 0) return null;

  return (
    <div className="tw-mb-6 tw-bg-gray-50 tw-p-4 tw-rounded-lg">
      {/* <h5 className="tw-font-bold tw-mb-4">Template Variables:</h5> */}
      <div className="tw-grid tw-grid-cols-2 tw-gap-4">
        {/* {vars.map((varNumber) => (
          <div key={varNumber} className="tw-flex tw-flex-col">
            <label className="tw-text-sm tw-font-medium tw-mb-1">
              Variable {varNumber}:
            </label>
            <input
              type="text"
              value={variables[varNumber] || ''}
              onChange={(e) => onVariableChange(varNumber, e.target.value)}
              placeholder={`Enter value for {{${varNumber}}}`}
              className="form-control input-field-ui"
            />
          </div>
        ))} */}
      </div>
    </div>
  );
};

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-justify-center tw-items-center tw-z-[9999]">
      <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-w-11/12 tw-max-w-4xl tw-h-[90vh] tw-flex tw-flex-col tw-relative">
        {children}
      </div>
    </div>,
    document.body
  );
};

const CreateMessage = ({whatsTemplateData,selectedTemplate,setSelectedTemplate}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tempSelectedTemplate, setTempSelectedTemplate] = useState(null);
  const [variables, setVariables] = useState({});
  const [searchTerm, setSearchTerm] = useState('');

  /*const templates = [
    { 
      name: 'coach_message_1', 
      body: 'Hi {{1}}, I am your coach {{2}} we will be doing your lifestyle changes on here. Please let me know if you have any diet restrictions or allergies.',
      type: 'text'
    },
    { 
      name: 'coach_message_2_ut', 
      body: 'Good morning, Ready to make some lifestyle changes?',
      type: 'text'
    },
    { 
      name: 'live_welcome_1_rev', 
      body: "Hello 👋\n\nYou're on the verge of a significant health breakthrough!\n\nWith GOQii, you're tapping into a comprehensive health platform that will support you every step of the way on your {{1}} journey. 🌟\n\nImagine easy access to personalised health insights, coaching & a supportive community—all within the GOQii app! This journey is about more than just managing diabetes; it's about embracing a vibrant, healthier life. 💪 🌿\n\nReady to start this new chapter together? Let's do this! 🚀",
      footer: '#BETHEFORCE',
      buttons: [{ text: 'Download GOQii', url: 'https://link.interakt.ai/api/wa/{{1}}' }],
      type: 'text'
    }
  ];*/

 

  const handleOpenModal = () => {
    setIsModalOpen(true);
    // Initialize temporary selection with current selection
    setTempSelectedTemplate(selectedTemplate);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setTempSelectedTemplate(null);
  };

  // const handleSelectTemplate = (template) => {
  //   const formattedTemplate = {
  //     ...template,
  //     body: template.templateBody.replace(/\\n/g, '<br />')
  //   };
  //   setSelectedTemplate(formattedTemplate);
  //   setVariables({});
  // };

  const handleSelectTemplate = (template) => {
    // Only update the temporary selection
    const formattedTemplate = {
      ...template,
      body: template.templateBody.replace(/\\n/g, '<br />')
    };
    setTempSelectedTemplate(formattedTemplate);
  };

  const handleChooseTemplate = () => {
    // Update the actual selected template only when "Choose Template" is clicked
    if (tempSelectedTemplate) {
      setSelectedTemplate(tempSelectedTemplate);
      setVariables({});
      setIsModalOpen(false);
    }
  };

  const handleVariableChange = (varNumber, value) => {
    setVariables(prev => ({
      ...prev,
      [varNumber]: value
    }));
  };

  const replaceVariables = (content) => {
    if (!content) return "";

    // URL regex for better link detection
    const urlRegex = /(?:(?:https?:\/\/)?(?:www\.)?)?[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z]{2,})+(?:\/[^\s]*)?/gi;
    
    const commonTLDs = ['com', 'org', 'net', 'edu', 'gov', 'mil', 'io', 'co', 'ai', 'app', 'dev', 'uk', 'us', 'eu', 'in', 'de', 'fr'];

     
     // First handle line breaks
     content = content.replace(/\\n/g, "<br />");

     // Split content by HTML tags and process each part
     const parts = content.split(/(<[^>]*>)/);
     content = parts.map(part => {
         // If it's an HTML tag, return it unchanged
         if (part.startsWith('<') && part.endsWith('>')) {
             return part;
         }
         // If part contains Arabic/Urdu, wrap it in RTL div
         if (/[\u0600-\u06FF\uFB50-\uFDFF\uFE70-\uFEFF]/.test(part)) {
             return `<div class="tw-text-right" dir="rtl" style="text-align: right;">${part}</div>`;
         }
         // If it's non-empty text without Arabic/Urdu, wrap it in regular div
         if (part.trim()) {
             return `<div>${part}</div>`;
         }
         // Return empty parts unchanged
         return part;
     }).join('');

     console.log(content,'content')

    return (
      content
        // Handle line breaks first
        // .replace(/\\n/g, "<br />")

        // Bold: *text* (not preceded or followed by space)
        .replace(/\*([^*]+)\*/g, "<strong>$1</strong>")

        // Italic: _text_ (not preceded or followed by space)
        .replace(/(?<!\s)_(.*?)_(?!\s)/g, "<em>$1</em>")

        // Strikethrough: ~text~ (not preceded or followed by space)
        .replace(/(?<!\s)~(.*?)~(?!\s)/g, "<del>$1</del>")

        // Monospace: ```text```
        .replace(/```(.*?)```/g, "<code>$1</code>")

        // Single line code: `text`
        .replace(/`(.*?)`/g, "<code>$1</code>")

        // Enhanced URL detection and replacement
        .replace(urlRegex, (url) => {
          // Check if this matches common URL patterns
          const isLikelyURL = commonTLDs.some(tld => 
            url.toLowerCase().includes(`.${tld}`) &&
            url.match(/^[^@\s]+\.[^\s]+$/) // Exclude email addresses
          );

          if (!isLikelyURL) return url;

          // Add protocol if missing
          let fullUrl = url;
          if (!url.match(/^https?:\/\//i)) {
            fullUrl = 'https://' + url;
          }

          // Validate URL
          try {
            new URL(fullUrl);
            return `<a href="${fullUrl}" class="tw-text-blue-600 hover:tw-underline" target="_blank" rel="noopener noreferrer">${url}</a>`;
          } catch {
            return url;
          }
        })

        // WhatsApp style quotes: > at start of line
        .replace(
          /(^|\n)&gt; ?([^\n]*)/g,
          '$1<div class="tw-border-l-4 tw-border-gray-300 tw-pl-3 tw-my-1 tw-text-gray-600">$2</div>'
        )

        // Lists: - or • at start of line
        .replace(
          /(^|\n)[-•] ?([^\n]*)/g,
          '$1<div class="tw-flex tw-gap-2 tw-my-1">•<span>$2</span></div>'
        )

        // Handle multiple underscores or asterisks in a single word
        .replace(/(\*{2}|_{2})(.*?)\1/g, "<strong>$2</strong>")
        .replace(/(\*{3}|_{3})(.*?)\1/g, "<strong><em>$2</em></strong>")

        // Fix any potentially broken HTML from nested formatting
        .replace(/<(\/?)(strong|em|del|code)>/g, "<$1$2>")
    );
  };

  return (
    <div className="tw-relative">
      <h3 className="tw-text-lg tw-font-semibold tw-mb-2">Message <span className="tw-text-red-500 tw-text-base tw-font-medium">*</span></h3>
      <div className="tw-border tw-border-gray-300 tw-rounded tw-p-4 tw-mb-4">
        {selectedTemplate ? (
          <>
            <TemplateMockup template={selectedTemplate} variables={variables} />
          </>
        ) : (
          <div className="tw-text-center tw-text-gray-500">No Template Selected yet</div>
        )}
      </div>
      <button 
        className="btn btn-success uplaod-file-btn"
        onClick={handleOpenModal}
      >
        Choose template
      </button>

      <Modal isOpen={isModalOpen} size="lg" onClose={handleCloseModal}>
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
          <h2 className="tw-text-xl tw-text-gray-600 tw-font-bold">Select Template</h2>
          <span 
            className="tw-bg-white tw-text-gray-500 tw-font-bold tw-text-xl tw-cursor-pointer tw-p-2 tw-rounded hover:tw-text-gray-800"
            onClick={handleCloseModal}
          >
            ✕
          </span>
        </div>

        <div className="tw-flex tw-space-x-4 tw-flex-grow tw-overflow-hidden">
          <div className="tw-w-1/3 tw-flex tw-flex-col">
            <div className="form-group" style={{width: "100%"}}>
              <input
                type="text"
                placeholder="Search Template"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="form-control input-field-ui ps-5"
              />
              <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
            </div>
            <div className="tw-overflow-y-auto tw-flex-grow tw-mt-2">
              {whatsTemplateData?.map((template, index) => (
                <TemplateItem 
                  key={index} 
                  template={template} 
                  onSelect={handleSelectTemplate}
                  isSelected={tempSelectedTemplate && tempSelectedTemplate.templateName === template.templateName}
                />
              ))}
            </div>
          </div>

          <div className='vr' />

          <div className="tw-w-2/3 tw-p-4 tw-overflow-y-auto">
                        {tempSelectedTemplate ? (
                            <div className='tw-flex tw-flex-col'>
                                 <div className="tw-w-full">
    <h5 className="tw-font-bold tw-mb-4">Message</h5>
    <div className="tw-overflow-y-auto tw-pr-2">
      <div className="tw-bg-gray-50 tw-rounded-lg tw-p-4">
        <div className="tw-mb-4">
          <p className="tw-font-semibold tw-text-gray-700 tw-mb-1">Body:</p>
          <div className="tw-bg-white tw-p-3 tw-rounded tw-border tw-border-gray-200">
            {/* <p className="tw-text-sm tw-text-gray-600 tw-mb-1">{tempSelectedTemplate.templateName}</p> */}
            <div 
              className="tw-text-sm tw-break-words tw-whitespace-pre-wrap" 
              dangerouslySetInnerHTML={{ __html: replaceVariables(tempSelectedTemplate.templateBody) }}
            />
          </div>
        </div>

        <div className="tw-mb-4">
  <p className="tw-font-semibold tw-text-gray-700 tw-mb-1">Buttons:</p>
  <div className="tw-bg-white tw-p-3 tw-rounded tw-border tw-border-gray-200">
    {tempSelectedTemplate.buttons && tempSelectedTemplate.buttons.length > 0 ? (
      <div className="tw-space-y-2">
        {tempSelectedTemplate.buttons.map((button, index) => (
          <p key={index} className="tw-text-sm tw-break-words">
            {button.type}: {button.title}
          </p>
        ))}
      </div>
    ) : (
      <p className="tw-text-sm tw-break-words">No buttons</p>
    )}
  </div>
</div>

<div className="tw-mb-4">
  <p className="tw-font-semibold tw-text-gray-700 tw-mb-1">Website URLs & Phone Numbers:</p>
  <div className="tw-bg-white tw-p-3 tw-rounded tw-border tw-border-gray-200">
    {tempSelectedTemplate.websiteUrl && tempSelectedTemplate.websiteUrl.length > 0 ? (
      <div className="tw-space-y-2">
        {tempSelectedTemplate.websiteUrl.map((link, index) => (
          <p key={index} className="tw-text-sm tw-break-words">
            {link.type === 'URL' ? `URL: ${link.title} (${link.url})` : 
             link.type === 'PHONE_NUMBER' ? `Phone: ${link.title} (${link.phone})` : ''}
          </p>
        ))}
      </div>
    ) : (
      <p className="tw-text-sm tw-break-words">No website URLs or phone numbers</p>
    )}
  </div>
</div>

        <div className="tw-mb-4">
          <p className="tw-font-semibold tw-text-gray-700 tw-mb-1">Header Content:</p>
          <div className="tw-bg-white tw-p-3 tw-rounded tw-border tw-border-gray-200">
            <p className="tw-text-sm tw-break-words">{tempSelectedTemplate.headerContent || "No header content"}</p>
          </div>
        </div>

        <div className="tw-mb-4">
          <p className="tw-font-semibold tw-text-gray-700 tw-mb-1">Media URL:</p>
          <div className="tw-bg-white tw-p-3 tw-rounded tw-border tw-border-gray-200">
            <p className="tw-text-sm tw-break-all hover:tw-whitespace-normal">
              {tempSelectedTemplate.mediaUrl || "No media URL"}
            </p>
          </div>
        </div>

        <div>
          <p className="tw-font-semibold tw-text-gray-700 tw-mb-1">Category:</p>
          <div className="tw-bg-white tw-p-3 tw-rounded tw-border tw-border-gray-200">
            <p className="tw-text-sm tw-break-words">{tempSelectedTemplate.category || "No category"}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
                                <div className='vr'></div>
                                <div className='tw-pl-2 tw-w-full'>
                  <h5 className="tw-font-bold tw-mb-4">Preview:</h5>
                  <TemplateMockup template={tempSelectedTemplate} />
                </div>
                            </div>
                        ) : (
                            <div className="tw-text-center tw-text-gray-500 tw-py-4">
                                Select a template to see preview
                            </div>
                        )}
                    </div>
        </div>

        <div className="tw-mt-6 tw-flex tw-justify-center tw-gap-4">
          <button 
            className="btn btn-success uplaod-file-btn"
            onClick={handleChooseTemplate}
            disabled={!tempSelectedTemplate}
          >
            Choose Template
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default CreateMessage;