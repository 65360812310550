import React,{useEffect,useState} from "react";
import MemberEngagementTableList from "./MemberEngagementTableList";
import { EngagementDetailsSummery,SummeryDashboardData, EngagementNotificationResult, Filterdashboard } from "../../met/utils/EngagementUtility";
import CalendarDatePicker from "../CalendarDatePicker";
import moment from "moment";
import { decryptData } from "../../utils/Utils";
import { ToastContainer, toast } from 'react-toastify';


function ContentMemberEngagementList() {

    const storedDates = localStorage.getItem('selectedDates') ? JSON.parse(decryptData(localStorage.getItem('selectedDates'))) : '';
    const [startDate, setStartDate] = useState(
        storedDates && storedDates.length === 4 ? moment(storedDates[0]) : moment()
    );
    const [endDate, setEndDate] = useState(
        storedDates && storedDates.length === 4 ? moment(storedDates[1]) : moment()
    );


    const [isWithinRange, setIsWithinRange] = useState(false);
    useEffect(() => {
        const today = resetTime(new Date());
        const sd = resetTime(new Date(startDate));
        const ed = resetTime(new Date(endDate));

        // let iwr = today >= sd && today <= ed;
        // setIsWithinRange(iwr);
        
        // if (sd.getTime() === today.getTime() && ed.getTime() === today.getTime()) {
        //     setIsWithinRange(false);
        // } else {
            const iwr = today >= sd && today <= ed;
            setIsWithinRange(iwr);
        // }
    }, [startDate, endDate])

    const today = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(today.getDate());

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    const formattedYesterdayDate = yesterday.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
    
    const handleDateChange = ({ start, end }) => {
        setStartDate(start);
        setEndDate(end);
    };

    const [selectedProgram, setSelectedProgram] = useState('');

    const handleProgramChange = (event) => {
      setSelectedProgram(event.target.value);
    };

    function resetTime(date){
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    };

    

    return (
        <>
        <div className="ContentMemberEngagement mb-3">
        <ToastContainer />

        
            <div className="container-fluid px-0 mb-3 ">

                <div className="row align-items-center pb-3 sticky-top-bar">
                <div className="col-md text-start">
                        <h1 className="main-title">Member Engagement Manual</h1>
                </div>
                  {/* <Filterdashboard onChange={handleProgramChange} /> */}

                    <div className="col-md-3">
                    <CalendarDatePicker startDate={startDate} endDate={endDate} onDateChange={handleDateChange} />
                    </div>
                </div>
                <div className='reports-programs mb-3'>
                    <div className="row align-items-center">
                        <div className="col-md-auto">
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/8hxst/888st4jed0.png" alt="Invited Members icon" style={{height:"140px"}} className="img-fluid mb-0"/>
                        </div>
                        <div className="col-md">
                            <p className="mb-0">Enables admins to view an overview of member information by categories, including contacted, not contacted, enrolled, and not interested, for effective member management.</p>
                        </div>
                    </div>
                </div>


                {isWithinRange &&
                    <div className="data-not-bg mb-3">
                        <div className="data-not-box">
                            <div className="row text-center px-2">
                                <div className="col text-center"  >
                                    <h1 class="data-not-title">Last Updated: {formattedYesterdayDate}. Today's data will be available tomorrow.</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div className="row">
                    <div className="col-md-12">
                    <div className="member-engagement px-3">
                            {/* <EngagementDetailsSummery /> */}
                            <SummeryDashboardData startDate={startDate} endDate={endDate} />
                        </div>
                    </div>
                    </div>
                        

                        {/* <div className="col-md-7">
                        <EngagementNotificationResult />
                        </div> */}


                </div>

                <div className='member-engagement mb-5'>
                <div className="row">
                    <div className="col-md-12">
                    <div className="table-responsive">
                        <MemberEngagementTableList startDate={startDate} endDate={endDate}/>
                    </div>
                    </div>
                </div>
                </div>

            </div>

        </>
    );
}

export default ContentMemberEngagementList;
