import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';
import highcharts3d from 'highcharts/highcharts-3d';

// Initialize exporting module
exporting(Highcharts);
highcharts3d(Highcharts);

const WhatsAppAnalyticsBar = (props) => { 
  // Destructure props for readability
  const { dataMonth } = props;
  const { columnName: careNavigators, dataValue: membersManaged, color: colorCode, displayName : axisName,subvalue:valueSuffix } = dataMonth;

  const convertTimeToSeconds = (timeStr) => {
    if (typeof timeStr !== "string") {
      console.warn("Invalid time string:", timeStr); // Debugging log
      return 0; // Fallback value for invalid inputs
    }
    const timeRegex = /(\d+)hr\s*(\d+)?min\s*(\d+)?sec?/;
    const match = timeStr.match(timeRegex);
    if (match) {
      const hours = parseInt(match[1] || 0, 10);
      const minutes = parseInt(match[2] || 0, 10);
      const seconds = parseInt(match[3] || 0, 10);
      return hours * 3600 + minutes * 60 + seconds;
    }
    return 0;
  };

  const convertSecondsToTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours}hr ${minutes}min ${seconds}sec`;
  };

  const convertSecondsYAxisToTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    // const minutes = Math.floor((totalSeconds % 3600) / 60);
    // const seconds = totalSeconds % 60;
    return `${hours}`;
  };
  
  const processedData = membersManaged.map((timeStr) => {
    const seconds = convertTimeToSeconds(timeStr);
    return {
      y: seconds, // Numerical value for rendering
      original: timeStr==="" ? 0 : timeStr, // Formatted time string
    };
  });

  // yAxis configuration
  const yAxisJson = {
    title: {
      text: "Time (hrs)",
    },
    labels: {
      formatter: function () {
        return convertSecondsYAxisToTime(this.value); // Format Y-axis labels
      },
    },
  };


  const tooltipJson = {
    
    // convertTimeToSeconds: function () {
    //   // Display original string in tooltip
    //   return `<b>${this.key}</b>: ${this.point.original}`;
    // }
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.original}</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true
  };

  // // Tooltip configuration
  // const tooltipJson = {
  //   shared: true,
  //   valueSuffix: ' '+valueSuffix
  // };

  // Series data
  const columnData = [{
    name: axisName,
    data: processedData,
    color: colorCode
  }];

  // Highcharts configuration
  const optionsCN1 = {
    chart: {
      type: "column",
      backgroundColor: 'transparent',
      height: 260,
      options3d: {
          enabled: true,
          alpha: 0,
          beta: 15,
          depth: 50,
          viewDistance: 0
      },
      marginTop: [34]
  },
    title: "    ",
    subtitle: null,
    xAxis: {
      categories: careNavigators,
      gridLineWidth: 0
    },
    yAxis: yAxisJson,
    tooltip: tooltipJson,
    plotOptions: {
      column: {
        borderRadius: 5,
        pointPadding: 0.2,
        groupPadding: 0.1,
        stacking: null, // Use null for side-by-side columns
        dataLabels: {
          enabled: true,
          formatter: function() {
            return convertSecondsToTime(this.y); // Convert seconds back to readable format
          },
          style: {
            textOutline: 'none'
          }
        }
      }
    },
    series: columnData,
    credits: {
      enabled: false
    },
    legend: {
      enabled: true
    },
    exporting: {
      enabled: true
    },
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={optionsCN1}
      />
    </div>
  );
};

export default WhatsAppAnalyticsBar;
